import UserForm from "../components/form/UserForm";
import {
  BLOG_IMAGE_1,
  BLOG_IMAGE_2,
  BLOG_IMAGE_3,
  BLOG_IMAGE_4,
} from "../configuration/environment";

const BlogPage = () => {
  return (
    <>
      <section className="banner-header banner-img section-padding valign bg-img bg-fixed photo-gallery-banner"></section>

      <div className="site-wrap">
        <div className="container">
          <div className="text-center mb-5">
            <span>OUR BLOG</span>
            <h2 className="text-primary text-center">LATEST NEWS</h2>
          </div>
          <div className="row">
            <div className="col-md-8">
              <div className="row">
                <div className="col-md-12">
                  <div className="projects-single">
                    <a href="/blog-detail1">
                      <div className="projects-img">
                        <img src={BLOG_IMAGE_1} alt="img2" width="100%" />
                      </div>
                    </a>
                    <div className="blog-sub-sec1">
                      <div className="blog-sub-sec bg-primary py-5">
                        <span className="blog-content-span">
                          April 29, 2022
                        </span>
                        <h3 className="text-white mt-3">
                          SEVEN BENEFITS OF INVESTING IN A PLOT OF LAND
                        </h3>
                        <p className="text-white my-3">
                          1. Greater flexibility Buyers are often restricted by
                          the architect’s vision when purchasing an apartment.
                          The buyer can make only a few modifications to the
                          property, and it is impossible to change the layout of
                          their home. A plot...
                        </p>
                        <div>
                          <a href="/" className="btn custom-btn to-right">
                            <span>
                              READ MORE <i className="fas fa-arrow-right"></i>
                            </span>
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-12">
                  <div className="projects-single">
                    <a href="/blog-detail2">
                      <div className="projects-img">
                        <img src={BLOG_IMAGE_2} alt="img2" width="100%" />
                      </div>
                    </a>
                    <div className="blog-sub-sec1">
                      <div className="blog-sub-sec bg-primary py-5">
                        <span className="blog-content-span">June 29, 2022</span>
                        <h3 className="text-white mt-3">
                          WHY INVEST IN SILPA’S VANADHARA
                        </h3>
                        <p className="text-white my-3">
                          INVEST IN FARMLANDS TO INCREASE YOUR ROI – WHY SILPA
                          VANADHARA IS THE RIGHT CHOICE. If you know the
                          industry well, you already know that investing in
                          farmlands can have great benefits. What are these
                          benefits and how do...
                        </p>
                        <div>
                          <a href="/" className="btn custom-btn to-right">
                            <span>
                              READ MORE <i className="fas fa-arrow-right"></i>
                            </span>
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <div className="blog-sidebar row">
                <div className="col-md-12">
                  <div className="widget">
                    <div className="widget-title">
                      <h3 class="widget-title">Recent Posts</h3>{" "}
                    </div>
                    <ul className="recent">
                      <li>
                        <div className="thum">
                          <img height="40px" alt="" src={BLOG_IMAGE_1} />
                        </div>
                        <a href="https://silpa.co.in/seven-benefits-of-investing-in-a-plot-of-land/">
                          Seven Benefits of Investing in a plot of land
                        </a>
                      </li>
                      <li>
                        <div className="thum">
                          <img height="40px" alt="" src={BLOG_IMAGE_2} />
                        </div>
                        <a href="https://silpa.co.in/seven-benefits-of-investing-in-a-plot-of-land/">
                          Why Invest in Anvika’s Vanadhara
                        </a>
                      </li>
                    </ul>
                  </div>
                  <div id="#text-2" className="single-widget  widget_text">
                    <h3 className="widget-title">REQUEST A CALL BACK</h3>
                  </div>

                  <UserForm />

                  <div className="my-4">
                    <img width="100%" alt="" src={BLOG_IMAGE_3} />
                  </div>
                  <div className="#media_image-3">
                    <img width="100%" alt="" src={BLOG_IMAGE_4} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default BlogPage;
