const API_URL_ANVI =
  "https://asia-south1-royal-rajarikam.cloudfunctions.net/send_message";
const API_URL_ROYAL =
  "https://asia-south1-royal-rajarikam.cloudfunctions.net/send_message";

export const PHONE_NUMBER = "81798 94526";
export const EMAIL_ID = "info@assetkingdome.in";
export const ADDRESS =
  "FLOT No. 202, Road No.1, Kavuri Hills, Siddhartha Building, Madhapur";
export const CITY = "Hyderabad";
export const STATE = "Telangana";
export const ZIP_CODE = "10001";
export const COUNTRY = "India";
export const FAX_NUMBER = "1234567890";
export const WEB_URL = "www.example.com";
export const FACEBOOK_URL = "www.facebook.com";
export const TWITTER_URL = "www.twitter.com";
export const INSTAGRAM_URL = "www.instagram.com";
export const YOUTUBE_URL = "www.youtube.com";
export const LINKEDIN_URL = "www.linkedin.com";
export const GOOGLE_URL = "www.google.com";
export const PINTEREST_URL = "www.pinterest.com";
export const VIMEO_URL = "www.vimeo.com";
export const DRIBBBLE_URL = "www.dribbble.com";
export const GITHUB_URL = "www.github.com";
export const TWITTER_HANDLE = "@example";
export const FACEBOOK_HANDLE = "example";
export const INSTAGRAM_HANDLE = "example";
export const YOUTUBE_HANDLE = "example";
export const LINKEDIN_HANDLE = "example";
export const GOOGLE_HANDLE = "example";
export const PINTEREST_HANDLE = "example";
export const VIMEO_HANDLE = "example";
export const DRIBBBLE_HANDLE = "example";
export const GITHUB_HANDLE = "example";

//images

export const ABOUT_IMG1 =
  "https://silpa.co.in/wp-content/uploads/2022/04/about_company_new.jpg";
export const ABOUT_IMG2 =
  "https://silpa.co.in/wp-content/uploads/2022/04/vision_mission.jpg";

export const ABOUT_IMG3 =
  "https://silpa.co.in/wp-content/uploads/2022/05/silpa_25Years.png";
export const ABOUT_IMG4 =
  "https://silpa.co.in/wp-content/uploads/2022/05/silpa_timely_delivered.png";
export const ABOUT_IMG5 =
  "https://silpa.co.in/wp-content/uploads/2022/05/silpa_clear_title_property.png";
export const ABOUT_IMG6 =
  "https://silpa.co.in/wp-content/uploads/2022/05/silpa_prime_locations.png";
export const ABOUT_IMG7 =
  "https://silpa.co.in/wp-content/uploads/2022/05/silpa_higher_returns.png";
export const ABOUT_IMG8 =
  "https://silpa.co.in/wp-content/uploads/2022/05/silpa_top_notch_development.png";
export const ABOUT_IMG9 =
  "https://silpa.co.in/wp-content/uploads/2022/05/silpa_trust_management.png";
export const ABOUT_IMG10 =
  "https://silpa.co.in/wp-content/uploads/2022/05/silpa_hassle_free.png";

export const BLOG_IMAGE_1 =
  "https://silpa.co.in/wp-content/uploads/2022/04/Seven_Benefits_of_Investing_in_a_plot_of_land.jpg";
export const BLOG_IMAGE_2 =
  "https://silpa.co.in/wp-content/uploads/2022/01/why_invest_vanadhara.jpg";
export const BLOG_IMAGE_3 =
  "https://silpa.co.in/wp-content/uploads/2022/05/blog_widget_02.jpg";
export const BLOG_IMAGE_4 =
  "https://silpa.co.in/wp-content/uploads/2022/05/blog_widget_01.jpg";
export const PROJECT_IMAGE_1 =
  "https://silpa.co.in/wp-content/uploads/2022/01/silpa_aranya_project.jpg";
export const PROJECT_IMAGE_2 =
  "https://silpa.co.in/wp-content/uploads/2022/01/silpa_nature_avenue_project.jpg";
export const PROJECT_IMAGE_3 =
  "https://silpa.co.in/wp-content/uploads/2022/01/silpa_vanadhara_project.jpg";
export const PROJECT_IMAGE_4 =
  "https://silpa.co.in/wp-content/uploads/2022/05/silpa_csk_green_villa_project.jpg";
export const PROJECT_IMAGE_5 =
  "https://silpa.co.in/wp-content/uploads/2022/01/silpa_sr_bamboo_project.jpg";
export const PROJECT_IMAGE_6 =
  "https://promote360.in/silpa1/wp-content/uploads/2022/05/silpa_botanica.jpg";

export const PROJECT_CAROUSEL_IMG =
  "https://silpa.co.in/wp-content/uploads/2022/05/silpa_csk_green_villa_project.jpg";
export const SECTION1_IMAGE_1 =
  "https://firebasestorage.googleapis.com/v0/b/anvikainfra.appspot.com/o/anvika_images%2F10.jpg?alt=media&token=2bae7ca9-79eb-47d5-b289-763c84657470";
export const VIDEO_SEC_IMAGE_1 =
  "https://silpa.co.in/wp-content/uploads/2022/04/vanadhara_video_bg.jpg";
export const VIDEO_GALLERY_1 = "https://www.youtube.com/embed/MnuAo69aeO8";

export const ABOUTUS_IMG_1 =
  "https://firebasestorage.googleapis.com/v0/b/anvikainfra.appspot.com/o/anvika_images%2F8.jpg?alt=media&token=2a0dd905-6c9b-4312-bfa1-d42c79b4c474";
export const ABOUTUS_IMG_2 =
  "https://firebasestorage.googleapis.com/v0/b/anvikainfra.appspot.com/o/anvika_images%2F10.jpg?alt=media&token=2bae7ca9-79eb-47d5-b289-763c84657470";
const API_URL =
  process.env.REACT_APP_APPLICATION_NAME !== "royal"
    ? API_URL_ANVI
    : API_URL_ROYAL;
export { API_URL };
