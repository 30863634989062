import { useRef, useState } from "react";

const VideoPlayer = ({ src, thumbnailSrc }) => {
  const [isPlaying, setIsPlaying] = useState(false);
  const videoRef = useRef(null);

  const handlePlay = () => {
    setIsPlaying(true);
    videoRef.current?.play();
  };

  return (
    <div>
      {isPlaying ? (
        <video ref={videoRef} controls width="100%" src={src} />
      ) : (
        <div
          style={{ position: "relative", width: "100%", cursor: "pointer" }}
          onClick={handlePlay}
        >
          <img src={thumbnailSrc} alt="Video Thumbnail" width="100%" />
          <div
            style={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              backgroundColor: "rgba(0, 0, 0, 0.5)",
              borderRadius: "50%",
              padding: "10px",
            }}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              fill="white"
              width="48px"
              height="48px"
            >
              <path d="M0 0h24v24H0z" fill="none" />
              <path d="M8 5v14l11-7z" />
            </svg>
          </div>
        </div>
      )}
    </div>
  );
};

export default VideoPlayer;
