import { COMPANY } from "./config";
import { API_URL } from "./environment";

export const sendEmail = ({ name, email, message, phone }) => {
  const data = {
    name,
    email,
    message,
    phone,
    company: COMPANY,
  };
  return fetch(API_URL, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(data),
  })
    .then((res) => res.json())
    .then((res) => {
      return res;
    })
    .catch((err) => err);
};
