import Carousel from "../components/carousel/Carousel";
import ClientCounts from "../components/clients/Clients";
import HappyClients from "../components/clients/HappyClients";
import Projects from "../components/projects";
import AboutSection from "../components/sections/AboutSection";
import VideoSection from "../components/video/VideoSection";
export default function LandingPage() {
  return (
    <>
      <Carousel />
      <AboutSection />
      <Projects />
      <VideoSection />
      <ClientCounts />
      <HappyClients />
      {/* <Blog /> */}
    </>
  );
}
