import UserForm from "../../components/form/UserForm";
const ProjectDetails1 = () => {
  const detailsObj = {
    heading: "OPEN VILLA PLOTS @ SADASIVAPET",
    subheading: "WELL-DEVELOPED YET SUSTAINABLE – LEAD A UNIQUE LIFESTYLE",
    description1:
      "Silpa Aranya, our Farm Plot Project in Manneguda, Chevella – Vikarabad Highway, Hyderabad is spread across 112 acres of beautiful landscapes. This sought-after project consists of diverse farmlands that have rich, fertile, and expansive soil to grow a wide variety of plants – a truly nurturing environment! The whole project is organised systematically and provided with an electricity connection and an HDPE water line for each plot along with a drip irrigation system.",
    description2:
      "If you have been in search of the perfect farmland venture that offers you a peaceful life in the midst of nature while making sure you are well-connected to the city, Silpa Aranya is an investment you cannot resist! With its long list of amenities (that include a 15,000 square feet clubhouse, a SPA, sports and leisure activities, etc.), its eco-friendly approach, and its well-developed layout, Silpa Aranya is a great place to call home.",
    disclaimer:
      "Disclaimer: I authorize Silpa Infratech Ltd and its representatives to Call, SMS, Email or WhatsApp me about its ventures, investment opportunities and offers. This consent overrides any registration for DNC / NDNC.",
    youtube: "https://www.youtube.com/embed/jlJmFakmwWE",
    browcher1: "https://silpa.co.in/aranya_brochure.pdf",
    projectheading: "PROJECT HIGHLIGHTS",
    project_highlights: [
      {
        text: "All around Compound Wall",
        image:
          "https://silpa.co.in/wp-content/uploads/2022/05/round_compound_wall.png",
      },
      {
        text: "Grand Entrance Arch",
        image:
          "https://silpa.co.in/wp-content/uploads/2022/05/grand_entrance_gate.png",
      },
      {
        text: "Electricity Connection",
        image: "https://silpa.co.in/wp-content/uploads/2022/05/electricity.png",
      },
      {
        text: "HDPE Water line to Each Plot along with Drip",
        image:
          "https://silpa.co.in/wp-content/uploads/2022/05/water_pipeline.png",
      },
      {
        text: "Internal Roads with gravel",
        image:
          "https://silpa.co.in/wp-content/uploads/2022/05/bt_top_roads.png",
      },
      {
        text: "15000 sft Club House",
        image: "https://silpa.co.in/wp-content/uploads/2022/05/club_house.png",
      },
      {
        text: "Over Head Tank with Sump",
        image:
          "https://silpa.co.in/wp-content/uploads/2022/05/water_system_head_tank.png",
      },
      {
        text: "Street Lights",
        image:
          "https://silpa.co.in/wp-content/uploads/2022/05/street_lights.png",
      },
      {
        text: "Main Road with top layer BT and Kerbstone and Saucer Drains.",
        image:
          "https://silpa.co.in/wp-content/uploads/2022/05/under_drainage_system.png",
      },
      {
        text: "Avenue Plantation",
        image:
          "https://silpa.co.in/wp-content/uploads/2022/05/avenue_plantation.png",
      },
    ],
    layoutheading: "PROJECT LAYOUT",
    projectlayoutimage:
      "https://silpa.co.in/wp-content/uploads/2022/05/nature-avenu_layout-1024x723.jpg",
    broucher2: "https://silpa.co.in/aranya_brochure.pdf",
    projectlocheading: "PROJECT LOCATION",
    projectlochighlights: "LOCATION HIGHLIGHTS",
    projectLoc:
      "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3805.782578445506!2d78.36525826467191!3d17.470117488029103!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bcb9233777420db%3A0x4949d2fc9dbc2d56!2sSilpa%20Park%2C%20Hafeezpet%2C%20Hyderabad%2C%20Telangana%20500084!5e0!3m2!1sen!2sin!4v1656581264336!5m2!1sen!2sin",
    gallery: [""],
  };
  return (
    <>
      <section className="banner-header banner-img section-padding valign bg-img bg-fixed photo-gallery-banner"></section>
      <div className="container">
        <div className="row mb-3">
          <div className="col-md-8">
            <div className="my-3">
              <strong className="project-details-heading">
                {detailsObj.heading}
              </strong>
              <p className="project-details-sub-heading">
                {detailsObj.subheading}
              </p>
              <span className="divider-separator2">
                <i className="fas fa-leaf aboutus-sub-text-head px-3"></i>
              </span>
            </div>
            <div className="mt-5">
              <p className="project-detail-text">{detailsObj.description1}</p>
              <p className="project-detail-text">{detailsObj.description2}</p>
            </div>
          </div>
          <div className="col-md-4 bg-primary">
            <div className="text-center text-white my-3">
              <span className="project-detail-form-head">
                WE'D LOVE TO HEAR FROM YOU GET IN TOUCH
              </span>
            </div>
            <div>
              <UserForm />
            </div>
            <div className="px-2">
              <span className="text-white project-detail-form-disclaimer">
                {detailsObj.disclaimer}
              </span>
            </div>
          </div>
        </div>
        <div className="row d-flex  justify-content-center">
          <div className="col-md-10 col-12 p-2">
            <iframe
              title="Block"
              className="project-detail-video-block"
              //   width={640}
              //   height={360}
              src={detailsObj.youtube}
            ></iframe>
          </div>
        </div>
        <div className="text-center">
          <a
            href={detailsObj.browcher1}
            rel="noreferrer"
            target="_blank"
            className="btn custom-btn to-right"
          >
            <span className="project-detail-download">
              Download browcher <i className="fas fa-download"></i>
            </span>
          </a>
        </div>
        <div className="mt-5 mb-3 text-center">
          <strong className="project-details-heading">
            {detailsObj.projectheading}
          </strong>
          <span className="divider-separator">
            <i className="fas fa-leaf aboutus-sub-text-head px-3"></i>
          </span>
        </div>
        <div className="row d-flex justify-content-center ny-4">
          {detailsObj &&
            detailsObj.project_highlights &&
            detailsObj.project_highlights.map((i, index) => {
              return (
                <>
                  <div className="col-lg-2 col-md-6 col-12" key={index}>
                    <div className="aboutus_card">
                      <div className="">
                        <img src={i.image} alt="Sample" className="img-fluid" />
                        <div>
                          <strong className="aboutus_card_tex">{i.text}</strong>
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              );
            })}
        </div>
        <div className="text-center">
          <a
            href={detailsObj.broucher2}
            target="_blank"
            rel="noreferrer"
            className="btn custom-btn to-right"
          >
            <span className="project-detail-download">
              Download browcher <i className="fas fa-download"></i>
            </span>
          </a>
        </div>
        <div className="mt-5 mb-3 text-center">
          <strong className="project-details-heading">
            {detailsObj.layoutheading}
          </strong>
          <span className="divider-separator">
            <i className="fas fa-leaf aboutus-sub-text-head px-3"></i>
          </span>
        </div>
        <div className="row d-flex justify-content-center">
          <div className="col-lg-8 col-12">
            <figure>
              <a target="_blank" rel="noreferrer" href="/project-detail1">
                <img alt="" width="100%" src={detailsObj.projectlayoutimage} />
              </a>
            </figure>
          </div>
        </div>
        <div className="row">
          <div className="col-md-6">
            <div className="my-4 text-center">
              <strong className="project-details-heading">
                {detailsObj.projectlocheading}
              </strong>
              <span className="divider-separator">
                <i className="fas fa-leaf aboutus-sub-text-head px-3"></i>
              </span>
            </div>
            <div>
              <iframe
                title="loc"
                src={detailsObj.projectLoc}
                width="100%"
                height="350"
                style={{ border: "0" }}
                allowfullscreen=""
                loading="lazy"
                referrerpolicy="no-referrer-when-downgrade"
              ></iframe>
            </div>
          </div>
          <div className="col-md-6">
            <div className="my-4 mb-3 text-center">
              <strong className="project-details-heading">
                {detailsObj.projectlochighlights}
              </strong>
              <span className="divider-separator">
                <i className="fas fa-leaf aboutus-sub-text-head px-3"></i>
              </span>
            </div>
            <div>
              <ol style={{ listStyle: "none" }}>
                <li className="py-1">
                  <i className="fas fa-circle project-heighlets-icon"></i> {""}
                  <span className="project-heighlets-text">
                    Pallavi International School
                  </span>
                </li>
                <li className="py-1">
                  <i className="fas fa-circle project-heighlets-icon"></i> {""}
                  <span className="project-heighlets-text">
                    VJIT (Vidya Jyothi Institute of Technology College in
                    Telangana)
                  </span>
                </li>
                <li className="py-1">
                  <i className="fas fa-circle project-heighlets-icon"></i> {""}
                  <span className="project-heighlets-text">
                    Shree Swaminarayan Gurukul International School
                  </span>
                </li>
                <li className="py-1">
                  <i className="fas fa-circle project-heighlets-icon"></i> {""}
                  <span className="project-heighlets-text">
                    Bhaskar Medical College
                  </span>
                </li>
                <li className="py-1">
                  <i className="fas fa-circle project-heighlets-icon"></i> {""}
                  <span className="project-heighlets-text">
                    Ayaan Institute of Medical Sciences
                  </span>
                </li>
                <li className="py-1">
                  <i className="fas fa-circle project-heighlets-icon"></i> {""}
                  <span className="project-heighlets-text">
                    Trance Green Fields Resorts
                  </span>
                </li>
                <li className="py-1">
                  <i className="fas fa-circle project-heighlets-icon"></i> {""}
                  <span className="project-heighlets-text">
                    Dr. Patnam Mahender Reddy Institute of Medical Sciences
                  </span>
                </li>
                <li className="py-1">
                  <i className="fas fa-circle project-heighlets-icon"></i> {""}
                  <span className="project-heighlets-text">
                    Encor Packaging PVT. Ltd.
                  </span>
                </li>
                <li className="py-1">
                  <i className="fas fa-circle project-heighlets-icon"></i> {""}
                  <span className="project-heighlets-text">
                    Kandlapally Reserve Forest
                  </span>
                </li>
                <li className="py-1">
                  <i className="fas fa-circle project-heighlets-icon"></i> {""}
                  <span className="project-heighlets-text">
                    Kandlapally Reserve Forest
                  </span>
                </li>
              </ol>
            </div>
          </div>
        </div>
        <div className="my-4 mb-3 text-center">
          <strong className="project-details-heading">GALLERY</strong>
          <span className="divider-separator">
            <i className="fas fa-leaf aboutus-sub-text-head px-3"></i>
          </span>
        </div>
        <div className="row my-3"></div>
      </div>
    </>
  );
};
export default ProjectDetails1;
