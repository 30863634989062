import * as Yup from "yup";

export const emailRegex =
  /^[a-zA-Z][a-zA-Z0-9_.]{1,32}@[a-zA-Z0-9_-]{2,}(\.[a-zA-Z0-9]{2,4}){1,2}$/;
export const phoneNumberRegex = /^\d{10}$/;
export const userValidationSchema = Yup.object().shape({
  name: Yup.string()
    .min(2, "Too Short!")
    .max(50, "Too Long!")
    .required("Required*"),

  email: Yup.string()
    .trim()
    .nullable()
    // .required("Required")

    .test("email", "In valid email", (val) => {
      if (!val) return true;
      return emailRegex.test(val);
    }),

  phone: Yup.string()
    .trim()
    .required("Required*")
    .test("phone", "InValid", (val) => {
      return phoneNumberRegex.test(val);
    }),
});
