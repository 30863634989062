import "./App.css";
import "@fortawesome/fontawesome-free/css/all.min.css";
// import "react-owl-carousel2/style.css";
import "aos/dist/aos.css";
import AOS from "aos";
import AppRouter from "./AppRouter";
AOS.init({
  duration: 800,
  easing: "slide",
  once: true,
  delay: 200,
});
function App() {
  return (
    <>
      <AppRouter />
    </>
  );
}

export default App;
