import React from "react";

export default function Loading() {
  return (
    <>
      <div id="loader" className="show fullscreen">
        <svg className="circular" width="48px" height="48px">
          <circle
            className="path-bg"
            cx="24"
            cy="24"
            r="22"
            fill="none"
            strokeWidth={4}
            stroke="#eeeeee"
          />
          <circle
            className="path"
            cx="24"
            cy="24"
            r="22"
            fill="none"
            strokeWidth={4}
            stroke-miterlimit="10"
            stroke="#51be78"
          />
        </svg>
      </div>
    </>
  );
}
