import UserForm from "../components/form/UserForm";
import {
  ADDRESS,
  CITY,
  contact_map,
  COUNTRY,
  EMAIL_ID,
  PHONE_NUMBER,
  STATE,
  ZIP_CODE,
} from "../configuration/config";

const ContactsPage = () => {
  return (
    <>
      <section className="banner-header banner-img section-padding valign bg-img bg-fixed photo-gallery-banner"></section>

      <div className="container">
        <div className="row d-flex justify-content-center">
          <div className="col-md-8 col-12">
            <div className="text-center">
              <span className="contact-header">
                YOUR SEARCH FOR THE RIGHT PROPERTY MADE EASY
              </span>
            </div>
          </div>
        </div>
        <div className="row mt-5">
          <div className="col-md-6">
            <p className="contact-loc-text">LOCATION</p>
            <p className="contact-us-text text-primary">CONTACT US</p>
            <p>
              Thank you so much for reaching out to us - we are excited to
              connect with you! Tell us about you.
            </p>
            <p>
              <i className="fas fa-phone-alt mr-2"></i> {PHONE_NUMBER}
            </p>
            <p>
              <i className="fas fa-envelope mr-2"></i> {EMAIL_ID}
            </p>
            <p>
              <i className="fas fa-search-location mr-2"></i> {ADDRESS},<br />{" "}
              {CITY}, {STATE} - {ZIP_CODE}, {COUNTRY}
            </p>
          </div>
          <div className="col-md-6">
            <div>
              <p className="contact-loc-text">GET IN TOUCH</p>
            </div>

            <UserForm />
            <div className="px-2 my-2">
              <span className="contact-page-disclaimer">
                {contact_map.disclaimer}
              </span>
            </div>
          </div>
        </div>
      </div>
      <div className="container-fluid">
        <div className="row">
          <div className="col-12">
            <div>
              <iframe
                title="loc"
                src={contact_map.loc}
                width="100%"
                height="350"
                style={{ border: "0" }}
                allowFullScreen=""
                loading="lazy"
                referrerPolicy="no-referrer-when-downgrade"
              ></iframe>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ContactsPage;
