import React from "react";

const TermsConditions = () => {
  return (
    <>
      <section className="banner-header banner-img section-padding valign bg-img bg-fixed photo-gallery-banner"></section>
      <div className="site-wrap pb-4">
        <div className="container">
          <div className="mt-3 text-center">
            <h2 className="text-head-color">TERMS & CONDITIONS</h2>
            <span className="divider-separator">
              <i className="fas fa-leaf aboutus-sub-text-head px-3"></i>
            </span>
          </div>
          {process.env.REACT_APP_APPLICATION_NAME !== "royal" ? (
            <div className="">
              <p className="policy-text text-justify">
                To avail the services offered at anvikainfraadevelopers.com, you
                must agree to the following terms and conditions:
              </p>
              <p className="policy-text text-justify">
                Please read the term and conditions carefully. While using any
                current or future services offered by
                anvikainfraadevelopers.com, whether or not included in the
                asrithasgroup.com. Website, you will abide by these Terms &
                conditions the guidelines and conditions applicable to such
                service or business.
              </p>
              <h3 className="text-primary">Privacy Policy</h3>
              <p className="policy-text text-justify">
                Please review our Privacy Policy, which also governs your visit
                to anvikainfraadevelopers.com, to fully understand our
                practices.
              </p>
              <h3 className="text-primary">Electronic Communication</h3>
              <p className="policy-text text-justify">
                When you visit anvikainfraadevelopers.com or send e-mails to us,
                you are communicating with us electronically. By communicating
                with us, you consent to receive communication from us
                electronically. We will communicate with you by e-mail or by
                posting notices on our website. You agree that all agreements,
                notices, disclosures, and other communications that we provide
                to you electronically satisfy any legal requirement that such
                communication be in writing.
              </p>
              <h3 className="text-primary">Prices</h3>
              <p className="policy-text text-justify">
                All prices posted on this website are subject to change without
                notice. Prices prevailing at commencement of placing the order
                will apply. Posted prices do not include convenience fee. In
                case there are any additional charges or taxes the same will be
                mentioned on the website.
              </p>
              <h3 className="text-primary">Payment</h3>
              <p className="policy-text text-justify">
                Online Payments can be done by:
              </p>
              <p className="policy-text text-justify">
                Online Payment: Card payment and Net Banking Payment via
                BillDesk Payment Gateway can be done at Online Payment link on
                Make Payment page.
              </p>
              <h3 className="text-primary">Refund</h3>
              <p className="policy-text text-justify">
                Refunds initiated will be credited to the account/card from
                where the transaction had initiated.
              </p>
              <h3 className="text-primary">
                License and Website Access General:
              </h3>
              <p className="policy-text text-justify">
                anvikainfraadevelopers.com grants you a limited license to
                access and make personal use of this website and not to download
                (other than page caching) or modify it, or any portion of it,
                except with express written consent of
                anvikainfraadevelopers.com
              </p>
              <h3 className="text-primary">No license for commercial sale:</h3>
              <p className="policy-text text-justify">
                This license does not include any resale or commercial use of
                this website or its content; any collection and use of any
                product listing, description, or pricing; copying of account
                information for the benefit of another merchant; or any use of
                data mining, or similar data gathering and extraction tools.
              </p>
              <h5 className="text-primary">No reproduction:</h5>
              <p className="policy-text text-justify">
                This website or any portion of this website may not be
                reproduced, duplicated, copies, sold, visited, or otherwise
                exploited for any commercial purpose without express written
                consent of anvikainfraadevelopers.com
              </p>
              <h5 className="text-primary">No framing:</h5>
              <p className="policy-text text-justify">
                You may not frame or utilize framing technologies to enclose any
                trademark, logo, or other proprietary information (including
                images, text, page layout, or form) of
                anvikainfraadevelopers.com without the express written consent.
              </p>
              <h5 className="text-primary">Metatags:</h5>
              <p className="policy-text text-justify">
                You may not use any metatags or any other ‘hidden text’
                utilizing anvika infraa developers name or trademarks without
                the express written consent of asrithasgroup.com. Any
                unauthorized use terminates the permission or license granted by
                anvikainfraadevelopers.com
              </p>
              <p className="policy-text text-justify">
                Reviews, Comments, Communications, and other content
              </p>
              <h5 className="text-primary">Nature of content:</h5>
              <p className="policy-text text-justify">
                Visitors to anvikainfraadevelopers.com may post content and
                other communications; and submit suggestions, ideas, comments,
                questions or other information, as long as the content is not
                illegal, obscene, threatening, defamatory, invasive of privacy,
                infringing of intellectual property rights to otherwise injuries
                to third party or objectionable and does not consist of or
                contains software virus, political campaigning, commercial
                solicitation, mass mailing or any form of spam.
              </p>
              <h5 className="text-primary">False information:</h5>
              <p className="policy-text text-justify">
                You may not use false email address, impersonate any person or
                entity, or otherwise mislead as to the origin of a card or other
                content anvikainfraadevelopers.com reserves the right (but not
                the obligation) to remove or edit such content but does not
                regularly review posted contents.
              </p>
              <h5 className="text-primary">Rights Granted:</h5>
              <p className="policy-text text-justify">
                If you do post content or submit material and unless we indicate
                otherwise, you grant anvikainfraadevelopers.com a non-exclusive,
                royalty free, perpetual, irrevocable, and fully sub-licensed
                right to use, reproduce, modify, adapt, publish, translate,
                create derivative work from, distribute, and display such
                content throughout the world in any media. You grant
                asrithasgroup.com the right to use the name that you submit in
                connection with such content if anvikainfraadevelopers.com
                chooses.
              </p>
              <h5 className="text-primary">Right Owned:</h5>
              <p className="policy-text text-justify">
                You represent and warrant that you own all the rights or
                otherwise or control all of the rights to the content that you
                post; that the content is accurate; that the use of the content
                to supply does not violate this policy and will not cause injury
                to any person or entity and that you will indemnify
                anvikainfraadevelopers.com for all claims resulting from the
                content you supply. asrithasgroup.com has the right but not the
                obligation to monitor and edit or remove any activity or
                content. asrithasgroup.com takes no responsibility and assumes
                no liability for any content posted by you or any third party.
              </p>
              <h3 className="text-primary">
                Site Policies, Modification, and Severability
              </h3>
              <p className="policy-text text-justify">
                Please review our other policies. We reserve the right to make
                changes to our website, policies, and these Terms and Conditions
                at any time. If any of these conditions shall be deemed invalid,
                void, or for any reason unenforceable, that condition shall be
                deemed severable and will not affect the validity and
                enforceability of any remaining conditions.
              </p>
              <h3 className="text-primary">INTELLECTUAL PROPERTY RIGHTS</h3>
              <h5 className="text-primary">Copyright Protection:</h5>
              <p className="policy-text text-justify">
                All content included on this site, such as text, graphics,
                logos, button icons, audio clips, digital downloads, data
                compilations and software, is the property of
                anvikainfraadevelopers.com and protected by the Indian Copyright
                law. The compilation of all the content on this site is the
                exclusive property of anvikainfraadevelopers.com and protected
                by Indian Copyright law. All software used in this site is the
                property of anvikainfraadevelopers.com and is protected under
                the Indian Copyright law.
              </p>
              <h5 className="text-primary">Trademarks:</h5>
              <ol className="policy-text">
                <li>Protected Marks:</li>

                <p className="policy-text text-justify">
                  anvikainfraadevelopers.com, and other marks indicated on
                  anvikainfraadevelopers.com website are registered trademarks
                  of anvikainfraadevelopers.com
                </p>

                <li>Protected Graphics:</li>
                <p className="policy-text text-justify">
                  All anvikainfraadevelopers.com graphics, logos, page headers,
                  button icons, scripts and service names are trademarks or
                  trade dress of anvikainfraadevelopers.com trademarks and trade
                  dress may not be used in connections with any product or
                  service that is not of anvikainfraadevelopers.com
                </p>
              </ol>

              <h5 className="text-primary">Governing Law and Jurisdiction</h5>
              <p className="policy-text text-justify">
                These terms and conditions will be construed only in accordance
                with the laws of India. In respect of all matters/disputes
                arising out of, in connection with or in relation to these terms
                and conditions or any other conditions on this website, only the
                competent Courts at Pune, Pune shall have jurisdiction, to the
                exclusion of all other courts.
              </p>
              <h5 className="text-primary">
                Disclaimer of warranties and Limitation of Liability
              </h5>
              <p className="policy-text text-justify">
                THIS SITE IS PROVIDED BY anvika infraa developers ON AN “AS IS”
                AND “AS AVAILABLE” BASIS. anvika infraa developers MAKES NO
                REPRESENTATIONS OR WARRANTIES OF ANY KIND, EXPRESS OR IMPLIED,
                AS TO THE OPERATION OF THIS SITE OR THE INFORMATION, CONTENT,
                MATERIALS, OR PRODUCTS INCLUED ON THIS SITE. YOU EXPRESSLY AGREE
                THAT YOUR USE OF THIS SITE IS AT YOUR SOLE RISK.
              </p>
              <p className="policy-text text-justify">
                TO THE FULL EXTENT PERMISSIBLE BY APPLICABLE LAW,
                anvikainfraadevelopers.com DISCLAIMS ALL WARRANTIES, EXPRESS OR
                IMPLIED, INCLUDING, BUT NOT LIMITED TO, IMPLIED WARRANTIES OF
                MERCHANTABILITY AND FITNESS FOR A PARTICULAR PURPOSE.
                anvikainfraadevelopers.com DOES NOT WARRANT THAT THE SITE, ITS
                SERVERS, OR EMAIL SENT FROM anvikainfraadevelopers.com ARE FREE
                OF VIRUS OR OTHER HARMFUL COMPONENTS. anvikainfraadevelopers.com
                WILL NOT BE LIABLE FOR ANY DAMAGES OF ANY KIND ARISING FROM THE
                USE OF THIS SITE, INCLUDING, BUT NOT LIMITED TO DIRECT,
                INDIRECT, INCIDENTAL, PUNITIVE AND CONSEQUENTIAL DAMAGES.
              </p>
            </div>
          ) : (
            <div className="">
              <p className="policy-text text-justify">
                To avail the services offered at Royal Rajarikam Developers, you
                must agree to the following terms and conditions:
              </p>
              <p className="policy-text text-justify">
                Please read the term and conditions carefully. While using any
                current or future services offered by rr.estates.com, whether or
                not included in the rr.estates.com. Website, you will abide by
                these Terms & conditions the guidelines and conditions
                applicable to such service or business.
              </p>
              <h3 className="text-primary">Privacy Policy</h3>
              <p className="policy-text text-justify">
                Please review our Privacy Policy, which also governs your visit
                to rr.estates.com, to fully understand our practices.
              </p>
              <h3 className="text-primary">Electronic Communication</h3>
              <p className="policy-text text-justify">
                When you visit rr.estates.com or send e-mails to us, you are
                communicating with us electronically. By communicating with us,
                you consent to receive communication from us electronically. We
                will communicate with you by e-mail or by posting notices on our
                website. You agree that all agreements, notices, disclosures,
                and other communications that we provide to you electronically
                satisfy any legal requirement that such communication be in
                writing.
              </p>
              <h3 className="text-primary">Prices</h3>
              <p className="policy-text text-justify">
                All prices posted on this website are subject to change without
                notice. Prices prevailing at commencement of placing the order
                will apply. Posted prices do not include convenience fee. In
                case there are any additional charges or taxes the same will be
                mentioned on the website.
              </p>
              <h3 className="text-primary">Payment</h3>
              <p className="policy-text text-justify">
                Online Payments can be done by:
              </p>
              <p className="policy-text text-justify">
                Online Payment: Card payment and Net Banking Payment via
                BillDesk Payment Gateway can be done at Online Payment link on
                Make Payment page.
              </p>
              <h3 className="text-primary">Refund</h3>
              <p className="policy-text text-justify">
                Refunds initiated will be credited to the account/card from
                where the transaction had initiated.
              </p>
              <h3 className="text-primary">
                License and Website Access General:
              </h3>
              <p className="policy-text text-justify">
                rr.estates.com grants you a limited license to access and make
                personal use of this website and not to download (other than
                page caching) or modify it, or any portion of it, except with
                express written consent of rr.estates.com
              </p>
              <h3 className="text-primary">No license for commercial sale:</h3>
              <p className="policy-text text-justify">
                This license does not include any resale or commercial use of
                this website or its content; any collection and use of any
                product listing, description, or pricing; copying of account
                information for the benefit of another merchant; or any use of
                data mining, or similar data gathering and extraction tools.
              </p>
              <h5 className="text-primary">No reproduction:</h5>
              <p className="policy-text text-justify">
                This website or any portion of this website may not be
                reproduced, duplicated, copies, sold, visited, or otherwise
                exploited for any commercial purpose without express written
                consent of rr.estates.com
              </p>
              <h5 className="text-primary">No framing:</h5>
              <p className="policy-text text-justify">
                You may not frame or utilize framing technologies to enclose any
                trademark, logo, or other proprietary information (including
                images, text, page layout, or form) of rr.estates.com without
                the express written consent.
              </p>
              <h5 className="text-primary">Metatags:</h5>
              <p className="policy-text text-justify">
                You may not use any metatags or any other ‘hidden text’
                utilizing Royal Rajarikam Developers name or trademarks without
                the express written consent of rr.estates.com. Any unauthorized
                use terminates the permission or license granted by
                rr.estates.com
              </p>
              <p className="policy-text text-justify">
                Reviews, Comments, Communications, and other content
              </p>
              <h5 className="text-primary">Nature of content:</h5>
              <p className="policy-text text-justify">
                Visitors to rr.estates.com may post content and other
                communications; and submit suggestions, ideas, comments,
                questions or other information, as long as the content is not
                illegal, obscene, threatening, defamatory, invasive of privacy,
                infringing of intellectual property rights to otherwise injuries
                to third party or objectionable and does not consist of or
                contains software virus, political campaigning, commercial
                solicitation, mass mailing or any form of spam.
              </p>
              <h5 className="text-primary">False information:</h5>
              <p className="policy-text text-justify">
                You may not use false email address, impersonate any person or
                entity, or otherwise mislead as to the origin of a card or other
                content. rr.estates.com reserves the right (but not the
                obligation) to remove or edit such content but does not
                regularly review posted contents.
              </p>
              <h5 className="text-primary">Rights Granted:</h5>
              <p className="policy-text text-justify">
                If you do post content or submit material and unless we indicate
                otherwise, you grant rr.estates.com a non-exclusive, royalty
                free, perpetual, irrevocable, and fully sub-licensed right to
                use, reproduce, modify, adapt, publish, translate, create
                derivative work from, distribute, and display such content
                throughout the world in any media. You grant rr.estates.com the
                right to use the name that you submit in connection with such
                content if rr.estates.com chooses.
              </p>
              <h5 className="text-primary">Right Owned:</h5>
              <p className="policy-text text-justify">
                You represent and warrant that you own all the rights or
                otherwise or control all of the rights to the content that you
                post; that the content is accurate; that the use of the content
                to supply does not violate this policy and will not cause injury
                to any person or entity and that you will indemnify
                rr.estates.com for all claims resulting from the content you
                supply. rr.estates.com has the right but not the obligation to
                monitor and edit or remove any activity or content.
                rr.estates.com takes no responsibility and assumes no liability
                for any content posted by you or any third party.
              </p>
              <h3 className="text-primary">
                Site Policies, Modification, and Severability
              </h3>
              <p className="policy-text text-justify">
                Please review our other policies. We reserve the right to make
                changes to our website, policies, and these Terms and Conditions
                at any time. If any of these conditions shall be deemed invalid,
                void, or for any reason unenforceable, that condition shall be
                deemed severable and will not affect the validity and
                enforceability of any remaining conditions.
              </p>
              <h3 className="text-primary">INTELLECTUAL PROPERTY RIGHTS</h3>
              <h5 className="text-primary">Copyright Protection:</h5>
              <p className="policy-text text-justify">
                All content included on this site, such as text, graphics,
                logos, button icons, audio clips, digital downloads, data
                compilations and software, is the property of rr.estates.com and
                protected by the Indian Copyright law. The compilation of all
                the content on this site is the exclusive property o Indian
                Copyright law. All software used in this site is the property of
                rr.estates.com and is protected under the Indian Copyright law.
              </p>
              <h5 className="text-primary">Trademarks:</h5>
              <ol className="policy-text">
                <li>Protected Marks:</li>

                <p className="policy-text text-justify">
                  rr.estates.com, and other marks indicated on rr.estates.com
                  website are registered trademarks of rr.estates.com
                </p>

                <li>Protected Graphics:</li>
                <p className="policy-text text-justify">
                  All rr.estates.com graphics, logos, page headers, button
                  icons, scripts and service names are trademarks or trade dress
                  of rr.estates.com .rr.estates.com trademarks and trade dress
                  may not be used in connections with any product or service
                  that is not of rr.estates.com.
                </p>
              </ol>

              <h5 className="text-primary">Governing Law and Jurisdiction</h5>
              <p className="policy-text text-justify">
                These terms and conditions will be construed only in accordance
                with the laws of India. In respect of all matters/disputes
                arising out of, in connection with or in relation to these terms
                and conditions or any other conditions on this website, only the
                competent Courts at Pune, Pune shall have jurisdiction, to the
                exclusion of all other courts.
              </p>
              <h5 className="text-primary">
                Disclaimer of warranties and Limitation of Liability
              </h5>
              <p className="policy-text text-justify">
                this site is provided by rr.estates private limited on an “as
                is” and “as available” basis. rr.estates group limited makes no
                representations or warranties of any kind, express or implied,
                as to the operation of this site or the information, content,
                materials, or products inclued on this site. you expressly agree
                that your use of this site is at your sole risk.
              </p>
              <p className="policy-text text-justify">
                To the full extent permissible by applicable law rr.estates.com
                disclaims all warranties, express or implied, including, but not
                limited to, implied warranties of merchantability and fitness
                for a particular purpose. rr.estates.com does not warrant that
                the site, its servers, or email sent from rr.estates.com are
                free of virus or other harmful components. rr.estates.com will
                not be liable for any damages of any kind arising from the use
                of this site, including, but not limited to direct, indirect,
                incidental, punitive and consequential damages.
              </p>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default TermsConditions;
