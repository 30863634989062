import { video_config } from "configuration/config";

export default function VideoSection() {
  return (
    <>
      <div
        className={
          "about section-padding" +
          (Object.keys(video_config).length === 0 ? " d-none" : "")
        }
        data-aos="fade-up"
      >
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="project-video left">
                <figure>
                  <img
                    src={video_config.image}
                    alt="img"
                    className="img-fluid"
                  />
                  <div className="row">
                    <div className="col-md-12 offset-md-4 valign v-middle clear">
                      <div className="vid-area">
                        <div className="vid-icon">
                          <a
                            className="play-button"
                            target="_blank"
                            href={video_config.video}
                            rel="noreferrer"
                            style={{
                              boxShadow: "none",
                              textDecoration: "none",
                            }}
                          >
                            <svg className="circle-fill">
                              <circle
                                cx="43"
                                cy="43"
                                r="39"
                                stroke="#fff"
                                strokeWidth="1"
                              ></circle>
                            </svg>
                            <svg className="circle-track">
                              <circle
                                cx="43"
                                cy="43"
                                r="39"
                                stroke="none"
                                strokeWidth="1"
                                fill="none"
                              ></circle>
                            </svg>
                            <span className="polygon">
                              <i className="fas fa-play"></i>
                            </span>
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </figure>
                <div className="caption">
                  <span className="text-white">Take a look at our</span>
                  <div className="section-title">{video_config.title}</div>
                  <p className="text-white" style={{ letterSpacing: 0 }}>
                    {video_config.description}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
