import { our_project_config } from "configuration/config";
import ProjectCard from "./ProjectCard";

const Projects = () => {
  return (
    <>
      <section className="banner-header banner-img section-padding valign bg-img bg-fixed photo-gallery-banner"></section>
      <div className="site-wrap">
        <div className="container">
          <div className="mt-3 text-center">
            <strong className="project_text_head">ON GOING PROJECTS</strong>
            <span className="divider-separator">
              <i className="fas fa-leaf aboutus-sub-text-head px-3"></i>
            </span>
          </div>

          <div className="row">
            <div className="col-lg-12">
              <div className="row">
                {our_project_config.map((item, index) => {
                  return (
                    <ProjectCard
                      number={index + 1}
                      image={item.image}
                      title={item.title}
                      link={item.link}
                    />
                  );
                })}
              </div>
            </div>
          </div>
          {/* <div className="row">
            <div className="col-lg-12">
              <div className="row">
                <div className="elementor-column elementor-col-50 p-2">
                  <div className="row">
                    <div className="col-md-12">
                      <div className="projects2">
                        <figure>
                          <a href="/project-detail1">
                            <img alt="" src={PROJECT_IMAGE_1} />
                          </a>
                        </figure>
                        <div className="caption">
                          <div className="section-number">.02</div>
                          <h4>SILPA BAMBOO ARANYA @ MANNEGUDA</h4>
                          <div>
                            <a
                              href="/silpa-nature-avenue/"
                              className="btn custom-btn to-right"
                            >
                              <span>
                                VIEW PROJECT{" "}
                                <i className="fas fa-arrow-right"></i>
                              </span>
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="elementor-column elementor-col-50 p-2">
                  <div className="row">
                    <div className="col-md-12">
                      <div className="projects2 left">
                        <figure>
                          <a href="/silpa-nature-avenue/">
                            <img alt="" src={PROJECT_IMAGE_2} />
                          </a>
                        </figure>
                        <div className="caption">
                          <div className="section-number">.02</div>
                          <h4>Anvika Nature Avenue @ Gudoor</h4>

                          <div>
                            <a
                              href="/silpa-nature-avenue/"
                              className="btn custom-btn to-right"
                            >
                              <span>
                                VIEW PROJECT{" "}
                                <i className="fas fa-arrow-right"></i>
                              </span>
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div> */}
          {/* <div className="row">
            <div className="col-lg-12">
              <div className="row">
                <div className="elementor-column elementor-col-50 p-2">
                  <div className="row">
                    <div className="col-md-12">
                      <div className="projects2 left">
                        <figure>
                          <a href="/silpa-nature-avenue/">
                            <img alt="" src={PROJECT_IMAGE_3} />
                          </a>
                        </figure>
                        <div className="caption">
                          <div className="section-number">.02</div>
                          <h4>SILPA NATURE VANADHARA @ KOTHUR</h4>
                          <div>
                            <a
                              href="/silpa-nature-avenue/"
                              className="btn custom-btn to-right"
                            >
                              <span>
                                VIEW PROJECT{" "}
                                <i className="fas fa-arrow-right"></i>
                              </span>
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="elementor-column elementor-col-50 p-2">
                  <div className="row">
                    <div className="col-md-12">
                      <div className="projects2 ">
                        <figure>
                          <a href="/silpa-nature-avenue/">
                            <img alt="" src={PROJECT_IMAGE_4} />
                          </a>
                        </figure>
                        <div className="caption">
                          <div className="section-number">.02</div>
                          <h4>SILPA SR BAMBOO GROVE @ SADHPET</h4>

                          <div>
                            <a
                              href="/silpa-nature-avenue/"
                              className="btn custom-btn to-right"
                            >
                              <span>
                                VIEW PROJECT{" "}
                                <i className="fas fa-arrow-right"></i>
                              </span>
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="row mt-3 mb-4">
            <div className="col-lg-12">
              <div className="row">
                <div className="elementor-column elementor-col-25 p-2"></div>
                <div className="elementor-column elementor-col-50 p-2">
                  <div className="row">
                    <div className="col-md-12">
                      <div className="projects2">
                        <figure>
                          <a href="/silpa-nature-avenue/">
                            <img alt="" src={PROJECT_IMAGE_5} />
                          </a>
                        </figure>
                        <div className="caption">
                          <div className="section-number">.02</div>
                          <h4>SILPA CSK GREEN VILLAS @ SHADNAGAR</h4>
                          <div>
                            <a
                              href="/silpa-nature-avenue/"
                              className="btn custom-btn to-right"
                            >
                              <span>
                                VIEW PROJECT{" "}
                                <i className="fas fa-arrow-right"></i>
                              </span>
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="elementor-column elementor-col-25 p-2"></div>
              </div>
            </div>
          </div>

          <div className="my-5 text-center">
            <strong className="project_text_head">UP COMING PROJECTS</strong>
            <span className="divider-separator">
              <i className="fas fa-leaf aboutus-sub-text-head px-3"></i>
            </span>
          </div>

          <div className="row">
            <div className="col-lg-12">
              <div className="row">
                <div className="elementor-column elementor-col-25 p-2"></div>
                <div className="elementor-column elementor-col-50 p-2">
                  <div className="row">
                    <div className="col-md-12">
                      <div className="projects2 left">
                        <figure>
                          <a href="/silpa-nature-avenue/">
                            <img alt="" src={PROJECT_IMAGE_6} />
                          </a>
                        </figure>
                        <div className="caption">
                          <div className="section-number">.02</div>
                          <h4>SILPA BOTANICA @ PHARMA CITY</h4>
                          <div>
                            <a
                              href="/silpa-nature-avenue/"
                              className="btn custom-btn to-right"
                            >
                              <span>
                                VIEW PROJECT{" "}
                                <i className="fas fa-arrow-right"></i>
                              </span>
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="elementor-column elementor-col-25 p-2"></div>
              </div>
            </div>
          </div> */}
        </div>
      </div>
    </>
  );
};

export default Projects;
