import img1 from "../assets/images/anvika_25Years.png";
import {
  default as img2,
  default as img8,
} from "../assets/images/anvika_hassle_free.png";
import img5 from "../assets/images/anvika_higher_returns.png";
import img4 from "../assets/images/anvika_prime_locations.png";
import img3 from "../assets/images/anvika_timely_delivered.png";
import img6 from "../assets/images/anvika_top_notch_development.png";
import img7 from "../assets/images/anvika_trust_management.png";

/** Landing Page */
const web_title_anvi = "Anvika Infra";
const carousel_config_anvi = [
  {
    class: "bg-hero-1",
    title: "PLOT YOUR FUTURE WITH ANVIKA",
  },
  {
    class: "bg-hero-2",
    title: "EXPERIENCE A SERENE LIFESTYLE",
  },
  {
    class: "bg-hero-3",
    title: "INVEST TODAY FOR A BETTER TOMORROW",
  },
];

const about_config_anvi = {
  image:
    "https://firebasestorage.googleapis.com/v0/b/anvikainfra.appspot.com/o/anvika_images%2F10.jpg?alt=media&token=2bae7ca9-79eb-47d5-b289-763c84657470",
  description:
    "Anvika Infraa Developers is one of the most reputed and well-recognized Real Estate Developers in Telangana. Over the years, we have been employing our best methods to convert our clients’ ideas into magnificent infrastructure, aiming to become the Top Real Estate Company. Anvika Infraa Developers focuses on developing the properties keeping in mind what is best for our customers.",
};

const video_config_anvi = {
  title: "Anvika Infra",
  video: "https://www.youtube.com/embed/DLWbnBLcTUk",
  image:
    "https://firebasestorage.googleapis.com/v0/b/anvikainfra.appspot.com/o/anvika_images%2F11.jpg?alt=media&token=5422ed09-9cbd-484c-a6bd-f1e2f23ab4ba",
  description:
    "Anvika Infraa Developers is one of the most reputed and well-recognized Real Estate Developers in Telangana. Over the years, we have been employing our best methods to convert our clients’ ideas into magnificent infrastructure, aiming to become the Top Real Estate Company. Anvika Infraa Developers focuses on developing the properties keeping in mind what is best for our customers.",
};

const our_project_config_anvi = [
  {
    id: 1,
    title: "OPEN VILLA PLOTS @ SADASHIVAPET",
    image:
      "https://firebasestorage.googleapis.com/v0/b/anvikainfra.appspot.com/o/anvika_images%2F12.jpg?alt=media&token=51a29fa8-f654-4af8-aab0-07bb8e914d5c",
    description: "Green Villas @ Shadnagar",
    link: "/project?id=1",
    plink: "/photos?id=1",
    youtube: "https://www.youtube.com/embed/DLWbnBLcTUk",
  },
  {
    id: 2,
    title: "APARTMENTS @ KOMPALLY",
    image:
      "https://firebasestorage.googleapis.com/v0/b/anvikainfra.appspot.com/o/anvika_images%2F14.jpg?alt=media&token=aa520eb8-f1bd-4a98-ab1e-a64e750e0593",
    description: "Vanadhara @ Shadnagar",
    link: "project?id=2",
    plink: "/photos?id=2",
    youtube: "https://www.youtube.com/embed/iDngz2fS93M",
  },
  {
    id: 3,
    title: "Flats @ KOKAPET",
    image:
      "https://firebasestorage.googleapis.com/v0/b/anvikainfra.appspot.com/o/anvika_images%2Fprojects%2Ftellapur%2Fkokapet-5.jpg?alt=media&token=7614ba91-2628-418d-a708-74a4efdb2fcf",
    description: "Flats @ KOKAPET",
    link: "project?id=3",
    plink: "/photos?id=3",
    youtube: "https://www.youtube.com/embed/VbPa0_PJYMI",
  },
  {
    id: 4,
    title: "APARTMENTS @ GOPANPALLY",
    image:
      "https://firebasestorage.googleapis.com/v0/b/anvikainfra.appspot.com/o/anvika_images%2F17.jpg?alt=media&token=2bb04543-b49e-485a-b937-11e6af934a89",
    description: "Arayana @ Shadnagar",
    link: "project?id=4",
    plink: "/photos?id=4",
    youtube: "https://www.youtube.com/embed/780pJprihW8",
  },
  {
    id: 5,
    title: "APARTMENTS @ PATANCHERU",
    image:
      "https://firebasestorage.googleapis.com/v0/b/anvikainfra.appspot.com/o/anvika_images%2Fprojects%2FPatancheruvu%2FPatancheruvu.jpg?alt=media&token=e1d17786-85ba-4ea8-a740-b899b5dab829",
    // "https://firebasestorage.googleapis.com/v0/b/anvikainfra.appspot.com/o/anvika_images%2F18.jpg?alt=media&token=1520b1b2-0fc1-47a5-b63a-4ac87e858326",
    description: "Arayana @ Shadnagar",
    link: "project?id=5",
    plink: "/photos?id=5",
    youtube: "https://www.youtube.com/embed/l7oWmr-VyxU",
  },
  {
    id: 6,
    title: "LUXURIOUS GATED @ MANIKONDA",
    image:
      "https://firebasestorage.googleapis.com/v0/b/anvikainfra.appspot.com/o/anvika_images%2Fprojects%2Fgreenspace%2Fgreenspace-24.png?alt=media&token=d4cb68ce-4d70-449e-9ae4-11bd679a14c1",
    description: "LUXURIOUS GATED COMMUNITY FLATS @ MANIKONDA",
    link: "project?id=6",
    plink: "/photos?id=6",
    youtube: "https://www.youtube.com/watch?v=jU7mPAFxJoE",
  },
];

const clients_count_value_config_anvi = {
  years: 6,
  past_projects: 20,
  happy_customers: 10000,
  acres_developed: 1126,
  acres_under_development: 344,
  acres_under_future_development: 872,
};

const clients_count_config_anvi = [
  {
    key: "years",
    title: "Years of Experience",
    suffix: "+",
  },
  {
    key: "past_projects",
    title: "Projects Handed Over",
    suffix: "+",
  },
  {
    key: "happy_customers",
    title: "Happy Customers",
    suffix: "+",
  },
  {
    key: "acres_developed",
    title: "Acres Developed",
    suffix: "",
  },
  {
    key: "acres_under_development",
    title: "Acres Under Development",
    suffix: "",
  },
  {
    key: "acres_under_future_development",
    title: "Acres Under Future Development",
    suffix: "",
  },
];

const clients_testimonial_config_anvi = [
  {
    name: "Mr. Sharique Azam",
    testimony:
      "The service provided by Anvika Infra Developers was incredible. They helped me to clear out all my doubts and queries about purchasing the property. Thanks for all your help and support.",
  },
  {
    name: "Mrs. Sinjini Das",
    testimony:
      "Anvika Infra Developers  has been a great help in my home buying journey. They have always been reachable over phone and in person. They guided us very well on the day of the site visit. We would like them to continue to assist us in the future as well.",
  },
  {
    name: "Mr. Arindam Samanta",
    testimony:
      "Iam very much satisfied with their assistance. They helped me with negotiations and all the documentation work without charging any brokerage. l highly recommend Shri Vriddhi if you are looking for your dream property. In the future, I wish to contact them if I will think of the next property.",
  },
  {
    name: "Dr. Ariful Haque",
    testimony:
      "I had a wonderful time with Anvika Infra Developers. They have been extremely helpful and have guided me well to purchase my dream property. They took great care of my references as well. Wishing them all the best in their future endeavours.",
  },
  {
    name: "Mr. Sudip Roy",
    testimony:
      "I wanted to purchase a flat in The One, but the flats were not available as per my Vastu choice. However, your company arranged it for me. Your company and the sales manager are very cooperative till date.",
  },
];

const blog_config_anvi = [
  {
    id: 1,
    date: "April 1, 2020",
    label: "Why Invest in Anvika - Infra",
    link: "/blog/1",
    image:
      "https://firebasestorage.googleapis.com/v0/b/anvikainfra.appspot.com/o/anvika_images%2F7.jpg?alt=media&token=fd9e9f0d-f5e6-4a52-bc40-c7fd294e5c29",
  },
  {
    id: 2,
    date: "April 30, 2020",
    label: "Why Invest in Anvika - Infra",
    link: "/blog/2",
    image:
      "https://firebasestorage.googleapis.com/v0/b/anvikainfra.appspot.com/o/anvika_images%2F12.jpg?alt=media&token=51a29fa8-f654-4af8-aab0-07bb8e914d5c",
  },
];

const project_details_config_anvi = [
  {
    heading: "OPEN VILLA PLOTS @ SADASIVAPET",
    subheading: "WELL-DEVELOPED YET SUSTAINABLE – LEAD A UNIQUE LIFESTYLE",
    description1:
      "Anvika Infraa Developers are a venture located in a serene and peaceful environment that promotes a modern, organized way of living. This venture is located near Muthangi ORR junction. It allows for easy access to the city. The entire layout has been approved by DTCP and is designed in a gated community format that is secure and well connected via internal roads.",
    description2:
      "This property offers all the amenities you need: a grand entrance gateway, a compound wall for the entire community, landscaped gardens and avenue plantations as well as BT roads measuring 40 feet in width. It also has well-developed drainage and water systems. All of our projects are well-known for their promise returns, and Anvika Infraa Developers has no exception. We are proud to have been involved in this venture.",

    description3:
      "This property offers all the amenities you need: a grand entrance gateway, a compound wall for the entire community, landscaped gardens and avenue plantations as well as BT roads measuring 40 feet in width. It also has well-developed drainage and water systems.",

    disclaimer:
      "Disclaimer: I authorize Anvika Infratech Ltd and its representatives to Call, SMS, Email or WhatsApp me about its ventures, investment opportunities and offers. This consent overrides any registration for DNC / NDNC.",
    youtube: "https://www.youtube.com/embed/DLWbnBLcTUk",

    brochure1:
      "https://firebasestorage.googleapis.com/v0/b/anvikainfra.appspot.com/o/anvika_images%2Fprojects%2Fsadasivapet%2Fanvika%20infraa%20developers%20sadasivapet.pdf?alt=media&token=f0ec24cc-c0c9-43ad-85dd-8a7e45fb4cb3",
    projectheading: "PROJECT HIGHLIGHTS",

    project_highlights: [
      {
        text: "100% Vastu Compliance",
        image:
          "https://firebasestorage.googleapis.com/v0/b/anvikainfra.appspot.com/o/anvika_images%2Fprojects%2Fproject_highlights%2Fgrand_entrance_gate.png?alt=media&token=8b2fac81-ab0e-4271-aa91-23c763338964",
      },
      {
        text: "60, 40 & 33 ft BT Roads",
        image:
          "https://firebasestorage.googleapis.com/v0/b/anvikainfra.appspot.com/o/anvika_images%2Fprojects%2Fproject_highlights%2Fbt_top_roads.png?alt=media&token=624abdd0-1673-46ff-b5b8-f7d111ccc651",
      },
      {
        text: "Underground Drainage",
        image:
          "https://firebasestorage.googleapis.com/v0/b/anvikainfra.appspot.com/o/anvika_images%2Fprojects%2Fproject_highlights%2Fwater_pipeline.png?alt=media&token=7a02c66d-b9ae-4a53-9fee-1f9bf5b8ae96",
      },
      {
        text: "Entrance arch with 24/7 security",
        image:
          "https://firebasestorage.googleapis.com/v0/b/anvikainfra.appspot.com/o/anvika_images%2Fprojects%2Fproject_highlights%2Fguest_rooms.png?alt=media&token=f708d1b6-3f32-4f4e-b5b0-87d684e75f8c",
      },
      {
        text: "Electrical Lines with designer lights",
        image:
          "https://firebasestorage.googleapis.com/v0/b/anvikainfra.appspot.com/o/anvika_images%2Fprojects%2Fproject_highlights%2Fstreet_lights.png?alt=media&token=7cc6c780-1f86-4546-9c60-fed6d93c9c34",
      },
      {
        text: "Well designed land scaped parks",
        image:
          "https://firebasestorage.googleapis.com/v0/b/anvikainfra.appspot.com/o/anvika_images%2Fprojects%2Fproject_highlights%2Favenue_plantation.png?alt=media&token=d0b1e03c-fec6-4360-9b50-36ef7d513cdb",
      },
      {
        text: "Clear Title",
        image:
          "https://firebasestorage.googleapis.com/v0/b/anvikainfra.appspot.com/o/anvika_images%2Fprojects%2Fproject_highlights%2Fclub_house.png?alt=media&token=d8fa68cd-8a13-4f5c-ae3f-1e62bd4de7ef",
      },
      {
        text: "Avenue Plantation",
        image:
          "https://firebasestorage.googleapis.com/v0/b/anvikainfra.appspot.com/o/anvika_images%2Fprojects%2Fproject_highlights%2Flandscape_gardens.png?alt=media&token=0d99132f-0a03-4b88-9eb6-94ee3a97feb0",
      },
      {
        text: "Children Play Area",
        image:
          "https://firebasestorage.googleapis.com/v0/b/anvikainfra.appspot.com/o/anvika_images%2Fprojects%2Fproject_highlights%2Foutdoor_games.png?alt=media&token=217d25c9-c610-4a20-b2f4-bb87a74139f7",
      },
      {
        text: "Rain Water Harvesting",
        image:
          "https://firebasestorage.googleapis.com/v0/b/anvikainfra.appspot.com/o/anvika_images%2Fprojects%2Fproject_highlights%2Fsenior_citizen_plaza.png?alt=media&token=e6b080c2-6e6f-4b20-a6e4-df6e2a5e34aa",
      },
      {
        text: "Overhead tank & 24/7 water facility to each plot",
        image:
          "https://firebasestorage.googleapis.com/v0/b/anvikainfra.appspot.com/o/anvika_images%2Fprojects%2Fproject_highlights%2Fwater_pipeline.png?alt=media&token=7a02c66d-b9ae-4a53-9fee-1f9bf5b8ae96",
      },
    ],
    layoutheading: "PROJECT LAYOUT",
    projectlayoutimage:
      "https://firebasestorage.googleapis.com/v0/b/anvikainfra.appspot.com/o/anvika_images%2Fprojects%2Fsadasivapet%2FAnvika%20Layout%20Final.png?alt=media&token=a8342128-7f9a-4431-aac0-a91f14b9220b",
    broucher2: "https://silpa.co.in/aranya_brochure.pdf",
    downloadlayoutimage:
      "https://firebasestorage.googleapis.com/v0/b/anvikainfra.appspot.com/o/anvika_images%2Fprojects%2Fsadasivapet%2FAnvika%20Layout%20Final.png?alt=media&token=a8342128-7f9a-4431-aac0-a91f14b9220b",
    projectlocheading: "PROJECT LOCATION",
    brochure2:
      "https://firebasestorage.googleapis.com/v0/b/anvikainfra.appspot.com/o/anvika_images%2Fprojects%2Fsadasivapet%2Fanvika%20infraa%20developers%20sadasivapet.pdf?alt=media&token=f0ec24cc-c0c9-43ad-85dd-8a7e45fb4cb3",

    projectlochighlights: "LOCATION HIGHLIGHTS",

    projectLoc:
      "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3802.3574342412335!2d77.8906389!3d17.6332222!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0xab36c0539bc35736!2zMTfCsDM3JzU5LjYiTiA3N8KwNTMnMjYuMyJF!5e0!3m2!1sen!2sin!4v1658991687374!5m2!1sen!2sin",

    location_highlights: [
      "2 Mins Drive From Sadasivapet",
      "5 Mins Drive to RRR",
      "15 Mins Drive to IIT Kandi",
      "20 Mins Drive to NIMZ (13000 Acres)",
      "25Mins Drive to GITAM University",
      "25 Mins Drive Zaheerabad",
      "30 Mins Drive to ORR",
      "40 Mins Drive to Patancheru",
      "50 Mins Drive to BHEL",
      "Adjacent to MRF",
      "Opposite to Woxen University",
    ],
    gallery: [
      "https://firebasestorage.googleapis.com/v0/b/anvikainfra.appspot.com/o/anvika_images%2Fprojects%2Fsadasivapet%2FSADASIVAPET%201.png?alt=media&token=b84785ef-dae0-4419-be94-e9b7af90b6e4",
      "https://firebasestorage.googleapis.com/v0/b/anvikainfra.appspot.com/o/anvika_images%2Fprojects%2Fsadasivapet%2FSADASIVAPET%202.jpg?alt=media&token=1496d982-3e8a-4ec1-b72b-4ca8661570e2",
      "https://firebasestorage.googleapis.com/v0/b/anvikainfra.appspot.com/o/anvika_images%2Fprojects%2Fsadasivapet%2FSADASIVAPET%206.png?alt=media&token=d5994b7f-f99a-4547-b8c2-83ced62fcea5",
      "https://firebasestorage.googleapis.com/v0/b/anvikainfra.appspot.com/o/anvika_images%2Fprojects%2Fsadasivapet%2FSADASIVAPET%20IMAGE%203.jpg?alt=media&token=ff15b4ef-d575-4fdb-b1c0-43c90c5acc44",
      "https://firebasestorage.googleapis.com/v0/b/anvikainfra.appspot.com/o/anvika_images%2Fprojects%2Fsadasivapet%2FSADASIVAPET%20Image%204.jpg?alt=media&token=2d2f34d1-2822-43df-a517-a92b93fc7381",
      "https://firebasestorage.googleapis.com/v0/b/anvikainfra.appspot.com/o/anvika_images%2Fprojects%2Fsadasivapet%2FSADASIVAPET%20image%205.png?alt=media&token=2dc84570-aa01-4fc1-b0c5-4aa17de71357",
    ],
    galleryVideo: [],
    videoThumnail: "",
  },
  {
    heading: "GATED COMMUNITY APARTMENT FLATS  @ KOMPALLY",
    subheading: "WELL-DEVELOPED YET SUSTAINABLE – LEAD A UNIQUE LIFESTYLE",
    description1:
      "Sunder Ecopolis is a project by Sunder Homes Hyderabad in Hyderabad. It is a Under Construction project. Sunder Ecopolis offers some of the most conveniently designed Apartment. Located in Kompally, it is a residential project. The project is spread over 2.11 Acres . Sunder Ecopolis offers some of the most exclusive 3 BHK, 4 BHK. As per the area plan, units are in the size range of 2307.0 - 4036.0 sq.ft.. Launched in May 2022, Sunder Ecopolis is slated for possession in Dec, 2025. The address of Sunder Ecopolis is Kompally. Sunder Ecopolis ensures a coveted lifestyle and offers a convenient living. It offers facilities such as Gymnasium. Residents can also enjoy Restaurants/ Cafeterias provisions in the project.",
    description2:
      "Sunder Ecopolis is now available with attractive Pre Launch Offer 3200/-Per Sqft. The project is RERA approved. For details, check RERA ID RERA Not Applicable. The firm has since then developed 1 projects and focuses on customer-centricity. Kompally is well-connected to other parts of city by road, which passes through the heart of this suburb. Prominent shopping malls, movie theatres, school, and hospitals are present in proximity of this residential project.",

    description3:
      "This property offers all the amenities you need: a grand entrance gateway, a compound wall for the entire community, landscaped gardens and avenue plantations as well as BT roads measuring 40 feet in width. It also has well-developed drainage and water systems.",

    disclaimer:
      "Disclaimer: I authorize Anvika Infratech Ltd and its representatives to Call, SMS, Email or WhatsApp me about its ventures, investment opportunities and offers. This consent overrides any registration for DNC / NDNC.",
    youtube: "https://www.youtube.com/embed/iDngz2fS93M",

    brochure1:
      "https://firebasestorage.googleapis.com/v0/b/anvikainfra.appspot.com/o/anvika_images%2Fprojects%2Fkompally%2FSUNDER%20ECOPOLIS%20APARTMENT%20DEVELOPMENT.pdf?alt=media&token=e44df4b1-bdf7-4818-a533-94d8961db22a",
    projectheading: "PROJECT HIGHLIGHTS",

    project_highlights: [
      {
        text: "100% Vastu Compliance",
        image:
          "https://firebasestorage.googleapis.com/v0/b/anvikainfra.appspot.com/o/anvika_images%2Fprojects%2Fproject_highlights%2Fgrand_entrance_gate.png?alt=media&token=8b2fac81-ab0e-4271-aa91-23c763338964",
      },
      {
        text: "Swimming Pool with Deck, Kids Pool",
        image:
          "https://firebasestorage.googleapis.com/v0/b/anvikainfra.appspot.com/o/anvika_images%2Fprojects%2Fproject_highlights%2Flandscape_gardens.png?alt=media&token=0d99132f-0a03-4b88-9eb6-94ee3a97feb0",
      },
      {
        text: "Kids Play Area, Desginer Land Scape,Fully Equipped A/C Gym",
        image:
          "https://firebasestorage.googleapis.com/v0/b/anvikainfra.appspot.com/o/anvika_images%2Fprojects%2Fproject_highlights%2Foutdoor_games.png?alt=media&token=217d25c9-c610-4a20-b2f4-bb87a74139f7",
      },
      {
        text: "Yoga, Meditation and Aerobic Center, Mini Theatre",
        image:
          "https://firebasestorage.googleapis.com/v0/b/anvikainfra.appspot.com/o/anvika_images%2Fprojects%2Fproject_highlights%2Fbanquet_hall.png?alt=media&token=bcae198a-94ab-432f-80d3-bae81d571425",
      },
      {
        text: "Suits Rooms, Banquet Halls, Amphi Theatre, Jogging Track",
        image:
          "https://firebasestorage.googleapis.com/v0/b/anvikainfra.appspot.com/o/anvika_images%2Fprojects%2Fproject_highlights%2Fclub_house.png?alt=media&token=d8fa68cd-8a13-4f5c-ae3f-1e62bd4de7ef",
      },
      {
        text: "Basket Ball Court, EV Charging point, Power Backup",
        image:
          "https://firebasestorage.googleapis.com/v0/b/anvikainfra.appspot.com/o/anvika_images%2Fprojects%2Fproject_highlights%2Fbt_top_roads.png?alt=media&token=624abdd0-1673-46ff-b5b8-f7d111ccc651",
      },
      {
        text: "CCTV surveillances/24 hours Security, Intercom",
        image:
          "https://firebasestorage.googleapis.com/v0/b/anvikainfra.appspot.com/o/anvika_images%2Fprojects%2Fproject_highlights%2Fsenior_citizen_plaza.png?alt=media&token=e6b080c2-6e6f-4b20-a6e4-df6e2a5e34aa",
      },
      {
        text: "Library, Pet court, Workstations with WIFI Enabled Hotspots",
        image:
          "https://firebasestorage.googleapis.com/v0/b/anvikainfra.appspot.com/o/anvika_images%2Fprojects%2Fproject_highlights%2Fclub_house.png?alt=media&token=d8fa68cd-8a13-4f5c-ae3f-1e62bd4de7ef",
      },
      {
        text: "Super market, Individual Gas Connection, Senior Citizen Court",
        image:
          "https://firebasestorage.googleapis.com/v0/b/anvikainfra.appspot.com/o/anvika_images%2Fprojects%2Fproject_highlights%2Frestaurant.png?alt=media&token=b6dcc951-38fe-4d24-944e-ec173d4ea037",
      },
    ],
    layoutheading: "PROJECT LAYOUT",
    projectlayoutimage:
      "https://firebasestorage.googleapis.com/v0/b/anvikainfra.appspot.com/o/anvika_images%2F14.jpg?alt=media&token=aa520eb8-f1bd-4a98-ab1e-a64e750e0593",
    brochure2:
      "https://firebasestorage.googleapis.com/v0/b/anvikainfra.appspot.com/o/anvika_images%2Fprojects%2Fkompally%2FSUNDER%20ECOPOLIS%20APARTMENT%20DEVELOPMENT.pdf?alt=media&token=e44df4b1-bdf7-4818-a533-94d8961db22a",
    downloadlayoutimage:
      "https://firebasestorage.googleapis.com/v0/b/anvikainfra.appspot.com/o/anvika_images%2F14.jpg?alt=media&token=aa520eb8-f1bd-4a98-ab1e-a64e750e0593",
    projectlocheading: "PROJECT LOCATION",
    projectlochighlights: "LOCATION HIGHLIGHTS",

    projectLoc:
      "https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d1768.242207641472!2d78.4625014!3d17.557585!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x4dcf0855772989ea!2zMTfCsDMzJzI3LjAiTiA3OMKwMjcnNDcuNSJF!5e1!3m2!1sen!2sin!4v1658991831334!5m2!1sen!2sin",

    location_highlights: [
      "0.5 kms from DRS International School",
      "1.5 kms from St.Martin's Engineering College",
      "2.5 kms from Kompally",
      "3 Kms from surekha Hospital",
      "3 kms from Malla Reddy Engineering College",
      "Nearer to 100 Acre Oxygen park",
      "Hospitals, schools, entertainment hubs all lie within 1 to 5 kms of distance",
    ],
    gallery: [
      "https://firebasestorage.googleapis.com/v0/b/anvikainfra.appspot.com/o/anvika_images%2Fprojects%2Fkompally%2F1.JPG?alt=media&token=b7c394df-0d4e-46f8-a51b-8373ff1aa97a",
      "https://firebasestorage.googleapis.com/v0/b/anvikainfra.appspot.com/o/anvika_images%2Fprojects%2Fkompally%2F2.JPG?alt=media&token=3dba1974-9e42-481c-83da-708b6aff3b70",
      "https://firebasestorage.googleapis.com/v0/b/anvikainfra.appspot.com/o/anvika_images%2Fprojects%2Fkompally%2F3.JPG?alt=media&token=e72dabff-8b21-4bc6-827e-8a9a3f01da65",
      "https://firebasestorage.googleapis.com/v0/b/anvikainfra.appspot.com/o/anvika_images%2Fprojects%2Fkompally%2F4.JPG?alt=media&token=145ca32a-08b4-4713-b26a-af4242fee026",
      "https://firebasestorage.googleapis.com/v0/b/anvikainfra.appspot.com/o/anvika_images%2Fprojects%2Fkompally%2F5.JPG?alt=media&token=fab0d970-2932-4ef2-bc68-d73c81d8ab4b",
      "https://firebasestorage.googleapis.com/v0/b/anvikainfra.appspot.com/o/anvika_images%2Fprojects%2Fkompally%2F6.JPG?alt=media&token=b3354ca1-8ca7-4d76-8558-90dc7298de3f",
    ],
    galleryVideo: [],
    videoThumnail: "",
  },
  {
    heading: "PREMIUM GATED COMMUNITY FLATS @ KOKAPET",
    subheading: "Experience the allure of a secure and prestigious lifestyle",
    description1:
      "Green Space Celestial is a Premium gated community Flats project located at Kokapet. This project is in 8.5 acres, having 9 towers, g+9 floors, total 769 units. 2bhk and 3 BHK flats available, 2bhk size is 1080 - 2600 sft, 3bhk sizes are 1310- 2700 sft with east and west facings. We are specially included in Club House G+5 Floors and 34000 sft Club house. This society will have all basic facilities and amenities to suit homebuyer’s needs and requirements. Brought to you by Greenspace Housing and Engineers, Greenspace Celestial is scheduled for possession in Oct, 2024.",

    disclaimer:
      "Disclaimer: I authorize Anvika Infratech Ltd and its representatives to Call, SMS, Email or WhatsApp me about its ventures, investment opportunities and offers. This consent overrides any registration for DNC / NDNC.",
    youtube: "https://www.youtube.com/embed/VbPa0_PJYMI",

    brochure1:
      "https://firebasestorage.googleapis.com/v0/b/anvikainfra.appspot.com/o/anvika_images%2Fprojects%2Ftellapur%2FCelestial%20brochure.pdf?alt=media&token=fbc27caf-3718-4d9a-a2bd-8de42a5aac78",
    projectheading: "PROJECT HIGHLIGHTS",

    project_highlights: [
      {
        text: "Surviellence System",
        image:
          "https://firebasestorage.googleapis.com/v0/b/anvikainfra.appspot.com/o/anvika_images%2F21.png?alt=media&token=382e4562-66f1-40e8-a4b5-aab2db6dff7d",
      },
      {
        text: "24/7 security",
        image:
          "https://firebasestorage.googleapis.com/v0/b/anvikainfra.appspot.com/o/anvika_images%2Fprojects%2Fproject_highlights%2Fguest_rooms.png?alt=media&token=f708d1b6-3f32-4f4e-b5b0-87d684e75f8c",
      },
      {
        text: "Firefighting System",
        image:
          "https://firebasestorage.googleapis.com/v0/b/anvikainfra.appspot.com/o/anvika_images%2F23.png?alt=media&token=ff9a1714-5a7a-4d3c-ae11-eae45f4f5c4a",
      },
      {
        text: "One Roof Swimming Pool",
        image:
          "https://firebasestorage.googleapis.com/v0/b/anvikainfra.appspot.com/o/anvika_images%2F22.png?alt=media&token=cda73a2e-ca8f-4e21-adbe-5806706f394a",
      },

      {
        text: "Children Play Area",
        image:
          "https://firebasestorage.googleapis.com/v0/b/anvikainfra.appspot.com/o/anvika_images%2F20.png?alt=media&token=b7580ffc-e21d-4599-8632-d6528cfca882",
      },

      {
        text: "Landscape Garden",
        image:
          "https://firebasestorage.googleapis.com/v0/b/anvikainfra.appspot.com/o/anvika_images%2Fprojects%2Fproject_highlights%2Flandscape_gardens.png?alt=media&token=0d99132f-0a03-4b88-9eb6-94ee3a97feb0",
      },
      {
        text: "AC Community Hall",
        image:
          "https://firebasestorage.googleapis.com/v0/b/anvikainfra.appspot.com/o/anvika_images%2F24.png?alt=media&token=b44b3362-2b7d-4d4c-92a9-c7bf033c5c12",
      },
      {
        text: "Fitness Center",
        image:
          "https://firebasestorage.googleapis.com/v0/b/anvikainfra.appspot.com/o/anvika_images%2F25.png?alt=media&token=a36598b5-1404-4102-b257-d3ce763d828b",
      },
    ],
    layoutheading: "PROJECT LAYOUT",
    projectlayoutimage:
      "https://firebasestorage.googleapis.com/v0/b/anvikainfra.appspot.com/o/anvika_images%2Fprojects%2Ftellapur%2Fkokapet-layout.png?alt=media&token=e553edba-609e-413d-b808-1b32a884df94",
    brochure2:
      "https://firebasestorage.googleapis.com/v0/b/anvikainfra.appspot.com/o/anvika_images%2Fprojects%2Ftellapur%2FCelestial%20brochure.pdf?alt=media&token=fbc27caf-3718-4d9a-a2bd-8de42a5aac78",
    downloadlayoutimage:
      "https://firebasestorage.googleapis.com/v0/b/anvikainfra.appspot.com/o/anvika_images%2Fprojects%2Ftellapur%2Fkokapet-layout.png?alt=media&token=e553edba-609e-413d-b808-1b32a884df94",
    projectlocheading: "PROJECT LOCATION",
    projectlochighlights: "LOCATION HIGHLIGHTS",

    projectLoc:
      "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d237.96326631106152!2d78.33864127085484!3d17.391992187913445!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bcb95bfcba1b3d1%3A0xdc5458e58bff3e1b!2sGreenspace%20Celestial!5e0!3m2!1sen!2sin!4v1688979801279!5m2!1sen!2sin",

    location_highlights: [
      "10 mins Nehru Outer Ring Road",
      "Ocean Park 2km",
      "10 mins drive to GAR Infobahn - Inspired by Laxmi",
      "Rockwell International School is a popular landmark in Kokapet",
      "It has 6 educational institutes in the vicinity. Around 6 medical care centres are available in proximity to this location.",
      "Nehru Outer Ring Road passes through the locality & further connects it to Narsingi & Shamshabad",
      "Located next to Financial District, Kokapet is 7-10 km from popular IT hubs of Gachibowli & HITEC City",
      "Sound road connectivity via Kokapet Main Road, Pipe Line Road, Shankarpalli-Hyderabad Road and Shanthinagar Road",
      "Premium residential supply by reputed builders such as Prestige Group, Ramky Estates & Farms Ltd & Vertex Homes",
      "Phoenix Greens International, Global Edge & Rockwell International schools are located in Kokapet",
      "Mahatma Gandhi Institute of Technology, Sadhana Junior College, and Vasavi College of Engineering are in proximity",
    ],
    gallery: [
      "https://firebasestorage.googleapis.com/v0/b/anvikainfra.appspot.com/o/anvika_images%2Fprojects%2Ftellapur%2Fkokapet-1.jpg?alt=media&token=aed9d81d-301e-475f-83fb-97553e801185",
      "https://firebasestorage.googleapis.com/v0/b/anvikainfra.appspot.com/o/anvika_images%2Fprojects%2Ftellapur%2Fkokapet-2.jpg?alt=media&token=0dd0182c-e58a-47ad-853e-a00495e4d04c",
      "https://firebasestorage.googleapis.com/v0/b/anvikainfra.appspot.com/o/anvika_images%2Fprojects%2Ftellapur%2Fkokapet-3.jpg?alt=media&token=59792bb9-cfb7-48fc-bb3a-f718e02ac5aa",
      "https://firebasestorage.googleapis.com/v0/b/anvikainfra.appspot.com/o/anvika_images%2Fprojects%2Ftellapur%2Fkokapet-4.jpg?alt=media&token=9b504b91-7b29-48c6-a8cf-4bf1728a4b3e",
      "https://firebasestorage.googleapis.com/v0/b/anvikainfra.appspot.com/o/anvika_images%2Fprojects%2Ftellapur%2Fkokapet-5.jpg?alt=media&token=7614ba91-2628-418d-a708-74a4efdb2fcf",
      "https://firebasestorage.googleapis.com/v0/b/anvikainfra.appspot.com/o/anvika_images%2Fprojects%2Ftellapur%2Fkokapet-6.jpg?alt=media&token=abcf5c92-12ce-4e61-b9b0-acf4c770baca",
    ],
    galleryVideo: [],
    videoThumnail: "",
  },
  {
    heading: "APARTMENTS @ Gopanpally",
    subheading: "WELL-DEVELOPED YET SUSTAINABLE – LEAD A UNIQUE LIFESTYLE",
    description1:
      "Check out SNR The Elite in Gopanpally, one of the upcoming under-construction housing societies in Hyderabad. There are apartments for sale in SNR The Elite. This society will have all basic facilities and amenities to suit homebuyer’s needs and requirements. Brought to you by SNR Avenues, SNR The Elite is scheduled for possession in Jul, 2027.",
    description2:
      "Being a RERA-registered society, the project details and other important information is also available on state RERA portal. The RERA registration number of this project is P02400003235 . Here’s everything you need to know about the must-know features of this housing society along with SNR The Elite Photos, Floor Plans, and other exciting facts about your future home",

    description3:
      "This property offers all the amenities you need: a grand entrance gateway, a compound wall for the entire community, landscaped gardens and avenue plantations as well as BT roads measuring 40 feet in width. It also has well-developed drainage and water systems.",

    disclaimer:
      "Disclaimer: I authorize Anvika Infratech Ltd and its representatives to Call, SMS, Email or WhatsApp me about its ventures, investment opportunities and offers. This consent overrides any registration for DNC / NDNC.",
    youtube: "https://www.youtube.com/embed/780pJprihW8",

    brochure1:
      "https://firebasestorage.googleapis.com/v0/b/anvikainfra.appspot.com/o/anvika_images%2Fprojects%2Fgopanpally%2Fgopanpally.pdf?alt=media&token=7197950c-3ab9-4a51-ba3b-8aafed9e6470",
    projectheading: "PROJECT HIGHLIGHTS",

    project_highlights: [
      {
        text: "Swimming Pool",
        image:
          "https://firebasestorage.googleapis.com/v0/b/anvikainfra.appspot.com/o/anvika_images%2Fprojects%2Fproject_highlights%2Flandscape_gardens.png?alt=media&token=0d99132f-0a03-4b88-9eb6-94ee3a97feb0",
      },
      {
        text: "Landscape Garden, Gym, Jogging Trackm, Play Area, Grand Entrance, Multipurpose Hall, Children Play Area",
        image:
          "https://firebasestorage.googleapis.com/v0/b/anvikainfra.appspot.com/o/anvika_images%2Fprojects%2Fproject_highlights%2Fgrand_entrance_gate.png?alt=media&token=8b2fac81-ab0e-4271-aa91-23c763338964",
      },

      {
        text: "Multipurpose Hall, Yoga/Aerobic Hall",
        image:
          "https://firebasestorage.googleapis.com/v0/b/anvikainfra.appspot.com/o/anvika_images%2Fprojects%2Fproject_highlights%2Fbanquet_hall.png?alt=media&token=bcae198a-94ab-432f-80d3-bae81d571425",
      },

      {
        text: "Badminton Court, Tennis court, Tennis court, Basket Ball court, Restaurant",
        image:
          "https://firebasestorage.googleapis.com/v0/b/anvikainfra.appspot.com/o/anvika_images%2Fprojects%2Fproject_highlights%2Foutdoor_games.png?alt=media&token=217d25c9-c610-4a20-b2f4-bb87a74139f7",
      },
      {
        text: "CCTV Surviellence, 24/7 Security",
        image:
          "https://firebasestorage.googleapis.com/v0/b/anvikainfra.appspot.com/o/anvika_images%2Fprojects%2Fproject_highlights%2Frestaurant.png?alt=media&token=b6dcc951-38fe-4d24-944e-ec173d4ea037",
      },

      {
        text: "Guest Room Accommodation, Landscape Garden, Mini Mart, Coffee shop",
        image:
          "https://firebasestorage.googleapis.com/v0/b/anvikainfra.appspot.com/o/anvika_images%2Fprojects%2Fproject_highlights%2Fguest_rooms.png?alt=media&token=f708d1b6-3f32-4f4e-b5b0-87d684e75f8c",
      },

      {
        text: "100% Vasthu Complaint.",
        image:
          "https://firebasestorage.googleapis.com/v0/b/anvikainfra.appspot.com/o/anvika_images%2Fprojects%2Fproject_highlights%2Fhmda_approved_layout.png?alt=media&token=2d4623e8-2d6c-47e6-9084-34872a90061b",
      },
    ],
    layoutheading: "PROJECT LAYOUT",
    projectlayoutimage:
      "https://firebasestorage.googleapis.com/v0/b/anvikainfra.appspot.com/o/anvika_images%2Fprojects%2Fgopanpally%2Fgopanpally%20layout.jpg?alt=media&token=4438c405-4814-4fac-8e35-3b491d97839f",
    brochure2:
      "https://firebasestorage.googleapis.com/v0/b/anvikainfra.appspot.com/o/anvika_images%2Fprojects%2Fgopanpally%2Fgopanpally.pdf?alt=media&token=7197950c-3ab9-4a51-ba3b-8aafed9e6470",
    downloadlayoutimage:
      "https://firebasestorage.googleapis.com/v0/b/anvikainfra.appspot.com/o/anvika_images%2Fprojects%2Fgopanpally%2Fgopanpally%20layout.jpg?alt=media&token=4438c405-4814-4fac-8e35-3b491d97839f",
    projectlocheading: "PROJECT LOCATION",
    projectlochighlights: "LOCATION HIGHLIGHTS",

    projectLoc:
      "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3806.4375205147808!2d78.29826!3d17.438761000000003!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0!2zMTfCsDI2JzE5LjUiTiA3OMKwMTcnNTMuNyJF!5e0!3m2!1sen!2sin!4v1658991935780!5m2!1sen!2sin",

    location_highlights: [
      "2 mins Drive from Vista School",
      "Nehru Outer Ring Road Hyderabad ~ 3 Kms",
      "NH 65 ~ 8.5 Kms.",
      "3 km from hospitals such as Citizens Speciality Hospital, Pranam Wellness Centre and American Oncology Institute Presence of Special Economic Zone, offering ample employment avenues.",
      "About 7 km from Lingampalli Railway Station located on the South Central Railway Zone.",
      "Famous among tenants due to its proximity to employment hub in Special Economic Zone.",
      "Nallagandla Road and Tellapur Road are a few prominent roads passing through this locality.",
      "Frequent buses available from Wipro Bus Stop located about 5 km away to connect various parts of the city",
      "The nearest metro station is 11 km away at Miyapur located on the LB Nagar-Miyapur Red Line",
      "South Campus Shopping Complex, The Chennai Shopping Mall and GSM Mall are within 10 km radiu",
      "About 33 km from Rajiv Gandhi International Airport via Nehru Outer Ring Road",
    ],
    gallery: [
      "https://firebasestorage.googleapis.com/v0/b/anvikainfra.appspot.com/o/anvika_images%2Fprojects%2Fgopanpally%2F1.png?alt=media&token=61f27913-cf62-4656-a9d6-0763a12b70ac",
      "https://firebasestorage.googleapis.com/v0/b/anvikainfra.appspot.com/o/anvika_images%2Fprojects%2Fgopanpally%2F2.jpg?alt=media&token=cbd5bcc2-7b78-483f-92a9-0c4f55eb3670",
      "https://firebasestorage.googleapis.com/v0/b/anvikainfra.appspot.com/o/anvika_images%2Fprojects%2Fgopanpally%2F3.jpg?alt=media&token=81a5e14a-d5fe-4dd4-bb1f-ac1804fc32c4",
    ],
    galleryVideo: [],
    videoThumnail: "",
  },
  {
    heading: "APARTMENTS @ Patancheru",
    subheading: "WELL-DEVELOPED YET SUSTAINABLE - LEAD A UNIQUE LIFESTYLE",
    description1:
      "Anvika Infraa Developers by Lucid at Patancheru, Hyderabad North Hyderabad. It offers 2 BHK residential apartments area ranging 1150-1750 sq.ft. The project offers world class residential units with top line specifications. The project enables easy connectivity to all points of interest in and around city.",
    description2:
      "Anvika Infraa Developers apartments are located at an excellent locality of Hyderabad North Hyderabad. which is much far from the hasty tones and fuzziness of the city. With bizarre hi-tech specifications of quality construction and architectural designs, you can be sure of loving the place where you would see your bright future. The facilities provided are top of the line matching your desire for a perfect home as Black Top Roads of 60 ft and 40 ft wide and Many Gated Communities Nearby which invite you to discover an exquisitely sublime world of refined indulgences for the rare few who have carved their own niche in the upper echelons of society.",

    disclaimer:
      "Disclaimer: I authorize Anvika Infratech Ltd and its representatives to Call, SMS, Email or WhatsApp me about its ventures, investment opportunities and offers. This consent overrides any registration for DNC / NDNC.",
    // youtube: "https://www.youtube.com/embed/l7oWmr-VyxU",
    youtube: "https://www.youtube.com/embed/NSdbfSYNncE",

    brochure1:
      "https://firebasestorage.googleapis.com/v0/b/anvikainfra.appspot.com/o/anvika_images%2Fprojects%2FPatancheruvu%2FBroucher.pdf?alt=media&token=1e97bc5e-a0af-407f-92fe-65363617d5d9",
    // "https://firebasestorage.googleapis.com/v0/b/anvikainfra.appspot.com/o/anvika_images%2Fprojects%2FPatancheruvu%2Fbroucher.pdf?alt=media&token=913c1a7a-9bf0-43b9-91f2-52af3393261b",
    projectheading: "PROJECT HIGHLIGHTS",

    project_highlights: [
      {
        text: "Swimming Pool",
        image:
          "https://firebasestorage.googleapis.com/v0/b/anvikainfra.appspot.com/o/anvika_images%2Fprojects%2Fproject_highlights%2Flandscape_gardens.png?alt=media&token=0d99132f-0a03-4b88-9eb6-94ee3a97feb0",
      },
      {
        text: "Landscape Garden, Gym, Jogging Trackm, Play Area, Grand Entrance, Multipurpose Hall, Children Play Area",
        image:
          "https://firebasestorage.googleapis.com/v0/b/anvikainfra.appspot.com/o/anvika_images%2Fprojects%2Fproject_highlights%2Fgrand_entrance_gate.png?alt=media&token=8b2fac81-ab0e-4271-aa91-23c763338964",
      },

      {
        text: "Multipurpose Hall, Yoga/Aerobic Hall",
        image:
          "https://firebasestorage.googleapis.com/v0/b/anvikainfra.appspot.com/o/anvika_images%2Fprojects%2Fproject_highlights%2Fbanquet_hall.png?alt=media&token=bcae198a-94ab-432f-80d3-bae81d571425",
      },

      {
        text: "Badminton Court, Tennis court, Tennis court, Basket Ball court, Restaurant",
        image:
          "https://firebasestorage.googleapis.com/v0/b/anvikainfra.appspot.com/o/anvika_images%2Fprojects%2Fproject_highlights%2Foutdoor_games.png?alt=media&token=217d25c9-c610-4a20-b2f4-bb87a74139f7",
      },
      {
        text: "CCTV Surviellence, 24/7 Security",
        image:
          "https://firebasestorage.googleapis.com/v0/b/anvikainfra.appspot.com/o/anvika_images%2Fprojects%2Fproject_highlights%2Frestaurant.png?alt=media&token=b6dcc951-38fe-4d24-944e-ec173d4ea037",
      },

      {
        text: "Guest Room Accommodation, Landscape Garden, Mini Mart, Coffee shop",
        image:
          "https://firebasestorage.googleapis.com/v0/b/anvikainfra.appspot.com/o/anvika_images%2Fprojects%2Fproject_highlights%2Fguest_rooms.png?alt=media&token=f708d1b6-3f32-4f4e-b5b0-87d684e75f8c",
      },

      {
        text: "100% Vasthu Complaint.",
        image:
          "https://firebasestorage.googleapis.com/v0/b/anvikainfra.appspot.com/o/anvika_images%2Fprojects%2Fproject_highlights%2Fhmda_approved_layout.png?alt=media&token=2d4623e8-2d6c-47e6-9084-34872a90061b",
      },
    ],
    layoutheading: "PROJECT LAYOUT",
    projectlayoutimage:
      "https://firebasestorage.googleapis.com/v0/b/anvikainfra.appspot.com/o/anvika_images%2Fprojects%2FPatancheruvu%2Flayout.png?alt=media&token=79e77cef-3276-4bd2-9a50-5be4bc1bcd11",
    brochure2:
      "https://firebasestorage.googleapis.com/v0/b/anvikainfra.appspot.com/o/anvika_images%2Fprojects%2FPatancheruvu%2Fbroucher.pdf?alt=media&token=913c1a7a-9bf0-43b9-91f2-52af3393261b",

    downloadlayoutimage:
      "https://firebasestorage.googleapis.com/v0/b/anvikainfra.appspot.com/o/anvika_images%2Fprojects%2FPatancheruvu%2Flayout.png?alt=media&token=79e77cef-3276-4bd2-9a50-5be4bc1bcd11",
    projectlocheading: "PROJECT LOCATION",
    projectlochighlights: "LOCATION HIGHLIGHTS",

    projectLoc:
      "https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d884.2332248332963!2d78.2576826!3d17.534606!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bcbf3b8bb7ad75d%3A0x381de8b58471d2d3!2sTEJA%20HOMES.PVT.LTD!5e1!3m2!1sen!2sin!4v1658991991193!5m2!1sen!2sin",

    location_highlights: [
      "1 min Drive from Candiidus International School",
      "5 min Drive from The Gaudium school and Samishiti International School.",
      "5 min Drive from Narayana IIT Academy.",
      "5 min Walk towards MaxCare Hospitals.",
      "500mts away from patancheru Bustop",
      "100mts away from Mumbai highway.",
      "500 mts away from Amazon warehouses.",
      "3kms Drive from ORR Exit no.3",
      "10 min drive from BHEL.",
      "15 min drive Miyapur Metro Station.",
      "20 min Drive from Gachibowli.",
      "25 min Drive from Hitech city.",
      "40 min Drive from Rajiv gandhi international airport.",
    ],
    gallery: [
      "https://firebasestorage.googleapis.com/v0/b/anvikainfra.appspot.com/o/anvika_images%2Fprojects%2FPatancheruvu%2Fpatancheru%201.jpg?alt=media&token=e211570d-8ae6-404c-b23b-29febf3b257c",
      "https://firebasestorage.googleapis.com/v0/b/anvikainfra.appspot.com/o/anvika_images%2Fprojects%2FPatancheruvu%2Fpatancheru%202.jpg?alt=media&token=62ee8afb-421a-4a65-8302-b867b200bd9a",
      "https://firebasestorage.googleapis.com/v0/b/anvikainfra.appspot.com/o/anvika_images%2Fprojects%2FPatancheruvu%2Fpatancheru%203.jpg?alt=media&token=6f1b99a0-4832-4628-90bb-519a32fb2482",
      // "https://firebasestorage.googleapis.com/v0/b/anvikainfra.appspot.com/o/anvika_images%2Fprojects%2FPatancheruvu%2Fpatancheru%204.jpg?alt=media&token=14afb120-0128-41c4-b2e1-a3a58153300d",
      // "https://firebasestorage.googleapis.com/v0/b/anvikainfra.appspot.com/o/anvika_images%2Fprojects%2FPatancheruvu%2Fpatancheru%205.jpg?alt=media&token=cf1413e0-4d24-4f7b-892c-11870b9cdb91",
      // "https://firebasestorage.googleapis.com/v0/b/anvikainfra.appspot.com/o/anvika_images%2Fprojects%2FPatancheruvu%2Fpatancheru%206.jpg?alt=media&token=9117ce08-c4fc-471c-9173-b4c5d825f7cb",
      "https://firebasestorage.googleapis.com/v0/b/anvikainfra.appspot.com/o/anvika_images%2Fprojects%2FPatancheruvu%2FA1.JPG?alt=media&token=9acb996c-e4de-409c-b36f-1e957e3eb1e7",
      "https://firebasestorage.googleapis.com/v0/b/anvikainfra.appspot.com/o/anvika_images%2Fprojects%2FPatancheruvu%2FA2.JPG?alt=media&token=ba2a34f3-8527-49f3-bf3a-cf14c2c9635c",
      "https://firebasestorage.googleapis.com/v0/b/anvikainfra.appspot.com/o/anvika_images%2Fprojects%2FPatancheruvu%2FA4.JPG?alt=media&token=cbcb5643-9597-45d8-bf8e-77e186119806",
      "https://firebasestorage.googleapis.com/v0/b/anvikainfra.appspot.com/o/anvika_images%2Fprojects%2FPatancheruvu%2FA5.JPG?alt=media&token=82c56a8f-5aef-4d3f-ab10-fe8235d48eab",
      "https://firebasestorage.googleapis.com/v0/b/anvikainfra.appspot.com/o/anvika_images%2Fprojects%2FPatancheruvu%2FA6.JPG?alt=media&token=ec398140-8d55-4961-90a3-e0a0c2620e0b",
      "https://firebasestorage.googleapis.com/v0/b/anvikainfra.appspot.com/o/anvika_images%2Fprojects%2FPatancheruvu%2FA7.JPG?alt=media&token=7e5f7957-8690-4d14-b174-a5a36bb2a0d9",
      "https://firebasestorage.googleapis.com/v0/b/anvikainfra.appspot.com/o/anvika_images%2Fprojects%2FPatancheruvu%2FA8.JPG?alt=media&token=758d35fa-f1e6-413c-b5d7-72a89b4200a9",
      "https://firebasestorage.googleapis.com/v0/b/anvikainfra.appspot.com/o/anvika_images%2Fprojects%2FPatancheruvu%2FA11.JPG?alt=media&token=caa4ecba-84cc-40ee-940b-06a7501ca2a3",
      "https://firebasestorage.googleapis.com/v0/b/anvikainfra.appspot.com/o/anvika_images%2Fprojects%2FPatancheruvu%2FA12.JPG?alt=media&token=2ec66303-d2fd-4f52-99c6-8b76d0bd4295",
      "https://firebasestorage.googleapis.com/v0/b/anvikainfra.appspot.com/o/anvika_images%2Fprojects%2FPatancheruvu%2FA13.JPG?alt=media&token=e4000543-3fc0-4074-b6bb-18b06965163a",
      "https://firebasestorage.googleapis.com/v0/b/anvikainfra.appspot.com/o/anvika_images%2Fprojects%2FPatancheruvu%2FA14.JPG?alt=media&token=3f7bc799-3926-4233-bc6c-d327a42fbe73",
      "https://firebasestorage.googleapis.com/v0/b/anvikainfra.appspot.com/o/anvika_images%2Fprojects%2FPatancheruvu%2FA15.JPG?alt=media&token=f856155a-a086-4113-95e2-2c901147bb4e",
      "https://firebasestorage.googleapis.com/v0/b/anvikainfra.appspot.com/o/anvika_images%2Fprojects%2FPatancheruvu%2FA16.JPG?alt=media&token=691f360b-7007-4278-bbc5-ddb889d5fd6f",
      "https://firebasestorage.googleapis.com/v0/b/anvikainfra.appspot.com/o/anvika_images%2Fprojects%2FPatancheruvu%2FA3.JPG?alt=media&token=94a6d0dd-7426-4b38-99b1-f2efc521d817",
      "https://firebasestorage.googleapis.com/v0/b/anvikainfra.appspot.com/o/anvika_images%2Fprojects%2FPatancheruvu%2FA10.JPG?alt=media&token=55ecca63-d92f-4a26-a778-c1dd3115308c",
    ],
    galleryVideo: [
      "https://firebasestorage.googleapis.com/v0/b/anvikainfra.appspot.com/o/anvika_images%2Fprojects%2FPatancheruvu%2FV1.MP4?alt=media&token=ea8d5ef5-2d25-445a-b206-219dc5493472",
      "https://firebasestorage.googleapis.com/v0/b/anvikainfra.appspot.com/o/anvika_images%2Fprojects%2FPatancheruvu%2FV2.MP4?alt=media&token=01d4150d-7b35-4ea8-aa49-343cbab97957",
      "https://firebasestorage.googleapis.com/v0/b/anvikainfra.appspot.com/o/anvika_images%2Fprojects%2FPatancheruvu%2FV3.MP4?alt=media&token=92a0af29-c02e-4f86-887d-27f2f8e3d90c",
    ],
    videoThumnail:
      "https://firebasestorage.googleapis.com/v0/b/anvikainfra.appspot.com/o/anvika_images%2Fprojects%2FPatancheruvu%2FPatancheruvu.jpg?alt=media&token=e1d17786-85ba-4ea8-a740-b899b5dab829",
  },
  {
    heading: "LUXURIOUS GATED COMMUNITY FLATS @ MANIKONDA",
    subheading: "WELL-DEVELOPED YET SUSTAINABLE – LEAD A UNIQUE LIFESTYLE",
    description1:
      "Green Space Marvel is a high rise residential gated community apartment project located at Manikonda near Puppalaguda.",
    description2:
      "This project is in 4.45 acres, having 2 towers, g+39 floors, total 792 units. 2bhk and 3 BHK flats available, 2bhk size is 1465 sft, 3bhk sizes are 1885 sft, 2685 sft with east and west facings. Floor to floor height is 3 meters, all side 18 M set backs. We can see 2 clubhouses in this project with indoor amenities. Minutes drive to Sloka the school, Avasa hospital, Delhi public school, Raidurg police station, Kokapet, Narsingi, Financial District, Gachibowli and Kondapur.",

    disclaimer:
      "Disclaimer: I authorize Anvika Infratech Ltd and its representatives to Call, SMS, Email or WhatsApp me about its ventures, investment opportunities and offers. This consent overrides any registration for DNC / NDNC.",
    youtube: "https://www.youtube.com/embed/jU7mPAFxJoE",

    brochure1:
      "https://firebasestorage.googleapis.com/v0/b/anvikainfra.appspot.com/o/anvika_images%2Fprojects%2Fgreenspace%2FGreenspace%20MARVEL-%20Puppalaguda%20PDF.pdf?alt=media&token=ae28a06e-afec-4d4b-a8ee-a3d82085978e",
    projectheading: "PROJECT HIGHLIGHTS",

    project_highlights: [
      {
        text: "100% Vastu Compliance",
        image:
          "https://firebasestorage.googleapis.com/v0/b/anvikainfra.appspot.com/o/anvika_images%2Fprojects%2Fproject_highlights%2Fgrand_entrance_gate.png?alt=media&token=8b2fac81-ab0e-4271-aa91-23c763338964",
      },
      {
        text: "60, 40 & 33 ft BT Roads",
        image:
          "https://firebasestorage.googleapis.com/v0/b/anvikainfra.appspot.com/o/anvika_images%2Fprojects%2Fproject_highlights%2Fbt_top_roads.png?alt=media&token=624abdd0-1673-46ff-b5b8-f7d111ccc651",
      },
      {
        text: "Underground Drainage",
        image:
          "https://firebasestorage.googleapis.com/v0/b/anvikainfra.appspot.com/o/anvika_images%2Fprojects%2Fproject_highlights%2Fwater_pipeline.png?alt=media&token=7a02c66d-b9ae-4a53-9fee-1f9bf5b8ae96",
      },
      {
        text: "Entrance arch with 24/7 security",
        image:
          "https://firebasestorage.googleapis.com/v0/b/anvikainfra.appspot.com/o/anvika_images%2Fprojects%2Fproject_highlights%2Fguest_rooms.png?alt=media&token=f708d1b6-3f32-4f4e-b5b0-87d684e75f8c",
      },
      {
        text: "Electrical Lines with designer lights",
        image:
          "https://firebasestorage.googleapis.com/v0/b/anvikainfra.appspot.com/o/anvika_images%2Fprojects%2Fproject_highlights%2Fstreet_lights.png?alt=media&token=7cc6c780-1f86-4546-9c60-fed6d93c9c34",
      },
      {
        text: "Well designed land scaped parks",
        image:
          "https://firebasestorage.googleapis.com/v0/b/anvikainfra.appspot.com/o/anvika_images%2Fprojects%2Fproject_highlights%2Favenue_plantation.png?alt=media&token=d0b1e03c-fec6-4360-9b50-36ef7d513cdb",
      },
      {
        text: "Clear Title",
        image:
          "https://firebasestorage.googleapis.com/v0/b/anvikainfra.appspot.com/o/anvika_images%2Fprojects%2Fproject_highlights%2Fclub_house.png?alt=media&token=d8fa68cd-8a13-4f5c-ae3f-1e62bd4de7ef",
      },
      {
        text: "Avenue Plantation",
        image:
          "https://firebasestorage.googleapis.com/v0/b/anvikainfra.appspot.com/o/anvika_images%2Fprojects%2Fproject_highlights%2Flandscape_gardens.png?alt=media&token=0d99132f-0a03-4b88-9eb6-94ee3a97feb0",
      },
      {
        text: "Children Play Area",
        image:
          "https://firebasestorage.googleapis.com/v0/b/anvikainfra.appspot.com/o/anvika_images%2Fprojects%2Fproject_highlights%2Foutdoor_games.png?alt=media&token=217d25c9-c610-4a20-b2f4-bb87a74139f7",
      },
      {
        text: "Rain Water Harvesting",
        image:
          "https://firebasestorage.googleapis.com/v0/b/anvikainfra.appspot.com/o/anvika_images%2Fprojects%2Fproject_highlights%2Fsenior_citizen_plaza.png?alt=media&token=e6b080c2-6e6f-4b20-a6e4-df6e2a5e34aa",
      },
      {
        text: "Overhead tank & 24/7 water facility to each plot",
        image:
          "https://firebasestorage.googleapis.com/v0/b/anvikainfra.appspot.com/o/anvika_images%2Fprojects%2Fproject_highlights%2Fwater_pipeline.png?alt=media&token=7a02c66d-b9ae-4a53-9fee-1f9bf5b8ae96",
      },
    ],
    layoutheading: "PROJECT LAYOUT",
    projectlayoutimage:
      "https://firebasestorage.googleapis.com/v0/b/anvikainfra.appspot.com/o/anvika_images%2Fprojects%2Fgreenspace%2Fgreenspace-23.png?alt=media&token=39283e7d-499e-4045-9c2b-c6e7524b5c1c",
    brochure2:
      "https://firebasestorage.googleapis.com/v0/b/anvikainfra.appspot.com/o/anvika_images%2Fprojects%2Fgreenspace%2FGreenspace%20MARVEL-%20Puppalaguda%20PDF.pdf?alt=media&token=ae28a06e-afec-4d4b-a8ee-a3d82085978e",

    downloadlayoutimage:
      "https://firebasestorage.googleapis.com/v0/b/anvikainfra.appspot.com/o/anvika_images%2Fprojects%2Fgreenspace%2Fgreenspace-23.png?alt=media&token=39283e7d-499e-4045-9c2b-c6e7524b5c1c",
    projectlocheading: "PROJECT LOCATION",
    projectlochighlights: "LOCATION HIGHLIGHTS",

    projectLoc:
      "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3807.211579490758!2d78.36443!3d17.401631!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0!2zMTfCsDI0JzA1LjkiTiA3OMKwMjEnNTIuMCJF!5e0!3m2!1sen!2sin!4v1669013504362!5m2!1sen!2sin",

    location_highlights: [
      "5 mins Drive to ORR, 2. 5 mins Drive to Wave Rock",
      "10 mins Drive to Gachibowli, 20 mins Drive to Hitech City",
      "25 mins Drive to Airport, Gitanjali Vedika School Narsingi, Phoenix Greens International School",
      "Esperanza Preschool, Kokapet Global Edge School & Euro Kids",
      "Rockwell International School, Heritage SuperMarket",
      "Ratnadeep Super Market, Ocean Park",
      "CBIT Engineering College, MGIT Engineering College",
      "Near by Many MNCs like Wipro, Amazon, Microsoft, Dell, Google, DLF, Infosys, TCS & COGNIZANT",
      " Continental Hospital",
    ],
    gallery: [
      "https://firebasestorage.googleapis.com/v0/b/anvikainfra.appspot.com/o/anvika_images%2Fprojects%2Fgreenspace%2Fgreenspace-24.png?alt=media&token=d4cb68ce-4d70-449e-9ae4-11bd679a14c1",
      "https://firebasestorage.googleapis.com/v0/b/anvikainfra.appspot.com/o/anvika_images%2Fprojects%2Fgreenspace%2Fgreenspace-25.png?alt=media&token=ff20aad3-673c-4993-abec-0cd58c30a091",
      "https://firebasestorage.googleapis.com/v0/b/anvikainfra.appspot.com/o/anvika_images%2Fprojects%2Fgreenspace%2Fgreenspace-26.png?alt=media&token=1b67df3f-a378-4488-823f-a8c6d4699e49",
      "https://firebasestorage.googleapis.com/v0/b/anvikainfra.appspot.com/o/anvika_images%2Fprojects%2Fgreenspace%2Fgreenspace-27.png?alt=media&token=70dc6abb-578a-4100-a857-24e1683e382e",
      "https://firebasestorage.googleapis.com/v0/b/anvikainfra.appspot.com/o/anvika_images%2Fprojects%2Fgreenspace%2Fgreenspace-28.png?alt=media&token=ad927402-b205-4c68-9eaf-25cde05e7ff5",
      "https://firebasestorage.googleapis.com/v0/b/anvikainfra.appspot.com/o/anvika_images%2Fprojects%2Fgreenspace%2Fgreenspace-29.png?alt=media&token=6d3451e8-7061-4e54-b475-adaf0eab4861",
    ],
    galleryVideo: [],
    videoThumnail: "",
  },
];

const contact_map_anvi = {
  disclaimer:
    "Disclaimer: I authorize Anvika Infratech Ltd and its representatives to Call, SMS, Email or WhatsApp me about its ventures, investment opportunities and offers. This consent overrides any registration for DNC / NDNC.",
  loc: "https://maps.google.com/maps?q=8J45%2BV6&t=&z=16&ie=UTF8&iwloc=&output=embed",
};
const about_page_config_anvi = {
  about_img:
    "https://firebasestorage.googleapis.com/v0/b/anvikainfra.appspot.com/o/anvika_images%2F8.jpg?alt=media&token=2a0dd905-6c9b-4312-bfa1-d42c79b4c474",
  vision_img:
    "https://firebasestorage.googleapis.com/v0/b/anvikainfra.appspot.com/o/anvika_images%2F10.jpg?alt=media&token=2bae7ca9-79eb-47d5-b289-763c84657470",
  about_1:
    "Anvika Infraa Deelopers is one of the most presumed and all around perceived Land Developers in Telangana.Throughout the long term, we have been utilizing our best techniques to change over our clients' thoughts into superb foundation, expecting to turn into the Top Land Organization. Anvika Infraa Developers. centers around fostering the properties remembering what is best for our clients.",
  about_2:
    "Laid out in the year 2016, established by Mr. Dasu Garu, He is quite possibly the earliest and the senior most land trailblazers of Hyderabad and has gotten the esteemed Public Residents Grant from the Indian Focal Government.",
  about_3:
    "Since its origin, Anvika Infraa Developers. has effectively finished and given more than 20 undertakings by villas, apartments, open plots (a sum of 900 sections of land). Aside from that, one more 120 sections of land is a work in progress.",
  about_4:
    "Anvika Infraa Developers. continuously puts stock in creating projects that rethink way of life. Today, our organization can gladly say that they have around 6000+ happy customers.",
  vision:
    "Our vision is to be the main land specialist co-ops in telangana, and the favored work environment for experts. Anvika infraa developers reliably endeavors to foster cooperative organizations, in light of straight forwardness and common trust, which effectively assemble persevering through client connections. As we grow, we're focused on these standards, which have served our organization and clients as the years progressed.",
  mission:
    "Anvika infraa developers is committed to accomplishing our vision by making a fiery, positive, results-driven workplace that spotlights on the speculation and improvement of long haul connections. We measure our prosperity by the activities conveyed to clients. Our morals are based on our obligation to offering prevalent client support combined with an innovative style and custom tailored help of a quickly developing organization.",
  value:
    "We believe in creating promising investment opportunities for our customers by providing them with knowledge and accurate information about the projects. Here's what sets us apart:",
  value_list: [
    {
      image: img1,
      description: "6 years of Real Estate experience",
    },
    {
      image: img2,
      description: "Timely delivery of projects",
    },
    {
      image: img3,
      description: "Clear Title properties",
    },
    {
      image: img4,
      description: "Ventures in prime locations",
    },
    {
      image: img5,
      description: "Higher Return on Investment",
    },
    {
      image: img6,
      description: "Top-notch development",
    },
    {
      image: img7,
      description: "Well-established and trusted management",
    },
    {
      image: img8,
      description: "Hassle-free process",
    },
  ],
  value_title: "WHY CHOOSE ANVIKA INFRAA DEVELOPERS?",
};

const PHONE_NUMBER_ANVI = "81798 94526";
const EMAIL_ID_ANVI = "anvikainfradevelopers@gmail.com";
const ADDRESS_ANVI =
  "FLOT No. 202, Road No.1, Kavuri Hills, Siddhartha Building, Madhapur";
const CITY_ANVI = "Hyderabad";
const STATE_ANVI = "Telangana";
const ZIP_CODE_ANVI = "500081";
const COUNTRY_ANVI = "India";
const FAX_NUMBER_ANVI = "1234567890";
const WEB_URL_ANVI = "www.example.com";
const FACEBOOK_URL_ANVI = "https://www.facebook.com/AnvikaInfraDevelopers";
const TWITTER_URL_ANVI = "www.twitter.com";
const INSTAGRAM_URL_ANVI = "https://www.instagram.com/anvikainfradevelopers/";
const YOUTUBE_URL_ANVI =
  "https://www.youtube.com/channel/UCrH3h9qKeeksNSW4Dgz45Lw";
const LINKEDIN_URL_ANVI = "www.linkedin.com";
const GOOGLE_URL_ANVI = "www.google.com";
const PINTEREST_URL_ANVI = "www.pinterest.com";
const COMPANY_ANVI = "anvika";

/** Royal Estate Project */
/** Landing Page */
const web_title_royal = "Royal Rajarikam";

const carousel_config_royal = [
  {
    class: "bg-royal-1",
    title: "PLOT YOUR FUTURE WITH ROYAL RAJARIKAM",
  },
  {
    class: "bg-royal-2",
    title: "BEGIN YOUR JOURNEY WITH ROYAL RAJARIKAM",
  },
  {
    class: "bg-royal-3",
    title: "THE BEST INVESTMENT ON EARTH IS EARTH",
  },
  {
    class: "bg-royal-4",
    title: "A SMALL INVESTMENT TODAY IS A GREAT ASSET TOMORROW",
  },
];

const about_config_royal = {
  image:
    "https://firebasestorage.googleapis.com/v0/b/rrestate-2022.appspot.com/o/RoyalRajarikam%2Fhand.jpg?alt=media&token=cbb11a9a-e57e-47d0-9d3f-2a34e8735947",
  description:
    "Royal Rajarikam Developers is one of the most reputed and well-recognized Real Estate Developers in Telangana and Andhra Pradesh. Over the years, we have been employing our best methods to convert our clients’ ideas into magnificent infrastructure, aiming to become the Top Real Estate Company. Royal Rajarikam Developers. focuses on developing the properties keeping in mind what is best for our customers.",
};

const our_project_config_royal = [
  {
    id: 1,
    title: "Open plots @ shadnagar",
    image:
      "https://firebasestorage.googleapis.com/v0/b/royal-rajarikam.appspot.com/o/visionpark.png?alt=media&token=bc69e142-268e-4084-b8ae-0d10d4962c6d",
    description: "Vision Spark @ Kondurg",
    link: "/project?id=1",
  },
  {
    id: 2,
    title: "Independent houses @ shadnagar",
    image:
      "https://firebasestorage.googleapis.com/v0/b/royal-rajarikam.appspot.com/o/brundavanam.png?alt=media&token=897f621b-cef9-4db9-82af-d41639a2bacb",
    description: "Brundhavanam Colony @ Suryapet",
    link: "project?id=2",
  },
  {
    id: 3,
    title: "Open plots @ suryapet",
    image:
      "https://firebasestorage.googleapis.com/v0/b/royal-rajarikam.appspot.com/o/shivanandi.png?alt=media&token=08439e4a-40d3-469d-92e8-fadf0e6c9257",
    description: "Shiva Nandi Phase 2 @ Yacharam",
    link: "project?id=3",
  },
  {
    id: 4,
    title: "Open Plots @ Mumbai Highway",
    image:
      "https://firebasestorage.googleapis.com/v0/b/royal-rajarikam.appspot.com/o/shivanandi.png?alt=media&token=08439e4a-40d3-469d-92e8-fadf0e6c9257",
    description: "Open Plots @ Mumbai Highway",
    link: "project?id=4",
  },
];
const video_config_royal = {
  // title: "Royal Rajarikam Real Estate",
  // video: "https://www.youtube.com/watch?v=7FgRcnVMss0",
  // image:
  //   "https://silpa.co.in/wp-content/uploads/2022/04/vanadhara_video_bg.jpg",
  // description:
  // "Vanadhara is an expanse of 103 acres, a farmland venture at JP Darga Kothur with access to all right social amenities like multiple well-developed access roads, well-connected to schools, access to hospitals and village markets, it is located in a promising micro-market with great scope for property appreciation.",
};
const clients_count_value_config_royal = {
  years: 6,
  plots_sold: 2000,
  houses_sold: 24,
  happy_customers: 6000,
  acres_developed: 150,
  acres_under_development: 617,
};

const clients_count_config_royal = [
  {
    key: "years",
    title: "Years of Experience",
    suffix: "+",
  },
  {
    key: "plots_sold",
    title: "Plots Sold",
    suffix: "+",
  },
  {
    key: "houses_sold",
    title: "Houses Sold",
    suffix: "",
  },
  {
    key: "happy_customers",
    title: "Houses Customers",
    suffix: "+",
  },
  {
    key: "acres_developed",
    title: "Acres Developed",
    suffix: "",
  },
  {
    key: "acres_under_development",
    title: "Acres Under Development",
    suffix: "",
  },
];

const clients_testimonial_config_royal = [
  {
    name: "Mr. Sachin Nalawade",
    testimony:
      "I had a very good experience with team Royal Rajarikam Developers and extremely satisfied with the overall project and also from the investment purpose.",
  },
  {
    name: "MR. SANDIPAN & MR. KRISHANDU",
    testimony:
      "I came to know about Royal Rajarikam Developers through one of my friend who owned a plot at RR Estates Developers. I have as well booked open plot at Royal Rajarikam Developers and I am extremely pleased by their service in terms of pricing, delivery and location. I am proud to be a part of RR Estates Developers.",
  },
  {
    name: "MR. GANGADHAR",
    testimony:
      "I have re-invested in Royal Rajarikam Developers once again due to their excellent customer support which I received. I would love to recommend Royal Rajarikam Developers to all my colleagues and my sister has as well booked an plot at Shadnagar.",
  },
];

const blog_config_royal = [
  {
    id: 1,
    date: "April 1, 2020",
    label: "Why Invest in Royal Rajarikam",
    link: "/blog/1",
    image:
      "https://silpa.co.in/wp-content/uploads/2022/05/silpa_csk_green_villa_project.jpg",
  },
  {
    id: 2,
    date: "April 30, 2020",
    label: "Why Invest in Royal Rajarikam",
    link: "/blog/2",
    image:
      "https://silpa.co.in/wp-content/uploads/2022/05/silpa_csk_green_villa_project.jpg",
  },
];

const project_details_config_royal = [
  {
    heading: "VISION SPARK",
    subheading: "WELL DEVELOPED & SUSTAINABLE - PLOTS AND HOUSES",
    description1:
      "Royal Rajarikam, our partnership with Vision India Group launches a new project in Kondurg, Shadnagar - Banglore Highway, which is spread across 112 acres of beautiful landscapes. This sought-after project is very near to National Remote Sensing Centre (NRSC) and ISRO Centre. It is facing RRR 45 minutes from the international airport.  The whole project is DTCP Approved with a drainage system, electricity, street lights, and provide rainwater harvesting system.",
    description2:
      "Royal Rajarikam is a unique investment venture that offers you everything you could possibly want in an investment. Not only does this project provide you with a peaceful life amid nature, but it is also well-connected to the city so that you can continue to make progress and progress in your career. With Royal Rajarikam, you can make sure to live a luxurious life while also taking care of your financial needs. So if you're looking for an investment that will provide you with many benefits, Royal Rajarikam is the perfect option for you!",
    disclaimer:
      "Disclaimer: I authorize Anvika Infratech Ltd and its representatives to Call, SMS, Email or WhatsApp me about its ventures, investment opportunities and offers. This consent overrides any registration for DNC / NDNC.",
    youtube: "",
    brochure1:
      "https://firebasestorage.googleapis.com/v0/b/rrestate-2022.appspot.com/o/RoyalRajarikam%2FProjects%2FVision%20spark%2FVision%20Spark%20Brochure%20min.pdf?alt=media&token=e8703637-1899-4afd-89e7-9c5f90e60106",
    projectheading: "PROJECT HIGHLIGHTS",
    project_highlights: [
      {
        text: "DTCP Approved",
        image:
          "https://firebasestorage.googleapis.com/v0/b/rrestate-2022.appspot.com/o/RoyalRajarikam%2FProjects%2FVision%20spark%2Fspark%20dtcp%202.png?alt=media&token=c000f786-f708-40f4-a70f-8843b8b21200",
      },
      {
        text: "Compound wall around the site with solar fencing",
        image:
          "https://silpa.co.in/wp-content/uploads/2022/05/round_compound_wall.png",
      },
      {
        text: "Excellent children play area",
        image:
          "https://firebasestorage.googleapis.com/v0/b/rrestate-2022.appspot.com/o/RoyalRajarikam%2Fchildren_play_area.png?alt=media&token=2c533115-e004-49f0-bb4c-f61ac959ff12",
      },
      {
        text: "Excellent underground drainage system",
        image:
          "https://firebasestorage.googleapis.com/v0/b/rrestate-2022.appspot.com/o/RoyalRajarikam%2Funder_drainage_system.png?alt=media&token=2dc61aca-dc0b-46d8-b98d-4d69cc16b1e2",
      },
      {
        text: "Uninterrupted electricity connection",
        image:
          "https://firebasestorage.googleapis.com/v0/b/rrestate-2022.appspot.com/o/RoyalRajarikam%2Felectricity.png?alt=media&token=4ed06744-d36b-4046-b05b-09d411ab2b5f",
      },
      {
        text: "Street Lights",
        image:
          "https://firebasestorage.googleapis.com/v0/b/rrestate-2022.appspot.com/o/RoyalRajarikam%2Fstreet_lights.png?alt=media&token=b7078896-e424-4188-b39d-870330760cb7",
      },
      {
        text: "C.C Roads",
        image:
          "https://firebasestorage.googleapis.com/v0/b/rrestate-2022.appspot.com/o/RoyalRajarikam%2Fbt_top_roads.png?alt=media&token=c7548da1-53d9-438e-adf4-28fd7c62f364",
      },
      {
        text: "Overhead tank that provides continuous water supply",
        image:
          "https://firebasestorage.googleapis.com/v0/b/rrestate-2022.appspot.com/o/RoyalRajarikam%2Fwater_system_head_tank.png?alt=media&token=2a8dd273-ea9e-4ed4-8f39-5c1816974c01",
      },

      {
        text: "Avenue plantations",
        image:
          "https://firebasestorage.googleapis.com/v0/b/rrestate-2022.appspot.com/o/RoyalRajarikam%2Favenue_plantation.png?alt=media&token=03c60342-2a84-431a-b5dd-8bdb602408be",
      },
      {
        text: "Rain water harvesting",
        image:
          "https://firebasestorage.googleapis.com/v0/b/rrestate-2022.appspot.com/o/RoyalRajarikam%2Flandscape_gardens.png?alt=media&token=79f70066-ac6c-442b-902c-a02dac8b9988",
      },
    ],
    layoutheading: "PROJECT LAYOUT",

    projectlayoutimage:
      "https://firebasestorage.googleapis.com/v0/b/rrestate-2022.appspot.com/o/RoyalRajarikam%2FProjects%2FVision%20spark%2Fvision%20spark%20layout.png?alt=media&token=e51ab4da-b833-437d-89d6-2691a44ce5ca",

    downloadlayout:
      "https://firebasestorage.googleapis.com/v0/b/rrestate-2022.appspot.com/o/RoyalRajarikam%2FProjects%2FVision%20spark%2Fvision%20spark%20layout.png?alt=media&token=e51ab4da-b833-437d-89d6-2691a44ce5ca",

    projectlocheading: "PROJECT LOCATION",
    brochure2:
      "https://firebasestorage.googleapis.com/v0/b/rrestate-2022.appspot.com/o/RoyalRajarikam%2FProjects%2FVision%20spark%2FVision%20Spark%20Brochure%20min.pdf?alt=media&token=e8703637-1899-4afd-89e7-9c5f90e60106",

    projectlochighlights: "LOCATION HIGHLIGHTS",
    location_highlights: [
      "Very near to National Remote Sensing Centre",
      "Six lanes National Highway No 44 (Largest in the country connecting Kashmir to Kanyakumari",
      "MMTS Connectivity to Shadnagar",
      "RRR Facing",
      "45 Mins to International Airport",
      "Shadnagar Zoo Park spread on 1500 acres",
      "Shadnagar Bus & Railway Station is just 10 - 15 minutes drive",
      "Near JIMS Medical College",
      "Near NATCO Pharma School",
      "Johnson & Johnson | Procter & Gamble",
      "Amazon | Symbiosis Unversity",
    ],
    projectLoc:
      "https://maps.google.com/maps?q=17.087772,78.039528&t=&z=16&ie=UTF8&iwloc=&output=embed",
    broucher2:
      "https://firebasestorage.googleapis.com/v0/b/rrestate-2022.appspot.com/o/RoyalRajarikam%2FProjects%2FVision%20spark%2FVision%20Spark%20Brochure%20min.pdf?alt=media&token=e8703637-1899-4afd-89e7-9c5f90e60106",

    gallery: [],
    galleryVideo: [],
    videoThumnail: "",
  },
  {
    heading: "BRUNDHAVANAM COLONY @ SURYAPET",
    subheading: "WELL-DEVELOPED YET SUSTAINABLE – LEAD A UNIQUE LIFESTYLE",
    description1:
      "Royal Rajarikam, our Farm Plot Project in Manneguda, Chevella – Vikarabad Highway, Hyderabad is spread across 112 acres of beautiful landscapes. This sought-after project consists of diverse farmlands that have rich, fertile, and expansive soil to grow a wide variety of plants – a truly nurturing environment! The whole project is organised systematically and provided with an electricity connection and an HDPE water line for each plot along with a drip irrigation system.",
    description2:
      "Buying a plot of land is an excellent way to get out from under your current circumstances and start fresh. It's also an ideal investment because properties often appreciate in value over time, giving you an attractive return on your investment. So if this sounds like something that interests you, then be sure to investigate the available options carefully before making any decisions.",
    disclaimer:
      "Disclaimer: I authorize Anvika Infratech Ltd and its representatives to Call, SMS, Email or WhatsApp me about its ventures, investment opportunities and offers. This consent overrides any registration for DNC / NDNC.",
    youtube: "https://www.youtube.com/embed/jlJmFakmwWE",
    brochure1: "https://silpa.co.in/aranya_brochure.pdf",
    projectheading: "PROJECT HIGHLIGHTS",

    project_highlights: [
      {
        text: "All around Compound Wall",
        image:
          "https://silpa.co.in/wp-content/uploads/2022/05/round_compound_wall.png",
      },
      {
        text: "Grand Entrance Arch",
        image:
          "https://silpa.co.in/wp-content/uploads/2022/05/grand_entrance_gate.png",
      },
      {
        text: "Electricity Connection",
        image: "https://silpa.co.in/wp-content/uploads/2022/05/electricity.png",
      },
      {
        text: "HDPE Water line to Each Plot along with Drip",
        image:
          "https://silpa.co.in/wp-content/uploads/2022/05/water_pipeline.png",
      },
      {
        text: "Internal Roads with gravel",
        image:
          "https://silpa.co.in/wp-content/uploads/2022/05/bt_top_roads.png",
      },
      {
        text: "15000 sft Club House",
        image: "https://silpa.co.in/wp-content/uploads/2022/05/club_house.png",
      },
      {
        text: "Over Head Tank with Sump",
        image:
          "https://silpa.co.in/wp-content/uploads/2022/05/water_system_head_tank.png",
      },
      {
        text: "Street Lights",
        image:
          "https://silpa.co.in/wp-content/uploads/2022/05/street_lights.png",
      },
      {
        text: "Main Road with top layer BT and Kerbstone and Saucer Drains.",
        image:
          "https://silpa.co.in/wp-content/uploads/2022/05/under_drainage_system.png",
      },
      {
        text: "Avenue Plantation",
        image:
          "https://silpa.co.in/wp-content/uploads/2022/05/avenue_plantation.png",
      },
    ],
    layoutheading: "PROJECT LAYOUT",
    projectlayoutimage:
      "https://silpa.co.in/wp-content/uploads/2022/05/nature-avenu_layout-1024x723.jpg",
    broucher2: "https://silpa.co.in/aranya_brochure.pdf",
    projectlocheading: "PROJECT LOCATION",
    projectlochighlights: "LOCATION HIGHLIGHTS",
    projectLoc:
      "https://maps.google.com/maps?q=8J45%2BV6&t=&z=16&ie=UTF8&iwloc=&output=embed",
    gallery: [],
    galleryVideo: [],
    videoThumnail: "",
  },
  {
    heading: "SHIVA NANDI PHASE 2 @ Yacharam",
    subheading: "WELL-DEVELOPED YET SUSTAINABLE – LEAD A UNIQUE LIFESTYLE",
    description1:
      "Royal Rajarikam, our Farm Plot Project in Manneguda, Chevella – Vikarabad Highway, Hyderabad is spread across 112 acres of beautiful landscapes. This sought-after project consists of diverse farmlands that have rich, fertile, and expansive soil to grow a wide variety of plants – a truly nurturing environment! The whole project is organised systematically and provided with an electricity connection and an HDPE water line for each plot along with a drip irrigation system.",
    description2:
      "If you have been in search of the perfect farmland venture that offers you a peaceful life in the midst of nature while making sure you are well-connected to the city, Royal Rajarikam is an investment you cannot resist! With its long list of amenities (that include a 15,000 square feet clubhouse, a SPA, sports and leisure activities, etc.), its eco-friendly approach, and its well-developed layout, Royal Rajarikam is a great place to call home.",
    disclaimer:
      "Disclaimer: I authorize Anvika Infratech Ltd and its representatives to Call, SMS, Email or WhatsApp me about its ventures, investment opportunities and offers. This consent overrides any registration for DNC / NDNC.",
    youtube: "https://www.youtube.com/embed/jlJmFakmwWE",
    brochure1: "https://silpa.co.in/aranya_brochure.pdf",
    projectheading: "PROJECT HIGHLIGHTS",

    project_highlights: [
      {
        text: "All around Compound Wall",
        image:
          "https://silpa.co.in/wp-content/uploads/2022/05/round_compound_wall.png",
      },
      {
        text: "Grand Entrance Arch",
        image:
          "https://silpa.co.in/wp-content/uploads/2022/05/grand_entrance_gate.png",
      },
      {
        text: "Electricity Connection",
        image: "https://silpa.co.in/wp-content/uploads/2022/05/electricity.png",
      },
      {
        text: "HDPE Water line to Each Plot along with Drip",
        image:
          "https://silpa.co.in/wp-content/uploads/2022/05/water_pipeline.png",
      },
      {
        text: "Internal Roads with gravel",
        image:
          "https://silpa.co.in/wp-content/uploads/2022/05/bt_top_roads.png",
      },
      {
        text: "15000 sft Club House",
        image: "https://silpa.co.in/wp-content/uploads/2022/05/club_house.png",
      },
      {
        text: "Over Head Tank with Sump",
        image:
          "https://silpa.co.in/wp-content/uploads/2022/05/water_system_head_tank.png",
      },
      {
        text: "Street Lights",
        image:
          "https://silpa.co.in/wp-content/uploads/2022/05/street_lights.png",
      },
      {
        text: "Main Road with top layer BT and Kerbstone and Saucer Drains.",
        image:
          "https://silpa.co.in/wp-content/uploads/2022/05/under_drainage_system.png",
      },
      {
        text: "Avenue Plantation",
        image:
          "https://silpa.co.in/wp-content/uploads/2022/05/avenue_plantation.png",
      },
    ],
    location_highlights: [
      "Very near to National Remote Sensing Centre",
      "Six lanes National Highway No 44 (Largest in the country connecting Kashmir to Kanyakumari",
      "MMTS Connectivity to Shadnagar",
      "RRR Facing",
      "45 Mins to International Airport",
      "Shadnagar Zoo Park spread on 1500 acres",
      "Shadnagar Bus & Railway Station is just 10 - 15 minutes drive",
      "Near JIMS Medical College",
      "Near NATCO Pharma School",
      "Johnson & Johnson | Procter & Gamble",
      "Amazon | Symbiosis Unversity",
    ],
    layoutheading: "PROJECT LAYOUT",
    projectlayoutimage:
      "https://silpa.co.in/wp-content/uploads/2022/05/nature-avenu_layout-1024x723.jpg",
    broucher2: "https://silpa.co.in/aranya_brochure.pdf",
    projectlocheading: "PROJECT LOCATION",
    projectlochighlights: "LOCATION HIGHLIGHTS",
    projectLoc:
      "https://maps.google.com/maps?q=8J45%2BV6&t=&z=16&ie=UTF8&iwloc=&output=embed",
    gallery: [],
    galleryVideo: [],
    videoThumnail: "",
  },
  {
    heading: "Open Plots @ Mumbai Highway",
    subheading: "",
    description1:
      "Royal Rajarikam, our partnership with Real Bazaar launches a new project in Zaheerabad- Mumbai Highway, which is spread across 13 acres of beautiful landscapes. This sought-after project is very near to National Mumbai Highway. Just 4.5 KM to Mumbai Highway. The whole project is DTCP Approved with a drainage system, electricity, street lights, and provide rainwater harvesting system.",
    description2:
      "Royal Rajarikam is a unique investment venture that offers you everything you could possibly want in an investment. Not only does this project provide you with a peaceful life amid nature, but it is also well-connected to the city so that you can continue to make progress and progress in your career. With Royal Rajarikam, you can make sure to live a luxurious life while also taking care of your financial needs. So if you're looking for an investment that will provide you with many benefits, Royal Rajarikam is the perfect option for you!",
    disclaimer:
      "Disclaimer: I authorize Anvika Infratech Ltd and its representatives to Call, SMS, Email or WhatsApp me about its ventures, investment opportunities and offers. This consent overrides any registration for DNC / NDNC.",
    brochure1:
      "https://firebasestorage.googleapis.com/v0/b/royal-rajarikam.appspot.com/o/Woxen%20County.pdf?alt=media&token=a40b10a1-9621-428d-a037-20cdc95c6267",
    projectheading: "PROJECT HIGHLIGHTS",

    project_highlights: [
      {
        text: "All around Compound Wall",
        image:
          "https://silpa.co.in/wp-content/uploads/2022/05/round_compound_wall.png",
      },
      {
        text: "Grand Entrance Arch",
        image:
          "https://silpa.co.in/wp-content/uploads/2022/05/grand_entrance_gate.png",
      },
      {
        text: "Electricity Connection",
        image: "https://silpa.co.in/wp-content/uploads/2022/05/electricity.png",
      },
      {
        text: "HDPE Water line to Each Plot along with Drip",
        image:
          "https://silpa.co.in/wp-content/uploads/2022/05/water_pipeline.png",
      },
      {
        text: "Internal Roads with gravel",
        image:
          "https://silpa.co.in/wp-content/uploads/2022/05/bt_top_roads.png",
      },
      {
        text: "15000 sft Club House",
        image: "https://silpa.co.in/wp-content/uploads/2022/05/club_house.png",
      },
      {
        text: "Over Head Tank with Sump",
        image:
          "https://silpa.co.in/wp-content/uploads/2022/05/water_system_head_tank.png",
      },
      {
        text: "Street Lights",
        image:
          "https://silpa.co.in/wp-content/uploads/2022/05/street_lights.png",
      },
      {
        text: "Main Road with top layer BT and Kerbstone and Saucer Drains.",
        image:
          "https://silpa.co.in/wp-content/uploads/2022/05/under_drainage_system.png",
      },
      {
        text: "Avenue Plantation",
        image:
          "https://silpa.co.in/wp-content/uploads/2022/05/avenue_plantation.png",
      },
    ],
    location_highlights: [
      "1.5 kms to Mumbai Highway",
      "1 kms to Food Processing Unit",
      "2 kms to woxsen University",
      "10 Mins to Sadasivapet Town",
      "10 Mins to Mahindra Tractor Plant",
      "15 Mins to NIMZ",
      "15 Mins to IIT Hyderabad",
      "Near by Gitam University",
    ],
    layoutheading: "PROJECT LAYOUT",
    projectlayoutimage:
      "https://firebasestorage.googleapis.com/v0/b/royal-rajarikam.appspot.com/o/Woxen%20County%20Layout.png?alt=media&token=695b003f-429d-4d52-a329-df4efa9b5446",
    broucher2:
      "https://firebasestorage.googleapis.com/v0/b/royal-rajarikam.appspot.com/o/Woxen%20County%20Layout.png?alt=media&token=695b003f-429d-4d52-a329-df4efa9b5446",
    projectlocheading: "PROJECT LOCATION",
    projectlochighlights: "LOCATION HIGHLIGHTS",
    projectLoc:
      "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3802.2975899451144!2d77.78179231487985!3d17.636058987929964!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x1dd09172020c844!2zMTfCsDM4JzA5LjgiTiA3N8KwNDcnMDIuMyJF!5e0!3m2!1sen!2sin!4v1668506061277!5m2!1sen!2sin",
    gallery: [],
    galleryVideo: [],
    videoThumnail: "",
  },
];
const contact_map_royal = {
  disclaimer:
    "Disclaimer: I authorize Anvika Infratech Ltd and its representatives to Call, SMS, Email or WhatsApp me about its ventures, investment opportunities and offers. This consent overrides any registration for DNC / NDNC.",
  loc: "https://maps.google.com/maps?q=8J45%2BV6&t=&z=16&ie=UTF8&iwloc=&output=embed",
};
const about_page_config_royal = {
  about_img:
    "https://silpa.co.in/wp-content/uploads/2022/04/about_company_new.jpg",
  vision_img:
    "https://silpa.co.in/wp-content/uploads/2022/04/vision_mission.jpg",
  about_1:
    "Royal Rajarikam Developers is one of the most reputed and well-recognized Real Estate Developers in Telangana and Andhra Pradesh. Over the years, we have been employing our best methods to convert our clients’ ideas into magnificent infrastructure, aiming to become the Top Real Estate Company. Royal Rajarikam Developers. focuses on developing the properties keeping in mind what is best for our customers.",
  about_2:
    "Established in the year 2022, founded by Mr. Rajarikam Bhushan Garu, He is one of the first and presently the senior-most real estate pioneers of Hyderabad and has received the prestigious National Citizens Award from the Indian Central Government.",

  about_3:
    "Since its inception, Royal Rajarikam Developers. has successfully completed and handed over 20 projects (a total of 1200 acres). Apart from that, another 350 acres of land are under development.",

  about_4:
    "Royal Rajarikam Developers. always believes in developing projects that redefine lifestyle. Today, our company can proudly say that they have about 10,000+ happy customers.",

  vision:
    "Our vision is to be the leading real estate service provider in Telangana and Andhra Pradesh, and the preferred place of employment for professionals. Royal Rajarikam Developers consistently strives to develop collaborative partnerships, based on transparency and mutual trust, which serve to build enduring customer relationships. As we expand, we're committed to these principles, which have served our company and customers through the years.",
  mission:
    "Royal Rajarikam Developers is dedicated to achieving our vision by creating an energetic, positive, results-driven work environment that focuses on the investment and development of long-term relationships. We measure our success by the projects delivered to customers. Our ethics are built on our commitment to offering superior customer service coupled with an entrepreneurial flair and bespoke service of a fast-growing company.",
  value:
    "We believe in creating promising investment opportunities for our customers by providing them with knowledge and accurate information about the projects. Here's what sets us apart:",
  value_list: [
    {
      image:
        "https://firebasestorage.googleapis.com/v0/b/rrestate-2022.appspot.com/o/RoyalRajarikam%2F15yr.png?alt=media&token=3bd4595b-1b34-4cd0-a282-03258f0a0fbe",
      description: "15 years of Real Estate experience",
    },
    {
      image:
        "https://silpa.co.in/wp-content/uploads/2022/05/silpa_timely_delivered.png",
      description: "Timely delivery of projects",
    },
    {
      image:
        "https://silpa.co.in/wp-content/uploads/2022/05/silpa_clear_title_property.png",
      description: "Clear Title properties",
    },
    {
      image:
        "https://silpa.co.in/wp-content/uploads/2022/05/silpa_prime_locations.png",
      description: "Ventures in prime locations",
    },
    {
      image:
        "https://silpa.co.in/wp-content/uploads/2022/05/silpa_higher_returns.png",
      description: "Higher Return on Investment",
    },
    {
      image:
        "https://silpa.co.in/wp-content/uploads/2022/05/silpa_top_notch_development.png",
      description: "Top-notch development",
    },
    {
      image:
        "https://silpa.co.in/wp-content/uploads/2022/05/silpa_trust_management.png",
      description: "Well-established and trusted management",
    },
    {
      image:
        "https://silpa.co.in/wp-content/uploads/2022/05/silpa_hassle_free.png",
      description: "Hassle-free process",
    },
  ],
  value_title: "WHY CHOOSE ROYAL RAJARIKAM?",
};
const PHONE_NUMBER_ROYAL = "+919390659518";
const EMAIL_ID_ROYAL = "bhushan.rajarikam@gmail.com";
const ADDRESS_ROYAL = "Main Road, Near Shaneshwar Swamy Temple, Munganoor";
const CITY_ROYAL = "Hayathnagar, RR Dist.";
const STATE_ROYAL = "Telangana";
const ZIP_CODE_ROYAL = "501511";
const COUNTRY_ROYAL = "India";
const FAX_NUMBER_ROYAL = "";
const WEB_URL_ROYAL = "www.rr.estate";
const FACEBOOK_URL_ROYAL = "https://www.facebook.com/royalrajarikamdevelopers/";
const TWITTER_URL_ROYAL = "www.twitter.com";
const INSTAGRAM_URL_ROYAL =
  "https://www.instagram.com/royalrajarikamdevelopers/";
const YOUTUBE_URL_ROYAL = "www.youtube.com";
const LINKEDIN_URL_ROYAL = "www.linkedin.com";
const GOOGLE_URL_ROYAL = "www.google.com";
const PINTEREST_URL_ROYAL = "www.pinterest.com";
const COMPANY_ROYAL = "royal";

// CONFIG SWITCH
const web_title =
  process.env.REACT_APP_APPLICATION_NAME !== "royal"
    ? web_title_anvi
    : web_title_royal;
const PHONE_NUMBER =
  process.env.REACT_APP_APPLICATION_NAME !== "royal"
    ? PHONE_NUMBER_ANVI
    : PHONE_NUMBER_ROYAL;
const EMAIL_ID =
  process.env.REACT_APP_APPLICATION_NAME !== "royal"
    ? EMAIL_ID_ANVI
    : EMAIL_ID_ROYAL;
const ADDRESS =
  process.env.REACT_APP_APPLICATION_NAME !== "royal"
    ? ADDRESS_ANVI
    : ADDRESS_ROYAL;
const CITY =
  process.env.REACT_APP_APPLICATION_NAME !== "royal" ? CITY_ANVI : CITY_ROYAL;
const STATE =
  process.env.REACT_APP_APPLICATION_NAME !== "royal" ? STATE_ANVI : STATE_ROYAL;
const ZIP_CODE =
  process.env.REACT_APP_APPLICATION_NAME !== "royal"
    ? ZIP_CODE_ANVI
    : ZIP_CODE_ROYAL;
const COUNTRY =
  process.env.REACT_APP_APPLICATION_NAME !== "royal"
    ? COUNTRY_ANVI
    : COUNTRY_ROYAL;
const FAX_NUMBER =
  process.env.REACT_APP_APPLICATION_NAME !== "royal"
    ? FAX_NUMBER_ANVI
    : FAX_NUMBER_ROYAL;
const WEB_URL =
  process.env.REACT_APP_APPLICATION_NAME !== "royal"
    ? WEB_URL_ANVI
    : WEB_URL_ROYAL;
const FACEBOOK_URL =
  process.env.REACT_APP_APPLICATION_NAME !== "royal"
    ? FACEBOOK_URL_ANVI
    : FACEBOOK_URL_ROYAL;
const TWITTER_URL =
  process.env.REACT_APP_APPLICATION_NAME !== "royal"
    ? TWITTER_URL_ANVI
    : TWITTER_URL_ROYAL;
const INSTAGRAM_URL =
  process.env.REACT_APP_APPLICATION_NAME !== "royal"
    ? INSTAGRAM_URL_ANVI
    : INSTAGRAM_URL_ROYAL;
const YOUTUBE_URL =
  process.env.REACT_APP_APPLICATION_NAME !== "royal"
    ? YOUTUBE_URL_ANVI
    : YOUTUBE_URL_ROYAL;
const LINKEDIN_URL =
  process.env.REACT_APP_APPLICATION_NAME !== "royal"
    ? LINKEDIN_URL_ANVI
    : LINKEDIN_URL_ROYAL;
const GOOGLE_URL =
  process.env.REACT_APP_APPLICATION_NAME !== "royal"
    ? GOOGLE_URL_ANVI
    : GOOGLE_URL_ROYAL;
const PINTEREST_URL =
  process.env.REACT_APP_APPLICATION_NAME !== "royal"
    ? PINTEREST_URL_ANVI
    : PINTEREST_URL_ROYAL;

const COMPANY =
  process.env.REACT_APP_APPLICATION_NAME !== "royal"
    ? COMPANY_ANVI
    : COMPANY_ROYAL;

const carousel_config =
  process.env.REACT_APP_APPLICATION_NAME !== "royal"
    ? carousel_config_anvi
    : carousel_config_royal;
const about_config =
  process.env.REACT_APP_APPLICATION_NAME !== "royal"
    ? about_config_anvi
    : about_config_royal;
const our_project_config =
  process.env.REACT_APP_APPLICATION_NAME !== "royal"
    ? our_project_config_anvi
    : our_project_config_royal;
const video_config =
  process.env.REACT_APP_APPLICATION_NAME !== "royal"
    ? video_config_anvi
    : video_config_royal;
const clients_count_value_config =
  process.env.REACT_APP_APPLICATION_NAME !== "royal"
    ? clients_count_value_config_anvi
    : clients_count_value_config_royal;
const clients_count_config =
  process.env.REACT_APP_APPLICATION_NAME !== "royal"
    ? clients_count_config_anvi
    : clients_count_config_royal;
const clients_testimonial_config =
  process.env.REACT_APP_APPLICATION_NAME !== "royal"
    ? clients_testimonial_config_anvi
    : clients_testimonial_config_royal;
const blog_config =
  process.env.REACT_APP_APPLICATION_NAME !== "royal"
    ? blog_config_anvi
    : blog_config_royal;
const project_details_config =
  process.env.REACT_APP_APPLICATION_NAME !== "royal"
    ? project_details_config_anvi
    : project_details_config_royal;

const contact_map =
  process.env.REACT_APP_APPLICATION_NAME !== "royal"
    ? contact_map_anvi
    : contact_map_royal;
const about_page_config =
  process.env.REACT_APP_APPLICATION_NAME !== "royal"
    ? about_page_config_anvi
    : about_page_config_royal;
export {
  ADDRESS,
  CITY,
  COMPANY,
  COUNTRY,
  EMAIL_ID,
  FACEBOOK_URL,
  FAX_NUMBER,
  GOOGLE_URL,
  INSTAGRAM_URL,
  LINKEDIN_URL,
  PHONE_NUMBER,
  PINTEREST_URL,
  STATE,
  TWITTER_URL,
  WEB_URL,
  YOUTUBE_URL,
  ZIP_CODE,
  about_config,
  about_page_config,
  blog_config,
  carousel_config,
  clients_count_config,
  clients_count_value_config,
  clients_testimonial_config,
  contact_map,
  our_project_config,
  project_details_config,
  video_config,
  web_title,
};
