export default function Contact() {
  return (
    <>
      <div className="intro-section small bg-hero-2">
        <div className="container">
          <div className="row align-items-center justify-content-center">
            <div className="col-md-7 mx-auto text-center" data-aos="fade-up">
              <div className="intro">
                <h1>Contact us</h1>
                <p>
                  Lorem ipsum dolor sit amet, consectetur adipisicing elit. Sit,
                  nihil.
                </p>
                <p>
                  <a href="#a" className="btn btn-primary">
                    Get Started
                  </a>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="site-section">
        <div className="container">
          <div className="row">
            <div className="col-md-6 form-group">
              <label for="fname">First Name</label>
              <input
                type="text"
                id="fname"
                className="form-control form-control-lg"
              />
            </div>
            <div className="col-md-6 form-group">
              <label for="lname">Last Name</label>
              <input
                type="text"
                id="lname"
                className="form-control form-control-lg"
              />
            </div>
          </div>
          <div className="row">
            <div className="col-md-6 form-group">
              <label for="eaddress">Email Address</label>
              <input
                type="text"
                id="eaddress"
                className="form-control form-control-lg"
              />
            </div>
            <div className="col-md-6 form-group">
              <label for="tel">Tel. Number</label>
              <input
                type="text"
                id="tel"
                className="form-control form-control-lg"
              />
            </div>
          </div>
          <div className="row">
            <div className="col-md-12 form-group">
              <label for="message">Message</label>
              <textarea
                name=""
                id="message"
                cols="30"
                rows="10"
                className="form-control"
              ></textarea>
            </div>
          </div>

          <div className="row">
            <div className="col-12">
              <input
                type="submit"
                value="Send Message"
                className="btn btn-primary btn-lg px-5"
              />
            </div>
          </div>
        </div>
      </div>

      <div className="site-section ftco-subscribe-1 bg-hero-2">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-7">
              <h2>Subscribe to us!</h2>
              <p>
                Far far away, behind the word mountains, far from the countries
                Vokalia and Consonantia,
              </p>
            </div>
            <div className="col-lg-5">
              <form action="" className="d-flex">
                <input
                  type="text"
                  className="rounded form-control mr-2 py-3"
                  placeholder="Enter your email"
                />
                <button
                  className="btn btn-primary rounded py-3 px-4"
                  type="submit"
                >
                  Send
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
