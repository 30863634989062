import { our_project_config } from "configuration/config";

const PhotoGallery = () => {
  return (
    <>
      <section className="banner-header banner-img section-padding valign bg-img bg-fixed photo-gallery-banner"></section>
      <div className="site-wrap pb-4">
        <div className="container">
          <div className="mt-3 text-center">
            <h2 className="text-head-color">PHOTO GALLERY</h2>
            <span className="divider-separator">
              <i className="fas fa-leaf aboutus-sub-text-head px-3"></i>
            </span>
          </div>
          <div className="row justify-content-center">
            {our_project_config.map((item, index) => {
              return (
                <>
                  <div
                    className="col-md-6 col-lg-4 news-entry-item mt-3"
                    key={index}
                  >
                    <a href={item.plink}>
                      <div className="card ">
                        <img src={item.image} alt="img2" width="100%" />
                      </div>
                      <div className="card photo-gallery-card bg-primary py-3 text-center">
                        <strong className="blog_text text-white">
                          {item.title}
                        </strong>
                      </div>
                    </a>
                  </div>
                </>
              );
            })}
          </div>
        </div>
      </div>
    </>
  );
};

export default PhotoGallery;
