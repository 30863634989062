import "owl.carousel/dist/assets/owl.carousel.css";
import OwlCarousel from "react-owl-carousel";
import { carousel_config } from "../../configuration/config";
const Carousel = () => {
  return (
    <>
      <div className="site-wrap">
        <div
          className="hero-slide owl-carousel site-blocks-cover"
          style={{ display: "block" }}
        >
          <OwlCarousel
            className="owl-theme"
            loop
            navText={[
              '<span class="icon-arrow_back">',
              '<span class="icon-arrow_forward">',
            ]}
            items={1}
            nav
          >
            {carousel_config.map((item, index) => {
              return (
                <div className={"intro-section " + item.class} key={index}>
                  <div className="container">
                    <div className="row align-items-center justify-content-center">
                      <div className="col-md-9 mx-auto text-center">
                        <h1 data-aos="fade-up" data-aos-duration="1000">
                          {item.title}
                        </h1>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
          </OwlCarousel>
        </div>
      </div>
    </>
  );
};

export default Carousel;
