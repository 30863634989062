import { about_config } from "configuration/config";

const AboutSection = () => {
  return (
    <>
      <div
        className="about section-padding"
        data-aos="fade-up"
        data-aos-delay="200"
        data-aos-offset="200"
      >
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="about">
                <figure>
                  <img
                    src={about_config.image}
                    alt="img"
                    className="img-fluid"
                  />
                </figure>
                <div className="caption">
                  <span className="text-white">Get to know us</span>
                  <div className="section-title">About the Company</div>
                  <p className="text-white" style={{ letterSpacing: 0 }}>
                    {about_config.description}
                  </p>
                  <a href="/about" className="btn custom-btn to-right">
                    Read more
                    <i className="fas fa-long-arrow-alt-right pl-2"></i>
                  </a>
                  {/* <button
                    className="btn custom-btn to-right"
                    type="submit"
                    href="/about"
                  >
                    Read more{" "}
                    <i className="fas fa-long-arrow-alt-right pl-2"></i>
                  </button> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AboutSection;
