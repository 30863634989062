import { about_page_config } from "configuration/config";
import { ABOUTUS_IMG_1, ABOUTUS_IMG_2 } from "../../configuration/environment";

const AboutUs = () => {
  return (
    <>
      <section className="banner-header banner-img section-padding valign bg-img bg-fixed photo-gallery-banner"></section>
      <div className="site-wrap">
        <div className="container">
          <div className="row pb-5">
            <div className="col-md-12">
              <div className="about">
                <figure>
                  <img src={ABOUTUS_IMG_1} alt="img" className="img-fluid" />
                </figure>
                <div className="caption">
                  <span className="text-white">ABOUT US</span>
                  <div className="section-title">OUR STORY</div>
                  <p className="text-white" style={{ letterSpacing: 0 }}>
                    {about_page_config.about_1}
                  </p>
                  <p className="text-white" style={{ letterSpacing: 0 }}>
                    {about_page_config.about_2}
                  </p>
                  <p className="text-white" style={{ letterSpacing: 0 }}>
                    {about_page_config.about_3}
                  </p>
                  <p className="text-white" style={{ letterSpacing: 0 }}>
                    {about_page_config.about_4}
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="row pt-5 mt-5">
            <div className="col-md-12">
              <div className="about left">
                <figure>
                  <img src={ABOUTUS_IMG_2} alt="img" className="img-fluid" />
                </figure>
                <div className="caption">
                  <span className="text-white">OUR</span>
                  <div className="section-title">VISION & MISSION</div>

                  <p className="text-white" style={{ letterSpacing: 0 }}>
                    {about_page_config.vision}
                  </p>
                  <p className="text-white" style={{ letterSpacing: 0 }}>
                    {about_page_config.mission}
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div className="my-5 text-center pt-5">
            <strong className="aboutus_text_head">
              {about_page_config.value_title}
            </strong>
            <span className="divider-separator">
              <i className="fas fa-leaf aboutus-sub-text-head px-3"></i>
            </span>
            <p className="aboutus_sub_text2 mt-4">
              We are here to create a real value in property and places.
            </p>
            <p className="aboutus_sub_text3">{about_page_config.value}</p>
          </div>

          <div className="row">
            {about_page_config.value_list.map((item, index) => {
              return (
                <div className="col-lg-3 col-md-6 col-12" key={index}>
                  <div className="aboutus_card">
                    <div className="">
                      <img
                        src={item.image}
                        alt="Sample"
                        className="img-fluid"
                      />
                      <div>
                        <strong className="aboutus_card_tex">
                          {item.description}
                        </strong>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </>
  );
};

export default AboutUs;
