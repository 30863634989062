import { project_details_config } from "configuration/config";
import "react-medium-image-zoom/dist/styles.css";
import { useSearchParams } from "react-router-dom";
const Photos = () => {
  const [searchParams] = useSearchParams();
  const paramValue = parseInt(searchParams.get("id"));
  const projectDetails = project_details_config[paramValue - 1];

  return (
    <>
      <section className="banner-header banner-img section-padding valign bg-img bg-fixed photo-gallery-banner"></section>
      <div className="site-wrap pb-4">
        <div className="container">
          <div className="mt-3 text-center">
            <h2 className="text-head-color">
              {projectDetails && projectDetails.heading} GALLERY
            </h2>
            <span className="divider-separator">
              <i className="fas fa-leaf aboutus-sub-text-head px-3"></i>
            </span>
          </div>
          <div className="row d-flex justify-content-center my-4">
            {projectDetails &&
              projectDetails.gallery &&
              projectDetails.gallery.length > 0 &&
              projectDetails.gallery.map((i, index) => {
                return (
                  <div className="col-lg-4 col-md-6 col-12" key={index}>
                    <div className="aboutus_card">
                      <div className="">
                        <img
                          src={i}
                          alt="Sample"
                          width="100%"
                          className="img-fluid img_trans"
                        />
                      </div>
                    </div>
                  </div>
                );
              })}
          </div>
        </div>
      </div>
    </>
  );
};

export default Photos;
