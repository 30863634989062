import { useState } from "react";
import AnimatedNumber from "react-animated-number";
import VisibilitySensor from "react-visibility-sensor";
import {
  clients_count_config,
  clients_count_value_config,
} from "../../configuration/config";
const ClientCounts = () => {
  const [counter, setCounter] = useState({
    years: 0,
    past_projects: 0,
    happy_customers: 0,
    acres_developed: 0,
    acres_under_development: 0,
    acres_under_future_development: 0,
  });

  return (
    <VisibilitySensor
      onChange={(isVisible) => {
        if (isVisible) {
          setTimeout(() => setCounter(clients_count_value_config), 400);
        }
      }}
    >
      <div
        className="site-wrap"
        data-aos="fade-up"
        data-aos-id="super-duper"
        data-aos-duration="400"
      >
        <div className="site-section" style={{ paddingTop: "5em" }}>
          <div className="container">
            <div className="row">
              {clients_count_config.map((item, index) => {
                return (
                  <div className="col-lg-2 col-12" key={index}>
                    <div className="numbers">
                      <AnimatedNumber
                        value={counter[item.key]}
                        className="label"
                        duration={3000}
                        formatValue={(n) =>
                          n
                            .toFixed(0)
                            .toString()
                            .replace(/\B(?=(\d{3})+(?!\d))/g, ",") + item.suffix
                        }
                      />
                      <span>{item.title}</span>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </VisibilitySensor>
  );
};

export default ClientCounts;
