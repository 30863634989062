/* eslint-disable jsx-a11y/anchor-is-valid */
import { PHONE_NUMBER } from "../../configuration/config";
import "./Navbar.css";

import anvikalogo from "../../assets/images/anvika_logo.png";
import rrlogo from "../../assets/images/rr_color.svg";
import rrwhite from "../../assets/images/rr_white.svg";

const NavHeader = () => {
  return (
    <>
      <nav className="navbar navbar-expand-lg navbar-dark">
        <div className="container">
          <a href="/" className="navbar-brand">
            {process.env.REACT_APP_APPLICATION_NAME !== "royal" ? (
              <img
                src={anvikalogo}
                style={{ width: "90px" }}
                alt="Anvika Logo"
                className="img-fluid company-logo"
              />
            ) : (
              <img
                src={rrlogo}
                style={{ width: "160px", height: "100px", padding: "10px" }}
                alt="Royal Rajarikam Logo"
                className="img-fluid company-logo"
              />
            )}
            {process.env.REACT_APP_APPLICATION_NAME !== "royal" ? (
              <img
                src={anvikalogo}
                style={{ width: "90px" }}
                alt="Anvika Logo"
                className="img-fluid white-company-logo"
              />
            ) : (
              <img
                src={rrwhite}
                style={{ width: "160px", height: "100px", padding: "10px" }}
                alt="Royal Rajarikam Logo"
                className="img-fluid white-company-logo"
              />
            )}
          </a>

          <button
            type="button"
            className="navbar-toggler collapsed"
            data-toggle="collapse"
            data-target="#main-nav"
          >
            <span className="menu-icon-bar"></span>
            <span className="menu-icon-bar"></span>
            <span className="menu-icon-bar"></span>
          </button>

          <div id="main-nav" className="collapse navbar-collapse">
            <ul className="navbar-nav mr-auto align-items-center">
              <li>
                <a
                  href="/"
                  className={
                    "nav-item nav-link" +
                    (window.location.pathname === "/" ? " active" : "")
                  }
                >
                  Home
                </a>
              </li>
              <li>
                <a
                  href="/about"
                  className={
                    "nav-item nav-link" +
                    (window.location.pathname === "/about" ? " active" : "")
                  }
                >
                  About Us
                </a>
              </li>
              <li className="dropdown">
                <a
                  href="/projects"
                  className={
                    "nav-item nav-link" +
                    (window.location.pathname === "/projects" ? " active" : "")
                  }
                >
                  Projects
                </a>
              </li>
              <li className="dropdown">
                <a
                  href="#"
                  className={
                    "nav-item nav-link" +
                    (window.location.pathname === "/photo-gallery" ||
                    window.location.pathname === "/video-gallery" ||
                    window.location.pathname === "/event-gallery"
                      ? " active"
                      : "")
                  }
                  data-toggle="dropdown"
                >
                  Gallery
                </a>
                <div className="dropdown-menu">
                  <a href="/photo-gallery" className="dropdown-item">
                    Photo Gallery
                  </a>
                  <a href="/video-gallery" className="dropdown-item">
                    Video Gallery
                  </a>
                  {/* <a href="/event-gallery" className="dropdown-item">
                    Events
                  </a> */}
                </div>
              </li>
              {/* <li className="dropdown">
                <a
                  href="/blog"
                  className={
                    "nav-item nav-link" +
                    (window.location.pathname === "/blog" ? " active" : "")
                  }
                >
                  Blog & News
                </a>
              </li> */}
              <li>
                <a
                  href="/contacts"
                  className={
                    "nav-item nav-link" +
                    (window.location.pathname === "/contacts" ? " active" : "")
                  }
                >
                  Contact Us
                </a>
              </li>
              <li>
                <p className="top_phone m-0">
                  <a
                    href={"tel:" + PHONE_NUMBER}
                    className="blinking text-white p-2"
                  >
                    {PHONE_NUMBER}
                  </a>
                </p>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </>
  );
};

export default NavHeader;
