import UserForm from "../form/UserForm";

const SendMessage = () => {
  return (
    <>
      <button
        type="button"
        className="btn modal-bg-message text-white sendmessage_float msg-btn-rotate"
        data-toggle="modal"
        data-target="#exampleModalCenter"
      >
        Send message
      </button>

      <div
        className="modal fade come-from-modal right"
        id="exampleModalCenter"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="exampleModalCenterTitle"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-scrollable" role="document">
          <div className="modal-content scrollable">
            <div className="modal-header modal-bg-message text-center">
              <span
                className="modal-title text-white pb-4 pt-4 modal-title-align"
                id="exampleModalLongTitle"
              >
                Request a Call Back
              </span>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span className="text-white" aria-hidden="true">
                  &times;
                </span>
              </button>
            </div>
            <div className="modal-body">
              <div className="model-inner-div card card-shadow py-3 px-2">
                <UserForm />
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <div
        className="modal fade come-from-modal right"
        id="exampleModalCenter"
        tabindex="-1"
        role="dialog"
        aria-labelledby="exampleModalCenterTitle"
        aria-hidden="true"
      >
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header modal-bg-message text-center">
              <h5 className="modal-title text-white " id="exampleModalLongTitle">
                Request a Call Back
              </h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <div className="row d-grid">
                <div className="col-12 pb-2">
                  <input
                    type="text"
                    name="name"
                    className="form-control name blog-input-height"
                    placeholder="Full Name*"
                    required=""
                  />
                </div>
                <div className="col-12 pb-2">
                  <input
                    type="text"
                    name="name"
                    className="form-control name blog-input-height"
                    placeholder="Phone Number*"
                    required=""
                  />
                </div>
                <div className="col-12 pb-2">
                  <input
                    type="text"
                    name="name"
                    className="form-control name blog-input-height"
                    placeholder="Enter Your Email*"
                    required=""
                  />
                </div>
                <div className="col-12 pb-2">
                  <textarea
                    type="textarea"
                    name="name"
                    className="form-control name blog-input-height"
                    placeholder="Your message"
                    required=""
                  />
                </div>
                <div className="col-12 mt-5">
                  <button className="btn custom-btn-sm to-right form-control blog-input-height">
                    Submit
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div> */}
    </>
  );
};

export default SendMessage;
