import React from "react";

const Whatsapp = () => {
  return (
    <a
      href="https://wa.me/918179894526/?text=I%20Saw%20in%20Website"
      className="whatsapp_float"
      target="_blank"
      rel="noopener noreferrer"
    >
      <i className="fab fa-whatsapp whatsapp-icon"></i>
    </a>
  );
};

export default Whatsapp;
