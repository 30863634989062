import React from "react";
import { clients_testimonial_config } from "../../configuration/config";
import OwlCarousel from "react-owl-carousel";

const HappyClients = () => {
  return (
    <>
      <div className="site-wrap" data-aos="fade-up" data-aos-delay="600">
        <div className="section-bg style-1 mb-3">
          <div className="container">
            <div className="row">
              <div className="col-lg-7 text-center mx-auto">
                <span className="caption text-white">OUR CUSTOMER</span>
                <h2 className="title-with-line text-center mb-5 text-white">
                  TESTIMONIALS
                </h2>
              </div>
            </div>
            <OwlCarousel
              margin={20}
              loop
              items={3}
              responsive={{
                0: {
                  stagePadding: 10,
                  items: 1,
                },
                480: {
                  stagePadding: 10,
                  items: 1,
                },
                768: {
                  stagePadding: 10,
                  items: 2,
                },
                992: {
                  stagePadding: 10,
                  items: 3,
                },
                1200: {
                  stagePadding: 10,
                  items: 3,
                },
              }}
            >
              {clients_testimonial_config.map((item, index) => {
                return (
                  <div className="ftco-testimonial-1" key={index}>
                    <div>
                      <p>{item.testimony}</p>
                    </div>
                    <h3 className="caption text-uppercase text-white">
                      {item.name}
                    </h3>
                  </div>
                );
              })}
            </OwlCarousel>
            <div className="owl-slide owl-carousel owl-testimonial">
              {clients_testimonial_config.map((item, index) => {
                return (
                  <div className="ftco-testimonial-1" key={index}>
                    <div>
                      <p>{item.testimony}</p>
                    </div>
                    <h3 className="caption text-uppercase text-white">
                      {item.name}
                    </h3>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default HappyClients;
