import React from "react";
import Zoom from "react-medium-image-zoom";
import "react-medium-image-zoom/dist/styles.css";
import { useSearchParams } from "react-router-dom";
import UserForm from "../components/form/UserForm";
import VideoPlayer from "../components/player/VideoPlayer";
import { project_details_config } from "../configuration/config";
export default function Project() {
  const [searchParams] = useSearchParams();
  const paramValue = parseInt(searchParams.get("id"));
  const projectDetails = project_details_config[paramValue - 1];
  return (
    <>
      <section className="banner-header banner-img section-padding valign bg-img bg-fixed photo-gallery-banner"></section>
      {paramValue && projectDetails && (
        <div className="container">
          <div className="row mb-3">
            <div className="col-md-8">
              <div className="my-3">
                <strong className="project-details-heading">
                  {projectDetails.heading}
                </strong>
                <p className="project-details-sub-heading">
                  {projectDetails.subheading}
                </p>
                <span className="divider-separator2">
                  <i className="fas fa-leaf aboutus-sub-text-head px-3"></i>
                </span>
              </div>
              <div className="mt-5">
                <p className="project-detail-text">
                  {projectDetails.description1}
                </p>
                <p className="project-detail-text">
                  {projectDetails.description2}
                </p>
              </div>
            </div>
            <div className="col-md-4 bg-primary">
              <div className="text-center text-white my-3">
                <span className="project-detail-form-head">
                  WE'D LOVE TO HEAR FROM YOU GET IN TOUCH
                </span>
              </div>
              <div>
                <UserForm />
              </div>
              <div className="px-2">
                <span className="text-white project-detail-form-disclaimer">
                  {projectDetails.disclaimer}
                </span>
              </div>
            </div>
          </div>
          {projectDetails.youtube ? (
            <div className="row d-flex py-4">
              <div className="col-md-8 col-12 px-2 mx-auto">
                <iframe
                  title="Block"
                  className="project-detail-video-block"
                  //   width={640}
                  //   height={360}
                  src={projectDetails.youtube}
                ></iframe>
              </div>
            </div>
          ) : null}

          <div className="text-center pt-4">
            <a
              href={projectDetails.brochure1}
              rel="noreferrer"
              target="_blank"
              className="btn custom-btn to-right"
            >
              <span className="project-detail-download">
                Download brochure <i className="fas fa-download"></i>
              </span>
            </a>
          </div>

          <div className="mt-5 mb-3 text-center">
            <strong className="project-details-heading">
              {projectDetails.projectheading}
            </strong>
            <span className="divider-separator">
              <i className="fas fa-leaf aboutus-sub-text-head px-3"></i>
            </span>
          </div>

          <div className="row d-flex justify-content-center ">
            {projectDetails &&
              projectDetails.project_highlights &&
              projectDetails.project_highlights.map((i, index) => {
                return (
                  <React.Fragment key={index}>
                    <div className="col-lg-2 col-md-6 col-12" key={index}>
                      <div className="aboutus_card">
                        <div className="">
                          <img
                            src={i.image}
                            alt="Sample"
                            className="img-fluid"
                          />
                          <div>
                            <strong className="aboutus_card_tex">
                              {i.text}
                            </strong>
                          </div>
                        </div>
                      </div>
                    </div>
                  </React.Fragment>
                );
              })}
          </div>

          <div className="mb-3 text-center">
            <strong className="project-details-heading">
              {projectDetails.layoutheading}
            </strong>
            <span className="divider-separator">
              <i className="fas fa-leaf aboutus-sub-text-head px-3"></i>
            </span>
          </div>
          <div className="row d-flex justify-content-center">
            <div className="col-lg-8 col-12">
              <Zoom zoomZindex={999}>
                <img
                  alt=""
                  width="100%"
                  src={projectDetails.projectlayoutimage}
                />
              </Zoom>
            </div>
          </div>
          <div className="text-center my-3">
            <a
              href={projectDetails.projectlayoutimage}
              target="_blank"
              rel="noreferrer"
              className="btn custom-btn to-right"
            >
              <span className="project-detail-download">
                Download layout <i className="fas fa-download"></i>
              </span>
            </a>
          </div>
          <div className="row">
            <div className="col-md-6">
              <div className="my-4 text-center">
                <strong className="project-details-heading">
                  {projectDetails.projectlocheading}
                </strong>
                <span className="divider-separator">
                  <i className="fas fa-leaf aboutus-sub-text-head px-3"></i>
                </span>
              </div>
              <div>
                <iframe
                  title="loc"
                  src={projectDetails.projectLoc}
                  width="100%"
                  height="350"
                  style={{ border: "0" }}
                  allowFullScreen=""
                  loading="lazy"
                  referrerPolicy="no-referrer-when-downgrade"
                ></iframe>
              </div>
            </div>
            <div className="col-md-6">
              <div className="my-4 mb-3 text-center">
                <strong className="project-details-heading">
                  {projectDetails.projectlochighlights}
                </strong>
                <span className="divider-separator">
                  <i className="fas fa-leaf aboutus-sub-text-head px-3"></i>
                </span>
              </div>
              <div>
                <ol style={{ listStyle: "none" }}>
                  {projectDetails.location_highlights &&
                  projectDetails.location_highlights.length > 0
                    ? projectDetails.location_highlights.map((item, index) => {
                        return (
                          <li className="py-1" key={index}>
                            <i className="fas fa-circle project-heighlets-icon"></i>{" "}
                            <span className="project-heighlets-text">
                              {item}
                            </span>
                          </li>
                        );
                      })
                    : null}
                </ol>
              </div>
            </div>
          </div>

          <div className="mt-5 mb-3 text-center">
            <a
              href={projectDetails.brochure1}
              rel="noreferrer"
              target="_blank"
              className="btn custom-btn to-right"
            >
              <span className="project-detail-download">
                Download brochure <i className="fas fa-download"></i>
              </span>
            </a>
          </div>
          {/* <div className="my-4 mb-3 text-center">
            <strong className="project-details-heading">GALLERY</strong>
            <span className="divider-separator">
              <i className="fas fa-leaf aboutus-sub-text-head px-3"></i>
            </span>
          </div> */}

          <div className="row d-flex justify-content-center my-4">
            {projectDetails &&
              projectDetails.gallery &&
              projectDetails.gallery.map((i, index) => {
                return (
                  <div className="col-lg-4 col-md-6 col-12" key={index}>
                    <div className="aboutus_card">
                      <div className="">
                        <img
                          src={i}
                          alt="Gallery"
                          width="100%"
                          className="img-fluid img_trans"
                        />
                      </div>
                    </div>
                  </div>
                );
              })}
            {projectDetails &&
              projectDetails.galleryVideo &&
              projectDetails.galleryVideo.map((i, index) => {
                return (
                  <div className="col-lg-4 col-md-6 col-12" key={index}>
                    <div className="aboutus_card">
                      <div className="">
                        <VideoPlayer
                          src={i}
                          thumbnailSrc={projectDetails.videoThumnail}
                        />
                      </div>
                    </div>
                  </div>
                );
              })}
          </div>
        </div>
      )}
    </>
  );
}
