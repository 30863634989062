import { Formik } from "formik";
import { useState } from "react";
import { sendEmail } from "../../configuration/send";
import { initialvalues } from "../../model/userinformation";
import { userValidationSchema } from "../../model/validation";

const UserForm = () => {
  const [success, setSuccess] = useState(false);
  const [failure, setFailure] = useState(false);
  const onSubmit = (values, resetForm) => {
    setSuccess(false);
    setFailure(false);
    sendEmail(values)
      .then((res) => {
        if (res && res.status && res.status.toLowerCase() === "success") {
          setSuccess(true);
          resetForm();
          setTimeout(() => setSuccess(false), 3000);
        } else {
          setFailure(true);
        }
      })
      .catch((err) => {
        setFailure(true);
      });
  };
  return (
    <>
      {success && (
        <div className="alert alert-success" role="alert">
          Email sent successfully.
        </div>
      )}{" "}
      {failure && (
        <div className="alert alert-danger" role="alert">
          Something went wrong. Please try again later.
        </div>
      )}
      <Formik
        initialValues={initialvalues}
        enableReinitialize={true}
        validationSchema={userValidationSchema}
        onSubmit={(values, { resetForm }) => onSubmit(values, resetForm)}
      >
        {({
          handleChange,
          handleSubmit,
          values,
          errors,
          setFieldValue,
          handleBlur,
          touched,
          handleReset,
        }) => {
          return (
            <div className="row d-grid">
              <div className="col-12 pb-2">
                <input
                  type="text"
                  name="name"
                  className="form-control name blog-input-height"
                  placeholder="Full Name*"
                  value={values.name}
                  onChange={handleChange("name")}
                />
                {touched.name && errors.name ? (
                  <p className="text-danger" style={{ marginBottom: "-10px" }}>
                    {errors.name}
                  </p>
                ) : null}
              </div>
              <div className="col-12 pb-2">
                <input
                  type="text"
                  name="phone"
                  className="form-control name blog-input-height"
                  placeholder="Phone Number*"
                  value={values.phone}
                  onChange={handleChange("phone")}
                />
                {touched.phone && errors.phone ? (
                  <p className="text-danger" style={{ marginBottom: "-10px" }}>
                    {errors.phone}
                  </p>
                ) : null}
              </div>
              <div className="col-12 pb-2">
                <input
                  type="text"
                  name="email"
                  className="form-control name blog-input-height"
                  placeholder="Enter Your Email*"
                  value={values.email}
                  onChange={handleChange("email")}
                />
                {touched.email && errors.email ? (
                  <p className="text-danger" style={{ marginBottom: "-10px" }}>
                    {errors.email}
                  </p>
                ) : null}
              </div>
              <div className="col-12 pb-2">
                <textarea
                  type="textarea"
                  name="message"
                  className="form-control name"
                  placeholder="Your message"
                  value={values.message}
                  onChange={handleChange("message")}
                  rows={4}
                />
              </div>
              <div className="col-12 mt-2">
                <button
                  className="btn custom-btn-sm to-right form-control blog-input-height"
                  onClick={() => handleSubmit()}
                >
                  Submit
                </button>
              </div>
            </div>
          );
        }}
      </Formik>
    </>
  );
};

export default UserForm;
