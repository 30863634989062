import { Formik } from "formik";
import {
  ADDRESS,
  CITY,
  COUNTRY,
  EMAIL_ID,
  FACEBOOK_URL,
  INSTAGRAM_URL,
  PHONE_NUMBER,
  STATE,
  YOUTUBE_URL,
  ZIP_CODE,
} from "../../configuration/config";
import { sendEmail } from "../../configuration/send";
import { initialvalues } from "../../model/userinformation";
import { userValidationSchema } from "../../model/validation";

const Footer = () => {
  const onSubmit = (values, resetForm) => {
    sendEmail(values)
      .then((res) => {
        if (res && res.status && res.status.toLowerCase() === "success") {
          resetForm();
        } else {
        }
      })
      .catch((err) => {});
  };
  return (
    <>
      <div className="site-wrap">
        <div className="footer">
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <p className="footer-text text-center">
                  WE'D LOVE TO HEAR FROM YOU
                </p>
                <h6 className="caption text-white text-center">GET IN TOUCH</h6>
              </div>
              <div className="col-md-12 pt-5">
                <Formik
                  initialValues={initialvalues}
                  enableReinitialize={true}
                  validationSchema={userValidationSchema}
                  onSubmit={(values, { resetForm }) =>
                    onSubmit(values, resetForm)
                  }
                >
                  {({
                    handleChange,
                    handleSubmit,
                    values,
                    errors,
                    setFieldValue,
                    handleBlur,
                    touched,
                    handleReset,
                  }) => {
                    return (
                      <div className="row justify-content-center">
                        <div className="col-md-3 col-12 pb-4">
                          <input
                            type="text"
                            name="name"
                            className="form-control name"
                            placeholder="Name*"
                            required=""
                            value={values.name}
                            onChange={handleChange("name")}
                          />
                          {touched.name && errors.name ? (
                            <p
                              className="text-danger"
                              style={{ marginBottom: "-10px" }}
                            >
                              {errors.name}
                            </p>
                          ) : null}
                        </div>
                        <div className="col-md-3 col-12 pb-4">
                          <input
                            type="text"
                            name="name"
                            className="form-control name"
                            placeholder="Phone Number*"
                            required=""
                            value={values.phone}
                            onChange={handleChange("phone")}
                          />
                          {touched.phone && errors.phone ? (
                            <p
                              className="text-danger"
                              style={{ marginBottom: "-10px" }}
                            >
                              {errors.phone}
                            </p>
                          ) : null}
                        </div>
                        <div className="col-md-3 col-12 pb-4">
                          <input
                            type="text"
                            name="name"
                            className="form-control name"
                            placeholder="Enter Your Email*"
                            required=""
                            value={values.email}
                            onChange={handleChange("email")}
                          />
                          {touched.email && errors.email ? (
                            <p
                              className="text-danger"
                              style={{ marginBottom: "-10px" }}
                            >
                              {errors.email}
                            </p>
                          ) : null}
                        </div>
                        <div className="col-md-3 col-12">
                          <button
                            className="btn custom-btn-sm to-right form-control"
                            onClick={() => handleSubmit()}
                            type="button"
                          >
                            Submit
                          </button>
                        </div>
                      </div>
                    );
                  }}
                </Formik>
              </div>
              <div className="col-md-12 pt-5 text-center">
                <h6 className="caption text-white">CONTACT INFO</h6>
              </div>
              <div className="col-md-12 text-center">
                <span>{ADDRESS}</span>
              </div>
              {/* <div className="col-md-12 text-center">
                <span>Lane opp to HDFC Bank, Phase - 1, Kavuri Hills,</span>
              </div> */}
              <div className="col-md-12 text-center">
                <span>
                  {" "}
                  {CITY}, {STATE} - {ZIP_CODE}, {COUNTRY}
                </span>
              </div>
              <div className="col-md-12 text-center">
                <span>{PHONE_NUMBER}</span>
              </div>
              <div className="col-md-12 text-center">
                <span>{EMAIL_ID}</span>
              </div>
            </div>
            <div className="row">
              <ul className="footer-menu d-none d-lg-block text-center mr-auto ml-auto">
                <li>
                  <a href="/" className="text-white">
                    Home
                  </a>
                </li>
                <li>
                  <a href="/about" className="text-white">
                    About
                  </a>
                </li>
                <li>
                  <a href="/projects" className="text-white">
                    Projects
                  </a>
                </li>
                {/* <li>
                  <a href="/blog" className="text-white">
                    Blog & News
                  </a>
                </li> */}
                <li>
                  <a href="/contact" className="text-white">
                    Contact Us
                  </a>
                </li>
              </ul>
            </div>
            <div className="row">
              <div className="col-12">
                <div className="copyright">
                  <div className="row align-items-center">
                    <div className="col-md-4">
                      <p>
                        Copyright &copy;
                        {" " + new Date().getFullYear() + " "} Anvika Infra. All
                        rights reserved.
                      </p>
                    </div>
                    <div className="col-md-4">
                      <div className="social-wrap text-center">
                        <a href={FACEBOOK_URL} target="_blank" rel="noreferrer">
                          <span className="icon-facebook"></span>
                        </a>
                        <a href={YOUTUBE_URL} target="_blank" rel="noreferrer">
                          <span className="icon-youtube"></span>
                        </a>
                        <a
                          href={INSTAGRAM_URL}
                          target="_blank"
                          rel="noreferrer"
                        >
                          <span className="icon-instagram"></span>
                        </a>
                        {/* <a href="#a">
                          <span className="icon-pinterest"></span>
                        </a> */}
                      </div>
                    </div>

                    <div className="col-md-4">
                      <div className="d-flex justify-content-between">
                        <a href="/policy" className="text-white">
                          Privacy Policy
                        </a>
                        <a href="/terms-conditions" className="text-white">
                          Terms & Conditions
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Footer;
