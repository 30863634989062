import { Suspense } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import AboutUs from "./components/about/AboutUs";
import Loading from "./components/loader/Loading";
import Projects from "./components/projects/Projects";
import Whatsapp from "./components/whatsapp/Whatsapp";
import SendMessage from "./components/sendmessage/SendMessage";
import MainLayout from "./routes/MainLayout";
import BlogPage from "./screens/BlogPage";
import Contact from "./screens/Contact";
import PhotoGallery from "./screens/gallery/PhotoGallery";
import LandingPage from "./screens/LandingPage";
import VideoGallery from "./screens/gallery/VideoGallery";
import BlogDetail1 from "./screens/BlogDetail1";
import BlogDetail2 from "./screens/BlogDetail2";
import EventGallery from "./screens/gallery/EventGallery";
import Photos from "./screens/gallery/Photos";
import ContactsPage from "./screens/ContactsPage";
import Project from "./screens/Project";
import ProjectDetails1 from "./screens/projectdetails/ProjectDetai1";
import Policy from "screens/policy/Policy";
import TermsConditions from "screens/termsconditions/TermsConditions";

export default function AppRouter() {
  return (
    <BrowserRouter>
      <Suspense fallback={<Loading />}>
        <Routes>
          <Route element={<MainLayout />}>
            <Route path="/" element={<LandingPage />} />
            <Route path="/contact" element={<Contact />} />
            <Route path="/about" element={<AboutUs />} />
            <Route path="/projects" element={<Projects />} />
            <Route path="/blog" element={<BlogPage />} />
            <Route path="/photo-gallery" element={<PhotoGallery />} />
            <Route path="/video-gallery" element={<VideoGallery />} />
            <Route path="/event-gallery" element={<EventGallery />} />
            <Route path="/blog-detail1" element={<BlogDetail1 />} />
            <Route path="/blog-detail2" element={<BlogDetail2 />} />
            <Route path="/photos" element={<Photos />} />
            <Route path="/project" element={<Project />} />
            <Route path="/contacts" element={<ContactsPage />} />
            <Route path="/project-detail1" element={<ProjectDetails1 />} />
            <Route path="/policy" element={<Policy />} />
            <Route path="/terms-conditions" element={<TermsConditions />} />
          </Route>
        </Routes>
        <Whatsapp />
        <SendMessage />
      </Suspense>
    </BrowserRouter>
  );
}
