import React from "react";
import OwlCarousel from "react-owl-carousel";
import { our_project_config } from "../configuration/config";

export default function Projects() {
  return (
    <>
      <div className="site-wrap">
        <div className="container">
          <div className="row justify-content-center text-center">
            <div className="col-lg-4">
              <span className="caption">PORTFOLIO</span>
              <h2 className="title-with-line text-center">OUR PROJECTS</h2>
            </div>
          </div>
        </div>
        <OwlCarousel
          margin={20}
          loop
          items={3}
          responsive={{
            0: {
              stagePadding: 10,
              items: 1,
            },
            480: {
              stagePadding: 10,
              items: 1,
            },
            768: {
              stagePadding: 10,
              items: 2,
            },
            992: {
              stagePadding: 10,
              items: 3,
            },
            1200: {
              stagePadding: 10,
              items: 3,
            },
          }}
        >
          {our_project_config.map((item, index) => {
            return (
              <React.Fragment key={index}>
                <div className="projects-single">
                  <a href={item.link}>
                    <div className="projects-img">
                      <img src={item.image} alt="img2" width="100%" />
                    </div>
                    <div className="projects-content">
                      <div className="projects-title">
                        <span className="text-white">{item.title}</span>
                      </div>
                      <div className="projects-arrow">
                        <i className="fas fa-arrow-right text-white"></i>
                      </div>
                    </div>
                  </a>
                </div>
              </React.Fragment>
            );
          })}
        </OwlCarousel>
        {/* <div className="owl-slide owl-carousel owl-testimonial">
          {our_project_config.map((item, index) => {
            return (
              <React.Fragment key={index}>
                <div className="projects-single">
                  <a href={item.link}>
                    <div className="projects-img">
                      <img src={item.image} alt="img2" width="100%" />
                    </div>
                    <div className="projects-content">
                      <div className="projects-title">
                        <span className="text-white">{item.title}</span>
                      </div>
                      <div className="projects-arrow">
                        <i className="fas fa-arrow-right text-white"></i>
                      </div>
                    </div>
                  </a>
                </div>
              </React.Fragment>
            );
          })}
        </div> */}
      </div>
    </>
  );
}
