import { our_project_config } from "configuration/config";

const VideoGallery = () => {
  return (
    <>
      <section className="banner-header banner-img section-padding valign bg-img bg-fixed photo-gallery-banner"></section>
      <div className="site-wrap pb-4">
        <div className="container">
          <div className="mt-3 text-center">
            <h2 className="text-head-color">VIDEO GALLERY</h2>
            <span className="divider-separator">
              <i className="fas fa-leaf aboutus-sub-text-head px-3"></i>
            </span>

            <div className="row d-flex justify-content-center my-4">
              {our_project_config &&
                our_project_config.map((i, index) => {
                  return (
                    <div className="col-md-6 col-12" key={index}>
                      <div className="aboutus_card">
                        <div className="">
                          <iframe
                            title="Block"
                            className="video-block"
                            width={640}
                            height={360}
                            src={i.youtube}
                          ></iframe>
                        </div>
                      </div>
                    </div>
                  );
                })}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default VideoGallery;
