import silpa_aranya_project from "../../assets/images/silpa_aranya_project.jpg";
import silpa_csk_project from "../../assets/images/silpa_csk_project.jpg";
import silpa_nature_avenue_project from "../../assets/images/silpa_nature_avenue_project.jpg";
import silpa_sr_bamboo_project from "../../assets/images/silpa_sr_bamboo_project.jpg";
import silpa_vanadhara_project from "../../assets/images/silpa_vanadhara_project.jpg";

const EventGallery = () => {
  return (
    <>
      <section className="banner-header banner-img section-padding valign bg-img bg-fixed photo-gallery-banner"></section>
      <div className="site-wrap pb-4">
        <div className="container">
          <div className="mt-3 text-center">
            <h2 className="text-head-color">EVENT GALLERY</h2>
            <span className="divider-separator">
              <i className="fas fa-leaf aboutus-sub-text-head px-3"></i>
            </span>
          </div>
          <div className="row justify-content-center">
            <div className="col-md-6 col-lg-4 news-entry-item mt-3">
              <a href="/photos">
                <div className="card ">
                  <img src={silpa_vanadhara_project} alt="img2" width="100%" />
                </div>
                <div className="card photo-gallery-card bg-primary py-3 text-center">
                  <strong className="blog_text text-white">
                    SILPA VANADHARA GALLERY
                  </strong>
                </div>
              </a>
            </div>
            <div className="col-md-6 col-lg-4 news-entry-item mt-3">
              <a href="/photos">
                <div className="card ">
                  <img src={silpa_aranya_project} alt="img2" width="100%" />
                </div>
                <div className="card photo-gallery-card bg-primary py-3 text-center">
                  <strong className="blog_text text-white">
                    SILPA ARANYA GALLERY
                  </strong>
                </div>
              </a>
            </div>
            <div className="col-md-6 col-lg-4 news-entry-item mt-3">
              <a href="/photos">
                <div className="card ">
                  <img src={silpa_sr_bamboo_project} alt="img2" width="100%" />
                </div>
                <div className="card photo-gallery-card bg-primary py-3 text-center">
                  <strong className="blog_text text-white">
                    SILPA SR BAMBOO GROVE GALLERY
                  </strong>
                </div>
              </a>
            </div>
            <div className="col-md-6 col-lg-4 news-entry-item mt-3">
              <a href="/photos">
                <div className="card ">
                  <img src={silpa_csk_project} alt="img2" width="100%" />
                </div>
                <div className="card photo-gallery-card bg-primary py-3 text-center">
                  <strong className="blog_text text-white">
                    SILPA CSK GREEN VILLA GALLERY
                  </strong>
                </div>
              </a>
            </div>
            <div className="col-md-6 col-lg-4 news-entry-item mt-3">
              <a href="/photos">
                <div className="card ">
                  <img
                    src={silpa_nature_avenue_project}
                    alt="img2"
                    width="100%"
                  />
                </div>
                <div className="card photo-gallery-card bg-primary py-3 text-center">
                  <strong className="blog_text text-white">
                    SILPA NATURE AVENUE GALLERY
                  </strong>
                </div>
              </a>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default EventGallery;
