import React from "react";
import UserForm from "../components/form/UserForm";
import { BLOG_IMAGE_1 } from "../configuration/environment";

const BlogDetail1 = () => {
  return (
    <>
      <section className="banner-header banner-img section-padding valign bg-img bg-fixed photo-gallery-banner"></section>
      {/* <div className="site-wrap"> */}
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <img width="100%" alt="" src={BLOG_IMAGE_1} />
            <div>
              <h2 className="text-primary blog-detail-heading my-3">
                SEVEN BENEFITS OF INVESTING IN A PLOT OF LAND
              </h2>
            </div>
            <div>
              <h3 className="blog-detail-sub-heading">
                1. Greater flexibility
              </h3>
              <p>
                Buyers are often restricted by the architect’s vision when
                purchasing an apartment. The buyer can make only a few
                modifications to the property, and it is impossible to change
                the layout of their home. A plot of land is, however, like a
                blank canvas on which to build your dream home. You have the
                option to design your home completely from scratch. You can add
                any elements you like and choose your materials. You can make
                your house last a lifetime, so it is important to have the
                ability to design it in a way that suits you and your lifestyle.
              </p>
            </div>
            <div>
              <h3 className="blog-detail-sub-heading">
                2. Low initial investment
              </h3>
              <p>
                Even the most affordable residential homes are often more
                expensive than a plot of land. Most potential investors will use
                a home loan to finance the purchase. A plot of land is more
                affordable. Because they have a low entry price, even young
                investors can buy them without financial obstacles. Many
                investors choose to buy land early in their lives and then build
                a home later when they have more money. This is often cheaper
                than buying a home and paying EMIs.
              </p>
            </div>
            <div>
              <h3 className="blog-detail-sub-heading">
                3. Land appreciates quickly
              </h3>
              <p>
                Real estate investments are almost certain to appreciate. This
                is the most appealing feature. While land and residential
                properties share this characteristic, it is possible to get an
                advantage by investing in a plot. Land has appreciated much
                faster than residential properties over the past few years. The
                surrounding infrastructure is also a key factor in determining
                the land’s value. The surrounding infrastructure will ensure a
                direct increase in its value. Land offers higher returns for a
                shorter period of time when you consider the low investment
                needed to buy it.
              </p>
            </div>
            <div>
              <h3 className="blog-detail-sub-heading">
                4. There is no gap between purchase and possession
              </h3>
              <p>
                Individuals have to wait to buy a home while it is still under
                construction before they can take possession. The final handover
                may take up to a year depending on the stage of construction.
                There is almost no chance of delays when you own a piece of
                land. Land doesn’t need to be constructed so owners can get
                their new asset almost immediately.
              </p>
            </div>
            <div>
              <h3 className="blog-detail-sub-heading">5. Lower property tax</h3>
              <p>
                Property tax is an expense property owners must account for
                regardless of whether they have a house or a plot of land.
                However, property taxes for land are usually lower than those
                for houses. For example, the property tax rates for residential
                houses in Bangalore can reach Rs. 5/sq. ft. 5/sq. ft. for
                tenants and Rs. 2.50/sq. ft for self-occupied properties. For
                vacant land, the property tax starts at Rs. 0.12/sq. ft, and up
                to Rs. 0.50/sq. ft. This means you’ll not only save money on the
                initial purchase of a piece of land but also pay LESS taxes in
                the long term.
              </p>
            </div>
            <div>
              <h3 className="blog-detail-sub-heading">
                6. There is no maintenance
              </h3>
              <p>
                You will need to maintain your home, regardless of whether you
                intend on living there. Some of these fees are permanent, such
                as the maintenance fees charged by housing societies. They can
                sometimes be unexpected, such as in the cases of plumbing
                problems, electrical problems, and renovations. A plot of land
                is almost maintenance-free.
              </p>
            </div>
            <div>
              <h3 className="blog-detail-sub-heading">
                7. It is a finite resource
              </h3>
              <p>
                Land is a finite resource, which is one of the greatest
                advantages of investing in it. There is only so much land that
                can be owned. New high-rises are being built all the time. This
                means that owners can rest assured that their investment will be
                in high demand and will appreciate in value.
              </p>
              <p>
                A plot of land investment is a great way to make high returns
                and minimize risk.
              </p>
              <p>
                Anvika Infratech offers a variety of plotted development project
                options.
              </p>
              <p>
                Visit https://www.silpa.co.in/ or reach us at +91 – 8747994499
              </p>
            </div>
          </div>
        </div>
        <div className="row my-3">
          <div className="col-md-4 comment-sec-margin">
            <h4 className="text-primary">Leave A Comments</h4>

            <UserForm />
          </div>
        </div>
      </div>
    </>
  );
};

export default BlogDetail1;
