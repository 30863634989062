import React from "react";

export default function ProjectCard({ number, image, title, link }) {
  const dir = [0, 1, 1, 0];
  return (
    <>
      <div className="elementor-column elementor-col-50 p-2">
        <div className="row">
          <div className="col-md-12">
            <div
              className={
                "projects2" + (dir[(number - 1) % 4] === 0 ? "" : " left")
              }
            >
              <figure>
                <a href={link}>
                  <img alt="" src={image} style={{ height: "320px" }} />
                </a>
              </figure>
              <div className="caption">
                <div className="section-number">.0{number}</div>
                <h4>{title}</h4>
                <div>
                  <a href={link} className="btn custom-btn to-right">
                    <span>
                      VIEW PROJECT <i className="fas fa-arrow-right"></i>
                    </span>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
