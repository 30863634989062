import React from "react";

const Policy = () => {
  return (
    <>
      <section className="banner-header banner-img section-padding valign bg-img bg-fixed photo-gallery-banner"></section>
      <div className="site-wrap pb-4">
        <div className="container">
          <div className="mt-3 text-center">
            <h2 className="text-head-color">PRIVACY POLICY</h2>
            <span className="divider-separator">
              <i className="fas fa-leaf aboutus-sub-text-head px-3"></i>
            </span>
          </div>

          {process.env.REACT_APP_APPLICATION_NAME !== "royal" ? (
            <div className="">
              <p className="policy-text text-justify">
                This privacy policy sets out how “Anvika Infraa Developers” uses
                and protects any information that you give “Anvika Infraa
                Developers” when you use this website. “Anvika Infraa
                Developers” is committed to ensuring that your privacy is
                protected. Should we ask you to provide certain information by
                which you can be identified when using this website, then you
                can be assured that it will only be used in accordance with this
                privacy statement.
              </p>
              <p className="policy-text text-justify">
                “Anvika Infraa Developers” may change this policy from time to
                time by updating this page. You should check this page from time
                to time to ensure that you are happy with any changes.
              </p>
              <p className="policy-text">
                This policy is effective from <strong>1st July, 2013</strong>.
              </p>
              <h3 className="text-primary">WHAT WE COLLECT</h3>
              <p className="policy-text">
                We may collect the following information:
              </p>
              <ul className="policy-text">
                <li>name and job title</li>
                <li>contact information including email address</li>
                <li>
                  demographic information such as post code, preferences and
                  interests
                </li>
                <li>
                  other information relevant to customer surveys and/or offers
                </li>
              </ul>
              <h3 className="text-primary">
                WHAT WE DO WITH THE INFORMATION WE GATHER
              </h3>
              <p className="policy-text">
                We require this information to understand your needs and provide
                you with a better service, and in particular for the following
                reasons:
              </p>
              <ul className="policy-text text-justify">
                <li>Internal record keeping.</li>
                <li>
                  We may use the information to improve our products and
                  services.
                </li>
                <li>
                  We may periodically send promotional email about new products,
                  special offers or other information which we think you may
                  find interesting using the email address which you have
                  provided.
                </li>
                <li>
                  From time to time, we may also use your information to contact
                  you for market research purposes. We may contact you by email,
                  phone, fax or mail.
                </li>
                <li>
                  We may use the information to customize the website according
                  to your interests.
                </li>
              </ul>
              <h3 className="text-primary">SECURITY</h3>
              <p className="policy-text text-justify">
                We are committed to ensuring that your information is secure. In
                order to prevent unauthorized access or disclosure we have put
                in place suitable physical, electronic and managerial procedures
                to safeguard and secure the information we collect online.
              </p>
              <h3 className="text-primary">HOW WE USE COOKIES</h3>
              <p className="policy-text text-justify">
                A cookie is a small file which asks permission to be placed on
                your computer’s hard drive. Once you agree, the file is added
                and the cookie helps analyse web traffic or lets you know when
                you visit a particular site. Cookies allow web applications to
                respond to you as an individual. The web application can tailor
                its operations to your needs, likes and dislikes by gathering
                and remembering information about your preferences.
              </p>
              <p className="policy-text text-justify">
                We use traffic log cookies to identify which pages are being
                used. This helps us analyse data about web page traffic and
                improve our website in order to tailor it to customer needs. We
                only use this information for statistical analysis purposes and
                then the data is removed from the system. Overall, cookies help
                us provide you with a better website, by enabling us to monitor
                which pages you find useful and which you do not. A cookie in no
                way gives us access to your computer or any information about
                you, other than the data you choose to share with us.
              </p>
              <p className="policy-text text-justify">
                You can choose to accept or decline cookies. Most web browsers
                automatically accept cookies, but you can usually modify your
                browser setting to decline cookies if you prefer. This may
                prevent you from taking full advantage of the website.
              </p>
              <h3 className="text-primary">LINKS TO OTHER WEBSITES</h3>
              <p className="policy-text text-justify">
                Our website may contain links to enable you to visit other
                websites of interest easily. However, once you have used these
                links to leave our site, you should note that we do not have any
                control over that other website. Therefore, we cannot be
                responsible for the protection and privacy of any information
                which you provide whilst visiting such sites and such sites are
                not governed by this privacy statement. You should exercise
                caution and look at the privacy statement applicable to the
                website in question.
              </p>

              <h3 className="text-primary">
                CONTROLLING YOUR PERSONAL INFORMATION
              </h3>
              <p className="policy-text">
                You may choose to restrict the collection or use of your
                personal information in the following ways:
              </p>
              <ul className="policy-text text-justify">
                <li>
                  whenever you are asked to fill in a form on the website, look
                  for the box that you can click to indicate that you do not
                  want the information to be used by anybody for direct
                  marketing purposes.
                </li>
                <li>
                  if you have previously agreed to us using your personal
                  information for direct marketing purposes, you may change your
                  mind at any time by writing to or email us
                </li>
              </ul>
              <p className="policy-text text-justify">
                We will not sell, distribute or lease your personal information
                to third parties unless we have your permission or are required
                by law. We may use your personal information to send you
                promotional information about Asrithas Group which we think you
                may find interesting.
              </p>
              <p className="policy-text text-justify">
                When you voluntarily send us electronic mail, we will keep a
                record of this information so that we can respond to you. We
                only collect information from you when you register on our site
                or fill out a form. Also, when filling out a form on our site,
                you may be asked to enter your: name, e-mail address or phone
                number. You may, however, visit our site anonymously. In case
                you have submitted your personal information and contact
                details, we reserve the rights to Call, SMS, Email or WhatsApp
                about our products and offers, even if your number has DND
                activated on it.
              </p>
            </div>
          ) : (
            <div className="">
              <p className="policy-text text-justify">
                Royal Rajarikam Developers takes into consideration your rights
                to privacy and does not share your information with any other
                media/research agencies without prior permission.
              </p>

              <h3 className="text-primary">WHAT WE COLLECT</h3>
              <p className="policy-text">
                We may collect the following information:
              </p>
              <ul className="policy-text">
                <li>Name and job title</li>
                <li>Contact information including email address</li>
                <li>
                  Demographic information such as post code, preferences and
                  interests
                </li>
                <li>
                  Other information relevant to customer surveys and/or offers
                </li>
              </ul>

              <h3 className="text-primary">SECURITY</h3>
              <p className="policy-text text-justify">
                Royal Rajarikam Developers is committed to ensure that your
                information is safe and secure.
              </p>

              <h3 className="text-primary">COOKIES</h3>

              <ul className="policy-text">
                <li>
                  A cookie is a small file which asks permission to be placed on
                  your computer's hard drive. Once you agree, the file is added
                  and the cookie helps analyze web traffic or lets you know when
                  you visit a particular site.
                </li>
                <li>
                  Cookies allow web applications to respond to you as an
                  individual. The web application can tailor its operations to
                  your needs, likes and dislikes by gathering and remembering
                  information about your preferences.
                </li>
                <li>
                  We use traffic log cookies to identify which pages are being
                  used. This helps us analyze data about webpage traffic and
                  improve our website in order to tailor it to customer needs.
                  We only use this information for statistical analysis purposes
                  and then the data is removed from the system.
                </li>
                <li>
                  Overall, cookies help us provide you with a better website by
                  enabling us to monitor which pages you find useful and which
                  you do not. A cookie in no way gives us access to your
                  computer or any information about you, other than the data you
                  choose to share with us.
                </li>
                <li>
                  You can choose to accept or decline cookies. Most web browsers
                  automatically accept cookies, but you can usually modify your
                  browser setting to decline cookies if you prefer. This may
                  prevent you from taking full advantage of the website.
                </li>
              </ul>

              <h3 className="text-primary">
                CONTROLLING YOUR PERSONAL INFORMATION
              </h3>
              <p className="policy-text">
                You may choose to restrict the collection or use of your
                personal information in the following ways:
              </p>
              <ul className="policy-text text-justify">
                <li>
                  Whenever you are asked to fill in a form on the website, look
                  for the box that you can click to indicate that you do not
                  want the information to be used by anybody for direct
                  marketing purposes.
                </li>
                <li>
                  If you have previously agreed to us using your personal
                  information for direct marketing purposes, you may change your
                  mind at any time by writing to or emailing us at
                  royalrajarikam@gmail.com • We will not sell, distribute or
                  lease your personal information to third parties unless we
                  have your permission or are required by law to do so. We may
                  use your personal information to send you promotional
                  information about third parties which we think you may find
                  interesting if you tell us that you wish this to happen.
                </li>
                <li>
                  If you believe that any information we are holding on you is
                  incorrect or incomplete, please write to or email us as soon
                  as possible at the above address. We will promptly correct any
                  information found to be incorrect.
                </li>
              </ul>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default Policy;
